import {
  IAddChecklistCompletionRequest,
  IChecklistCompletionResponseDTO,
  IPaginatedCompletionsQuery,
} from "../interfaces/checklists";
import { IPaginatedResponse } from "../interfaces/companies";
import { getSortParams } from "./api-helpers";
import { kypsApi } from "./kyps.api";

export const checklistCompletionControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getCompletionById: query<IChecklistCompletionResponseDTO, string>({
      query: (completionId) => ({
        url: `/checklist-completions/${completionId}`,
        method: "GET",
      }),
      providesTags: ["ChecklistCompletion"],
    }),

    addChecklistCompletion: mutation<string, IAddChecklistCompletionRequest>({
      query: ({ modelId, locationId, body }) => ({
        url: `/checklist-models/${modelId}/locations/${locationId}/completions`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ChecklistCompletion"],
    }),

    getChecklistCompletions: query<
      IPaginatedResponse<IChecklistCompletionResponseDTO>,
      IPaginatedCompletionsQuery
    >({
      query: ({
        modelId,
        page,
        size,
        sort = [{ direction: "ASC", property: "createdAt" }],
      }) => ({
        url: `/checklist-models/${modelId}/completions-without-content?page=${page}&size=${size}&sort=${getSortParams(
          sort
        )}`,
        method: "GET",
      }),
      providesTags: ["ChecklistCompletion"],
    }),
  }),
});

export const {
  useGetCompletionByIdQuery,
  useAddChecklistCompletionMutation,
  useGetChecklistCompletionsQuery,
} = checklistCompletionControllerEndpoints;
