import { MutableRefObject, PropsWithChildren } from "react";
import {
  CloseChecklistWrapper,
  ChecklistModalHeading,
  ChecklistModalOuterWrapper,
  ChecklistModalWrapper,
} from "./checklist-modal-container.styles";
import IconButton from "../../icon-button/icon-button";

interface IChecklistModalContainer {
  closeModal: () => void;
  heading?: string;
  componentRef?: MutableRefObject<HTMLDivElement | null>;
}

const ChecklistModalContainer = ({
  closeModal,
  children,
  heading,
  componentRef,
}: PropsWithChildren<IChecklistModalContainer>) => (
  <ChecklistModalOuterWrapper ref={componentRef}>
    <ChecklistModalWrapper>
      <CloseChecklistWrapper>
        <IconButton
          name="close"
          onClick={closeModal}
          hoverColor="info"
          hasZeroMargin
        />
      </CloseChecklistWrapper>
      {heading && <ChecklistModalHeading>{heading}</ChecklistModalHeading>}
      {children}
    </ChecklistModalWrapper>
  </ChecklistModalOuterWrapper>
);

export default ChecklistModalContainer;
