import { ReactNode, MouseEvent } from "react";
import { isEmpty } from "lodash";
import {
  ILocationInstallationPoint,
  ILocationZone,
} from "../../../interfaces/dashboard";
import { ISelectOption } from "../../kyps-select/kyps-select";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { PageIconNames } from "../../../utils/empty-page-icons";
import { ILocationTableData, ISubRowsData } from "../../../interfaces/tables";
import EditIPointSidebar from "../../kyps-sidebar/edit-installation-point-sidebar/edit-installation-point-sidebar";
import { IMenuItems } from "../../../interfaces/dropdown-menu";
import EditSensorSidebar from "../../kyps-sidebar/edit-sensor-sidebar/edit-sensor-sidebar";
import { useDeleteIPointMutation } from "../../../api/installation-point.api";
import { toRoundValue } from "../../dashboard-components/location-dashboard/location-dashboard.helper";
import { TFunction } from "i18next";
import { createNotification } from "../../toaster/toaster.helper";
import { QueryStatus } from "@reduxjs/toolkit/query";

type DeleteIPointResult = ReturnType<typeof useDeleteIPointMutation>;
export type DeleteIPointFunction = DeleteIPointResult[0];

interface IOnDeleteIPoint {
  id: string;
  name: string;
  deleteIPoint: DeleteIPointFunction;
}

interface ICreateDropdownMenuItems {
  id: string;
  role: "iPoint" | "sensor";
  selectedZone: ISelectOption;
  zonesOptions: ISelectOption[];
  selectedIPoint?: ISelectOption;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  onDeleteButton: (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  t: TFunction<"translations", undefined>;
}

interface IHasEmptyMessage {
  data: any[];
  message: string;
  iconName?: PageIconNames;
  isSmall?: boolean;
  isLoading?: boolean;
  status?: QueryStatus;
}

export const AllZonesValue = "All";

export const getZoneData = (
  installationPoints: ILocationInstallationPoint[]
): ILocationTableData[] =>
  installationPoints.map(
    ({ sensors, name, temperatureRange: iPointRange, id }) => {
      const subItems: ISubRowsData[] = sensors.map(
        ({
          id,
          lastUpdateDate,
          lastTemperature,
          sensorNumber,
          lastVoltage,
          lastDbm,
          state,
          customConfig,
          showHumidity,
          temperatureRange,
          sensorName,
        }) => ({
          id,
          battery: lastVoltage ?? "-",
          name: { value: sensorNumber, isCustomConfig: customConfig },
          safeZone: temperatureRange,
          signal: lastDbm,
          temperature: {
            value:
              state !== "OFF" ? `${toRoundValue(lastTemperature)}` : "OFFLINE",
            state: `${state.toLocaleLowerCase()}`,
          },
          time: lastUpdateDate,
          showHumidity,
          sensorName: sensorName as string,
        })
      );

      return {
        name: { value: name },
        id,
        safeZone: iPointRange,
        subRows: subItems,
      };
    }
  );

export const getZoneSelectArray = (
  data: ILocationZone[],
  t: TFunction<"translations", undefined>
): ISelectOption[] => {
  const selectArrayData = data.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  if (data.length > 1)
    selectArrayData.unshift({
      label: t("tables.allZones"),
      value: AllZonesValue,
    });

  return selectArrayData;
};

export const hasEmptyMessage = ({
  data,
  message,
  iconName = "clipboard",
  isSmall,
  isLoading,
  status,
}: IHasEmptyMessage): JSX.Element | null => {
  if (isLoading || getIsUninitialized(status)) return null;

  return isEmpty(data) ? (
    <EmptyPage isSmall={isSmall} heading={message} iconName={iconName} />
  ) : null;
};

const getIsUninitialized = (status?: QueryStatus) =>
  status === QueryStatus.uninitialized;

export const createDropdownMenuItems = ({
  id,
  role,
  selectedZone,
  zonesOptions,
  selectedIPoint,
  closeAndResetModal,
  openModal,
  onDeleteButton,
  t,
}: ICreateDropdownMenuItems): IMenuItems[] => {
  if (role === "iPoint")
    return [
      {
        name: t("buttons.edit.action"),
        onItemClick: () =>
          openModal(
            <EditIPointSidebar
              closeModal={closeAndResetModal}
              iPointId={id}
              zoneId={selectedZone.value}
              t={t}
            />
          ),
      },
      {
        name: t("buttons.delete.action"),
        onItemClick: onDeleteButton,
      },
    ];

  return [
    {
      name: t("buttons.edit.action"),
      onItemClick: () =>
        openModal(
          <EditSensorSidebar
            closeModal={closeAndResetModal}
            sensorId={id}
            zonesOptions={zonesOptions}
            defaultZone={selectedZone}
            defaultIPoint={selectedIPoint}
            t={t}
          />
        ),
    },
    {
      name: t("buttons.delete.action"),
      onItemClick: onDeleteButton,
    },
  ];
};

export const handleOnDeleteIPoint = ({
  id,
  name,
  deleteIPoint,
}: IOnDeleteIPoint) =>
  deleteIPoint(id)
    .unwrap()
    .then(() =>
      createNotification({
        message: `${name} was successfully deleted!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
