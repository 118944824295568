import { logo } from "../../assets";
import { KypsHeader } from "../../components/kyps-header/kyps-header";
import KypsNav from "../../components/kyps-nav/kyps-nav";
import Holder from "../../components/kyps-holder/kyps-holder";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { UserRoles, UserRolesEnum } from "../../interfaces/roles";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getUserRoles } from "../../auth/get-user-roles.helper";
import { useGetAuthorizedUserDataQuery } from "../../api/managers.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import KypsBottomNav from "../../components/kyps-bottom-nav/kyps-bottom-nav";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import useGA from "../../hooks/useGA";
import { getPathString } from "../../helpers/page-path.helper";
import { AppWrapper } from "../../styles/helper.styles";
import { useGetManagerLocationsQuery } from "../../api/location-manager.api";
import { IUserLocations } from "../../redux/userLocations/reducers";
import { useGetLocationsByCompanyIdQuery } from "../../api/location.api";

const AppContent = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();

  const currentCompany = useAppSelector(
    ({ company }) => company.selectedCompany
  );
  const authorizedUser = useAppSelector(({ user: { user } }) => user);
  const { login, setUserLocations } = useAppDispatch();

  const { pathname } = useLocation();
  const { sendGAPageView } = useGA();

  const { data: apiUserData } = useGetAuthorizedUserDataQuery(
    !initialized ? skipToken : null
  );
  const { data: apiUserLocationsData } = useGetManagerLocationsQuery(
    authorizedUser?.id ?? skipToken
  );

  const isAdmin = useMemo(() => {
    if (
      authorizedUser?.role === UserRolesEnum.ADMIN ||
      authorizedUser?.role === UserRolesEnum.SUPER_ADMIN
    )
      return true;
  }, [authorizedUser?.role]);

  const { data: apiCompanyLocations } = useGetLocationsByCompanyIdQuery(
    isAdmin && currentCompany?.value ? currentCompany.value : skipToken
  );

  const userLocationsData: IUserLocations[] | null = useMemo(() => {
    if (isAdmin && !apiCompanyLocations) return null;

    if (apiCompanyLocations && isAdmin)
      return apiCompanyLocations.map((location) => ({
        ...location,
        userRole: UserRolesEnum.MANAGER,
      }));

    if (apiUserLocationsData && currentCompany)
      return apiUserLocationsData
        .filter(({ companyId }) => companyId === currentCompany.value)
        .map(({ location, role }) => ({
          ...location,
          userRole: role,
        }));

    return null;
  }, [apiCompanyLocations, apiUserLocationsData, currentCompany, isAdmin]);

  const userData = useMemo(() => apiUserData ?? null, [apiUserData]);

  useEffect(() => {
    if (userLocationsData)
      setUserLocations(
        userLocationsData.sort((a, b) => a.name.localeCompare(b.name))
      );
  }, [setUserLocations, userLocationsData]);

  useEffect(() => {
    const handleRole = (role: UserRoles) => {
      if (userData && !authorizedUser?.name)
        login({
          role,
          name: userData.name,
          email: userData.email,
          id: userData.id,
        });
    };

    if (currentCompany) {
      getUserRoles({
        url: currentCompany.value,
        handleOnChangeRole: handleRole,
      });

      return;
    }

    handleRole(UserRolesEnum.USER);
  }, [authorizedUser?.name, currentCompany, login, userData]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  useEffect(() => {
    sendGAPageView({
      page: getPathString(pathname),
      title: `User Cabinet - ${getPathString(pathname)}`,
    });
  }, [pathname, sendGAPageView]);

  if (!initialized || !keycloak.authenticated) return null;

  return (
    <AppWrapper>
      <KypsHeader logo={logo} nav={<KypsNav />} t={t} />
      <Holder />
      <KypsBottomNav t={t} />
    </AppWrapper>
  );
};

export default AppContent;
