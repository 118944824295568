import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import keycloak from "../keycloak";

export const kypsApi = createApi({
  reducerPath: "kypsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.kyps.link/",
    prepareHeaders: (headers) => {
      const token = keycloak.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    "Companies",
    "Locations",
    "Managers",
    "Zones",
    "Sensors",
    "InstallationPoints",
    "LocationManager",
    "Reports",
    "Dashboard",
    "DashboardZone",
    "DashboardComplete",
    "CompanyChecklists",
    "LocationChecklists",
    "ChecklistCompletion",
  ],
  endpoints: () => ({}),
});
