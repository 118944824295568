import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { Survey } from "survey-react-ui";
import { checklistCssColors } from "./checklist-theme";
import { useGetChecklistModelByIdQuery } from "../../../api/checklists.api";
import { useMemo } from "react";
import Loaders from "../../loaders/loaders";
import { AddCompletionFunction } from "../../../interfaces/add-functions";
import {
  getChecklistResult,
  handleOnAddCompletion,
} from "./checklist-component.helper";
import ChecklistModalContainer from "../checklist-modal-container/checklist-modal-container";
import EmptyPage from "../../kyps-empty/kyps-empty-page";

interface IChecklistComponent {
  model: string;
  modelContent?: string;
  locationId?: string;
  closeChecklist: () => void;
  addCompletionFunction?: AddCompletionFunction;
}

const ChecklistComponent = ({
  model,
  locationId,
  modelContent,
  closeChecklist,
  addCompletionFunction,
}: IChecklistComponent) => {
  const { data: apiChecklistModelData, isLoading } =
    useGetChecklistModelByIdQuery(model);

  const data = useMemo(
    () => (apiChecklistModelData ? apiChecklistModelData.model : null),
    [apiChecklistModelData]
  );

  const checklistConfig = useMemo(() => {
    if (modelContent) {
      const checklist = new Model(modelContent);
      checklist.applyTheme({
        cssVariables: checklistCssColors,
      });

      return checklist;
    }

    if (data) {
      const checklist = new Model(data);
      checklist.showTimerPanel = "none";

      checklist.onComplete.add(() => {
        const { correctAnswers, incorrectAnswers } =
          getChecklistResult(checklist);

        if (addCompletionFunction && locationId)
          handleOnAddCompletion({
            addCompletion: addCompletionFunction,
            locationId,
            modelId: model,
            requestBody: {
              content: JSON.stringify(checklist.data),
              correctAnswers,
              incorrectAnswers,
              questionCount: checklist.getAllQuestions().length,
            },
          });
      });

      return checklist;
    }

    return null;
  }, [addCompletionFunction, data, locationId, model, modelContent]);

  const content = useMemo(() => {
    if (isLoading) return <Loaders variant="location" />;

    return checklistConfig && !checklistConfig.isEmpty ? (
      <Survey model={checklistConfig} />
    ) : (
      <EmptyPage heading="Checklist is empty" iconName="clipboard" />
    );
  }, [isLoading, checklistConfig]);

  return (
    <ChecklistModalContainer closeModal={closeChecklist}>
      {content}
    </ChecklistModalContainer>
  );
};

export default ChecklistComponent;
