import { theme } from "../../../theme";

interface IChecklistCssVariables {
  [index: string]: string;
}

export const checklistCssColors: IChecklistCssVariables = {
  "--sjs-special-green": theme.palette.border.default,
  "--sjs-special-green-light": "rgba(99, 102, 241, 0.1)",
  "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
  "--sjs-special-blue": "rgba(67, 127, 217, 1)",
  "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
  "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
  "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
  "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
  "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
  "--sjs-questionpanel-backcolor": theme.palette.background.default,
  "--sjs-general-backcolor": theme.palette.background.secondary,
  "--sjs-general-backcolor-dim": theme.palette.background.secondary,
  "--sjs-primary-backcolor": theme.palette.border.default,
  "--sjs-primary-backcolor-dark": "rgba(20, 164, 139, 1)",
  "--sjs-primary-backcolor-light": "rgba(25, 179, 148, 0.1)",
  "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
  "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
  "--sjs-special-red": theme.palette.error.default,
  "--sjs-special-red-light": theme.palette.error.light as string,
  "--sjs-font-family": '"Roboto"',
  "--sjs-questionpanel-hovercolor": theme.palette.background.disable as string,
  "--sjs-mobile-font-editorfont-size": "14px",
};
