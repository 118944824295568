import { createColumnHelper } from "@tanstack/react-table";
import { IChecklistCompletionResponseDTO } from "../../../interfaces/checklists";
import { checkDate } from "../../../helpers/check-date.helper";
import { ReactNode } from "react";
import IconButton from "../../icon-button/icon-button";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";
import ChecklistModalContainer from "../../survey-components/checklist-modal-container/checklist-modal-container";
import ChecklistResultComponent from "../../survey-components/checklist-result-component/checklist-result-component";
import { t } from "i18next";

interface IChecklistCompletionsTableColumns {
  closeModal?: () => void;
  openModal?: (modalContent: ReactNode) => void;
  isCompanyChecklist?: boolean;
}

const { accessor, display } =
  createColumnHelper<IChecklistCompletionResponseDTO>();
export const createChecklistCompletionsTableColumns = ({
  closeModal,
  openModal,
  isCompanyChecklist,
}: IChecklistCompletionsTableColumns) => {
  const baseArray = [
    accessor("respondent", {
      header: t("tables.respondent"),
      size: 200,
      cell: ({ getValue }) => <TooltipCell value={getValue()} />,
    }),
    accessor("date", {
      header: t("tables.date"),
      size: 174,
      cell: ({ getValue }) => checkDate(getValue(), t),
    }),
    accessor("questionCount", {
      header: t("tables.totalQuestions"),
      size: 140,
    }),
    accessor("correctAnswers", {
      header: t("tables.requiredQuestions"),
      size: 140,
      cell: ({
        row: {
          original: { incorrectAnswers, correctAnswers },
        },
      }) => `${correctAnswers}/${correctAnswers + incorrectAnswers}`,
    }),
    display({
      header: "",
      id: "Show completion",
      size: 120,
      cell: ({
        row: {
          original: {
            id,
            checklistModel: { modelId },
          },
        },
      }) => {
        if (openModal && closeModal)
          return (
            <IconButton
              onClick={() =>
                openModal(
                  <ChecklistModalContainer closeModal={closeModal}>
                    <ChecklistResultComponent
                      completionId={id}
                      modelId={modelId}
                    />
                  </ChecklistModalContainer>
                )
              }
              name="result"
              hoverColor="warning"
              dataName={t("tables.showCompletion")}
            />
          );

        return null;
      },
    }),
  ];

  const locationCell = accessor("location.name", {
    header: t("tables.location"),
    size: 140,
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  });

  return isCompanyChecklist
    ? [baseArray[0], locationCell, ...baseArray.slice(1)]
    : baseArray;
};
