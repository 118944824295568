import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectOption } from "../../components/kyps-select/kyps-select";

interface CompanyState {
  selectedCompany: ISelectOption | null;
  areChecklistsAvailable: boolean;
  loading: boolean;
}

const initialState: CompanyState = {
  selectedCompany: null,
  areChecklistsAvailable: false,
  loading: true,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    selectCompany: (
      state,
      { payload }: PayloadAction<ISelectOption | null>
    ) => ({
      ...state,
      selectedCompany: payload,
    }),
    changeLoadingState: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      loading: payload,
    }),
    updateChecklistsState: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      areChecklistsAvailable: payload,
    }),
  },
});

export const { selectCompany, changeLoadingState, updateChecklistsState } =
  companySlice.actions;
export default companySlice.reducer;
