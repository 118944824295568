import * as CompanyActions from "./company/actions";
import * as UserActions from "./user/actions";
import * as UserLocationsActions from "./userLocations/actions";

const actions = {
  ...CompanyActions,
  ...UserActions,
  ...UserLocationsActions,
};

export default actions;
