import { Question } from "survey-core";
import { theme } from "../../../theme";

export const getQuestionStatusColor = (question: Question) => {
  if (!question.isRequired && !question.isAnswered)
    return theme.palette.background.disable;

  if (!!question.correctAnswer)
    return question?.isAnswerCorrect?.()
      ? theme.palette.success.default
      : theme.palette.error.default;

  if (question.isAnswered) return theme.palette.border.default;
};
