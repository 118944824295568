import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "tabulator-tables/dist/css/tabulator.min.css";
import "survey-analytics/survey.analytics.tabulator.css";
import { useGetChecklistModelByIdQuery } from "../../../api/checklists.api";
import { useGetCompletionByIdQuery } from "../../../api/checklists-completion.api";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import Loaders from "../../loaders/loaders";
import { getQuestionStatusColor } from "./checklist-result-component.helper";

interface IChecklistResultComponent {
  modelId: string;
  completionId: string;
}

const ChecklistResultComponent = ({
  modelId,
  completionId,
}: IChecklistResultComponent) => {
  const { data: apiModelData, isLoading: isApiModelDataLoading } =
    useGetChecklistModelByIdQuery(modelId);
  const { data: apiCompletionData, isLoading: isApiCompletionDataLoading } =
    useGetCompletionByIdQuery(completionId);

  const survey = new Model(apiModelData?.model);

  if (apiCompletionData) survey.data = JSON.parse(apiCompletionData.content);

  survey.mode = "display";
  survey.showProgressBar = "bottom";
  survey.showTimerPanel = "none";
  survey.onAfterRenderQuestion.add(function (_, { htmlElement, question }) {
    const questionStatusColor = getQuestionStatusColor(question);

    if (questionStatusColor) {
      htmlElement.style.border = `2px solid ${questionStatusColor}`;

      const radio = htmlElement.querySelector(
        'input[value="' + question.correctAnswer + '"]'
      );

      if (!!radio && radio.parentElement)
        radio.parentElement.style.color = questionStatusColor;
    }
  });

  if (isApiModelDataLoading || isApiCompletionDataLoading)
    return <Loaders variant="table" />;

  if (!apiModelData || !apiCompletionData)
    return <EmptyPage heading="There is no any result" iconName="clipboard" />;

  return <Survey model={survey} />;
};

export default ChecklistResultComponent;
