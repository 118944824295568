import { useEffect, useRef, useState } from "react";
import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  ExpandedState,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { handleOnDeleteIPoint } from "./location-table.helper";
import { createLocationTableColumns } from "./location-table.utils";
import KypsModal from "../../kyps-modal/kyps-modal";
import useModal from "../../../hooks/useModal";
import { useDeleteSensorMutation } from "../../../api/sensor.api";
import { useDeleteIPointMutation } from "../../../api/installation-point.api";
import { handleOnDeleteSensor } from "../sensors-table/sensors-table.helper";
import { ILocationTableData } from "../../../interfaces/tables";
import Loaders from "../../loaders/loaders";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { isEmpty } from "lodash";
import { UserRoles } from "../../../interfaces/roles";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { KypsButton } from "../../kyps-button/kyps-button";
import AddIPointSidebar from "../../kyps-sidebar/add-installation-point-sidebar/add-installation-point-sidebar";
import { TFunction } from "i18next";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import DeleteConfirm from "../../delete-confirm/delete-confirm";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import useDropdownMenu from "../../../hooks/useDropdownMenu";
import DropdownMenu from "../../dropdown-menu/dropdown-menu";

export interface ILocationTable {
  dashboardZones: ISelectOption[];
  selectedZone: ISelectOption;
  tableSensorData: ILocationTableData[];
  isLoading: boolean;
  minDate: Date | null;
  userRole: UserRoles;
  t: TFunction<"translations", undefined>;
}

const LocationTable = ({
  dashboardZones,
  selectedZone,
  isLoading,
  tableSensorData,
  minDate,
  userRole,
  t,
}: ILocationTable) => {
  const [sorting] = useState<SortingState>([{ id: "name", desc: false }]);
  const [expanded, setExpanded] = useState<ExpandedState>(true);
  const [selectedId, setSelectedId] = useState<string>("");

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [deleteSensor] = useDeleteSensorMutation();
  const [deleteIPoint] = useDeleteIPointMutation();
  const onSensorDelete = (id: string, name: string) =>
    handleOnDeleteSensor({ id, name, deleteSensor });

  const onIPointDelete = (id: string, name: string) =>
    handleOnDeleteIPoint({ id, name, deleteIPoint });

  const {
    closeDeleteConfirm,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    openerParams,
  } = useDeleteConfirm(scrollContainerRef);

  const {
    isDropdownMenuOpen,
    menuItems,
    dropdownMenuPosition,
    openDropdownMenu,
    setIsDropdownMenuOpen,
  } = useDropdownMenu(scrollContainerRef);
  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer && isDeleteConfirmOpen) {
        closeDeleteConfirm();
      }
    };

    if (scrollContainer) {
      window.addEventListener("resize", handleScroll);
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      }
    };
  }, [
    closeDeleteConfirm,
    setIsDropdownMenuOpen,
    isDeleteConfirmOpen,
    isDropdownMenuOpen,
  ]);

  const isZoneLoading = isLoading || isEmpty(selectedZone.label);

  const table = useReactTable({
    data: tableSensorData,
    columns: createLocationTableColumns({
      openModal,
      closeAndResetModal,
      selectedZone,
      zonesOptions: dashboardZones,
      deleteSensor: onSensorDelete,
      deleteIPoint: onIPointDelete,
      openDeleteConfirm,
      minDate,
      userRole,
      t,
      openDropdownMenu,
      isMenuOpen: isDropdownMenuOpen,
      selectedId,
      setSelectedId,
    }),
    state: {
      expanded,
      sorting,
      columnPinning: { left: ["name"] },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    autoResetPageIndex: false,
  });

  return (
    <>
      <KypsTable
        table={table}
        emptyMessage={
          !isLoading && isEmpty(tableSensorData) ? (
            <EmptyPage
              heading={t("emptyMessage.text", {
                entity: t("emptyMessage.entities.iPoint"),
              })}
              iconName="clipboard"
              actionButton={
                <AuthWrapper allowedRoles={managersRights}>
                  <KypsButton
                    onClick={() =>
                      openModal(
                        <AddIPointSidebar
                          closeModal={closeAndResetModal}
                          zoneId={selectedZone.value}
                          t={t}
                        />
                      )
                    }
                    placeholder={t("buttons.add.actionWithKey", {
                      key: t("entities.iPoint"),
                    })}
                  />
                </AuthWrapper>
              }
            />
          ) : null
        }
        hidePagination
        hideSorting
        isTransparentBg
        isLoading={isZoneLoading}
        loader={<Loaders variant="table" />}
        tableRef={scrollContainerRef}
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
        />
      )}
      {isDropdownMenuOpen && (
        <DropdownMenu
          menuItems={menuItems}
          position={dropdownMenuPosition}
          isOpen={isDropdownMenuOpen}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
        />
      )}
    </>
  );
};

export default LocationTable;
