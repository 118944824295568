import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { RefObject, useMemo, useRef, useState } from "react";
import { createLocationChecklistsTableColumns } from "./location-checklists-table.utils";
import KypsModal from "../../kyps-modal/kyps-modal";
import useModal from "../../../hooks/useModal";
import {
  useDeleteChecklistMutation,
  useGetAllLocationsChecklistModelsWithoutContentQuery,
} from "../../../api/checklists.api";
import { useAddChecklistCompletionMutation } from "../../../api/checklists-completion.api";
import { skipToken } from "@reduxjs/toolkit/query";
import { KypsButton } from "../../kyps-button/kyps-button";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { t } from "i18next";
import { checkElementVisibility } from "../../../helpers/check-element-visibility";
import DropdownMenu from "../../dropdown-menu/dropdown-menu";
import useDropdownMenu from "../../../hooks/useDropdownMenu";
import { IMenuItems } from "../../../interfaces/dropdown-menu";
import { transformToLocationChecklistsTableData } from "./location-checklists-table.helper";
import ChecklistCreatorComponent from "../../survey-components/checklist-creator-component/checklist-creator-component";
import { ChecklistEntityEnum } from "../../../interfaces/checklists";
import DeleteConfirm from "../../delete-confirm/delete-confirm";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import Loaders from "../../loaders/loaders";
import { LocationChecklistsTableHeaderWrapper } from "./location-checklists-table.styles";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { UserRolesEnum } from "../../../interfaces/roles";
import { Subheading } from "../../../styles/helper.styles";

interface ILocationChecklistsTable {
  tableHeaderRef: RefObject<HTMLDivElement>;
  companyId: string | null;
}

const LocationChecklistsTable = ({
  tableHeaderRef,
  companyId,
}: ILocationChecklistsTable) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const userRole = useAppSelector(
    ({ user: { user } }) => user?.role || UserRolesEnum.USER
  );
  const userLocations = useAppSelector(
    ({ userLocations: { userLocations } }) => userLocations
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 14,
  });
  const [sorting] = useState<SortingState>([{ id: "title", desc: false }]);
  const [expanded, setExpanded] = useState<ExpandedState>(true);

  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const {
    dropdownMenuPosition,
    isDropdownMenuOpen,
    menuItems,
    openDropdownMenu,
    setIsDropdownMenuOpen,
  } = useDropdownMenu(tableHeaderRef);
  const {
    deleteConfirmName,
    isDeleteConfirmOpen,
    openerParams,
    closeDeleteConfirm,
    deleteFunction,
    openDeleteConfirm,
  } = useDeleteConfirm(tableRef);

  const [deleteChecklistFunction] = useDeleteChecklistMutation();
  const [addCompletion] = useAddChecklistCompletionMutation();
  const { data: apiLocationsChecklists, isLoading } =
    useGetAllLocationsChecklistModelsWithoutContentQuery(
      companyId ?? skipToken
    );

  const locationsChecklistsData = useMemo(
    () =>
      apiLocationsChecklists
        ? transformToLocationChecklistsTableData(apiLocationsChecklists)
        : [],
    [apiLocationsChecklists]
  );

  const locationsSelectOptions: ISelectOption[] = useMemo(
    () =>
      apiLocationsChecklists
        ?.filter(({ id: locationId }) =>
          userLocations?.find(
            ({ id, userRole }) =>
              locationId === id && userRole === UserRolesEnum.MANAGER
          )
        )
        .map(({ name, id }) => ({
          value: id,
          label: name,
        })) ?? [],
    [apiLocationsChecklists, userLocations]
  );

  const table = useReactTable({
    columns: createLocationChecklistsTableColumns({
      openModal,
      closeModal: closeAndResetModal,
      addCompletion,
      openDeleteConfirm,
      deleteChecklistFunction,
      userRole,
    }),
    data: locationsChecklistsData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
      sorting,
      columnPinning: { left: ["title"] },
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    autoResetPageIndex: false,
  });

  const dropdownMenuItems: IMenuItems[] = useMemo(
    () =>
      locationsSelectOptions.map(({ label, value }) => ({
        name: label,
        onItemClick: () =>
          openModal(
            <ChecklistCreatorComponent
              entityName={label}
              entity={ChecklistEntityEnum.LOCATION}
              targetId={value}
              closeModal={closeAndResetModal}
            />
          ),
      })),
    [closeAndResetModal, locationsSelectOptions, openModal]
  );

  const locationChecklistsTableHeader = useMemo(
    () => [
      <LocationChecklistsTableHeaderWrapper key="LocationChecklistsTableHeaderWrapper">
        <Subheading>{t("tabs.locationChecklists")}</Subheading>
        <KypsButton
          onClick={(e) => {
            e.stopPropagation();
            openDropdownMenu({
              isDropdownMenuOpen: true,
              menuItems: dropdownMenuItems,
              position: checkElementVisibility(
                e.currentTarget.getBoundingClientRect(),
                dropdownMenuItems.length
              ),
            });
          }}
          placeholder={t("buttons.add.action")}
          hasIcon
          iconType="plus"
        />
      </LocationChecklistsTableHeaderWrapper>,
    ],
    [dropdownMenuItems, openDropdownMenu]
  );

  if (isLoading) return <Loaders variant="table" />;

  return (
    <>
      <KypsTable
        table={table}
        tableHeaderElements={locationChecklistsTableHeader}
        tableRef={tableRef}
        hideSorting
        hasNoActions
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      {isDropdownMenuOpen && (
        <DropdownMenu
          menuItems={menuItems}
          position={dropdownMenuPosition}
          isOpen={isDropdownMenuOpen}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
          header={t("checklists.actions.addToLocation")}
        />
      )}
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
        />
      )}
    </>
  );
};

export default LocationChecklistsTable;
