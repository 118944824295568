import { editorLocalization } from "survey-creator-core";

const customLocaleStrings = {
  survey: {
    edit: "Редагувати",
    externalHelpLink: "Дивіться і навчайтеся, як створювати опитування",
    externalHelpLinkUrl:
      "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Будь ласка, перетягніть питання сюди з Панелі інструментів.",
    addLogicItem: "Створіть правило для налаштування потоку опитування.",
    copy: "Копіювати",
    duplicate: "Дублювати",
    addToToolbox: "Додати до Панелі інструментів",
    deletePanel: "Видалити Панель",
    deleteQuestion: "Видалити Питання",
    convertTo: "Перетворити на",
    drag: "Перетягнути елемент",
    license:
      "Для використання нашої бібліотеки потрібна <a href='https://surveyjs.io/licensing'>ліцензія для розробників</a>. Якщо у вас є активна ліцензія, <a href='https://surveyjs.io/remove-alert-banner'>налаштуйте свій ліцензійний ключ</a> і переконайтеся, що ви використовуєте останню версію.",
  },
  tabs: {
    preview: "Попередній перегляд",
    theme: "Теми",
    translation: "Переклади",
    designer: "Дизайнер",
    editor: "JSON Редактор",
    logic: "Логіка",
  },
  qt: {
    default: "За замовчуванням",
    checkbox: "Прапорці",
    comment: "Довгий текст",
    imagepicker: "Вибір зображення",
    ranking: "Ранжування",
    image: "Зображення",
    dropdown: "Випадаючий список",
    tagbox: "Випадаючий список з мультивибором",
    file: "Завантаження файлу",
    html: "HTML",
    matrix: "Матриця з одним вибором",
    matrixdropdown: "Матриця з мультивибором",
    matrixdynamic: "Динамічна матриця",
    multipletext: "Кілька текстових полів",
    panel: "Панель",
    paneldynamic: "Динамічна панель",
    radiogroup: "Група радіо-кнопок",
    rating: "Шкала оцінювання",
    text: "Однолінійний текстовий ввід",
    boolean: "Так/Ні (Булевий тип)",
    expression: "Вираз (тільки для читання)",
    signaturepad: "Підпис",
    buttongroup: "Група кнопок",
  },
  toolboxCategories: {
    general: "Загальні",
    choice: "Питання з вибором",
    text: "Питання з текстовим ввідом",
    containers: "Контейнери",
    matrix: "Питання-матриці",
    misc: "Різне",
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "За замовчуванням ({0})",
    survey: "Опитування",
    settings: "Налаштування",
    settingsTooltip: "Відкрити налаштування",
    surveySettings: "Налаштування опитування",
    surveySettingsTooltip: "Налаштування опитування",
    themeSettings: "Налаштування теми",
    themeSettingsTooltip: "Налаштування теми",
    showPanel: "Показати панель",
    hidePanel: "Сховати панель",
    prevSelected: "Вибрати попереднє",
    nextSelected: "Вибрати наступне",
    prevFocus: "Фокус на попереднє",
    nextFocus: "Фокус на наступне",
    surveyTypeName: "Опитування",
    pageTypeName: "Сторінка",
    panelTypeName: "Панель",
    questionTypeName: "Питання",
    columnTypeName: "Колонка",
    addNewPage: "Додати нову сторінку",
    moveRight: "Прокрутити праворуч",
    moveLeft: "Прокрутити ліворуч",
    deletePage: "Видалити сторінку",
    editPage: "Редагувати сторінку",
    edit: "Редагувати",
    newPageName: "сторінка",
    newQuestionName: "питання",
    newPanelName: "панель",
    newTextItemName: "текст",
    defaultV2Theme: "За замовчуванням",
    modernTheme: "Сучасний",
    defaultTheme: "За замовчуванням (старий)",
    testSurveyAgain: "Переглянути опитування знову",
    testSurveyWidth: "Ширина опитування: ",
    navigateToMsg: "Вам потрібно було перейти до",
    saveSurvey: "Зберегти опитування",
    saveSurveyTooltip: "Зберегти опитування",
    saveTheme: "Зберегти тему",
    saveThemeTooltip: "Зберегти тему",
    jsonHideErrors: "Приховати помилки",
    jsonShowErrors: "Показати помилки",
    undo: "Скасувати",
    redo: "Повторити",
    undoTooltip: "Скасувати останню зміну",
    redoTooltip: "Повторити зміну",
    showMoreChoices: "Показати більше",
    showLessChoices: "Показати менше",
    copy: "Копіювати",
    cut: "Вирізати",
    paste: "Вставити",
    copyTooltip: "Копіювати вибране в буфер обміну",
    cutTooltip: "Вирізати вибране в буфер обміну",
    pasteTooltip: "Вставити з буфера обміну",
    options: "Опції",
    generateValidJSON: "Згенерувати правильний JSON",
    generateReadableJSON: "Згенерувати читабельний JSON",
    toolbox: "Інструменти",
    "property-grid": "Властивості",
    toolboxFilteredTextPlaceholder: "Наберіть для пошуку...",
    toolboxNoResultsFound: "Результатів не знайдено",
    propertyGridFilteredTextPlaceholder: "Наберіть для пошуку...",
    propertyGridNoResultsFound: "Результатів не знайдено",
    correctJSON: "Будь ласка, виправте JSON.",
    surveyResults: "Результати опитування",
    surveyResultsTable: "У вигляді таблиці",
    surveyResultsJson: "У вигляді JSON",
    resultsTitle: "Назва питання",
    resultsName: "Назва питання",
    resultsValue: "Значення відповіді",
    resultsDisplayValue: "Відображене значення",
    modified: "Змінено",
    saving: "Зберігається",
    saved: "Збережено",
    propertyEditorError: "Помилка",
    saveError: "Помилка! Вміст редактора не збережено.",
    translationPropertyGridTitle: "Налаштування мови",
    themePropertyGridTitle: "Налаштування теми",
    translationLanguages: "Мови",
    translationDeleteLanguage:
      "Ви впевнені, що хочете видалити всі рядки для цієї мови?",
    translationAddLanguage: "Виберіть мову для перекладу",
    translationShowAllStrings: "Усі рядки",
    translationShowUsedStringsOnly: "Тільки використані рядки",
    translationShowAllPages: "Усі сторінки",
    translationNoStrings:
      "Немає рядків для перекладу. Будь ласка, змініть фільтр.",
    translationExportToSCVButton: "Експортувати в CSV",
    translationImportFromSCVButton: "Імпортувати з CSV",
    translateUsigAI: "Автоматичний переклад всіх",
    translateUsigAIFrom: "Перекласти з: ",
    translationDialogTitle: "Неперекладені рядки",
    translationMergeLocaleWithDefault: "Об'єднати {0} з мовою за замовчуванням",
    translationPlaceHolder: "Переклад...",
    translationSource: "Джерело: ",
    translationTarget: "Ціль: ",
    themeExportButton: "Експортувати",
    themeImportButton: "Імпортувати",
    surveyJsonExportButton: "Експортувати",
    surveyJsonImportButton: "Імпортувати",
    surveyJsonCopyButton: "Копіювати в буфер обміну",
    themeResetButton: "Скинути налаштування теми до стандартних",
    themeResetConfirmation:
      "Ви дійсно хочете скинути тему? Всі ваші налаштування будуть втрачені.",
    themeResetConfirmationOk: "Так, скинути тему",
    bold: "Жирний",
    italic: "Курсив",
    underline: "Підкреслений",
    addNewQuestion: "Додати питання",
    selectPage: "Виберіть сторінку...",
    carryForwardChoicesCopied: "Варіанти вибору скопійовані з",
    choicesLoadedFromWebText: "Варіанти вибору завантажені з веб-сервісу.",
    choicesLoadedFromWebLinkText: "Перейти до налаштувань",
    choicesLoadedFromWebPreviewTitle:
      "Попередній перегляд завантажених варіантів вибору",
    htmlPlaceHolder: "HTML контент буде тут.",
    panelPlaceHolder: "Перетягніть питання з інструментальної панелі сюди.",
    surveyPlaceHolder:
      "Опитування порожнє. Перетягніть елемент з інструментальної панелі або натисніть кнопку нижче.",
    pagePlaceHolder:
      "Сторінка порожня. Перетягніть елемент з інструментальної панелі або натисніть кнопку нижче.",
    imagePlaceHolder:
      "Перетягніть зображення сюди або натисніть кнопку нижче та виберіть зображення для завантаження",
    surveyPlaceHolderMobile:
      'Натисніть кнопку "Додати питання" нижче, щоб почати створення вашої форми.',
    pagePlaceHolderMobile:
      'Натисніть кнопку "Додати питання" нижче, щоб додати новий елемент на сторінку.',
    panelPlaceHolderMobile:
      'Натисніть кнопку "Додати питання" нижче, щоб додати новий елемент до панелі.',
    imagePlaceHolderMobile:
      "Натисніть кнопку нижче та виберіть зображення для завантаження",
    imageChooseImage: "Вибрати зображення",
    addNewTypeQuestion: "Додати {0}", //{0} - локалізований тип питання
    chooseLogoPlaceholder: "[ЛОГО]",
    choices_Item: "Елемент ",
    selectFile: "Вибрати файл",
    removeFile: "Видалити файл",
    lg: {
      addNewItem: "Додати нове правило",
      empty_tab: "Створіть правило, щоб налаштувати перебіг опитування.",
      page_visibilityName: "Показати/сховати сторінку",
      page_enableName: "Увімкнути/вимкнути сторінку",
      page_requireName: "Зробити сторінку обов'язковою",
      panel_visibilityName: "Показати/сховати панель",
      panel_enableName: "Увімкнути/вимкнути панель",
      panel_requireName: "Зробити панель обов'язковою",
      question_visibilityName: "Показати/сховати питання",
      question_enableName: "Увімкнути/вимкнути питання",
      question_requireName: "Зробити питання обов'язковим",
      question_resetValueName: "Скинути значення питання",
      question_setValueName: "Встановити значення питання",
      column_visibilityName: "Показати/сховати колонку",
      column_enableName: "Увімкнути/вимкнути колонку",
      column_requireName: "Зробити колонку обов'язковою",
      column_resetValueName: "Скинути значення колонки",
      column_setValueName: "Встановити значення колонки",
      trigger_completeName: "Завершити опитування",
      trigger_setvalueName: "Встановити відповідь",
      trigger_copyvalueName: "Скопіювати відповідь",
      trigger_skipName: "Перейти до питання",
      trigger_runExpressionName: "Запустити вираз",
      completedHtmlOnConditionName: 'Встановити розмітку сторінки "Дякуємо"',
      page_visibilityDescription:
        "Зробіть сторінку видимою, коли логічний вираз оцінюється як істинний. В іншому випадку, залиште сторінку невидимою.",
      panel_visibilityDescription:
        "Зробіть панель видимою, коли логічний вираз оцінюється як істинний. В іншому випадку, залиште панель невидимою.",
      panel_enableDescription:
        "Зробіть панель і всі елементи всередині неї активними, коли логічний вираз оцінюється як істинний. В іншому випадку, залиште їх неактивними.",
      question_visibilityDescription:
        "Зробіть питання видимим, коли логічний вираз оцінюється як істинний. В іншому випадку, залиште питання невидимим.",
      question_enableDescription:
        "Зробіть питання активним, коли логічний вираз оцінюється як істинний. В іншому випадку, залиште питання неактивним.",
      question_requireDescription:
        "Питання стає обов'язковим, коли логічний вираз оцінюється як істинний.",
      trigger_completeDescription:
        'Коли логічний вираз оцінюється як істинний, опитування завершується, і респондент бачить сторінку "Дякуємо".',
      trigger_setvalueDescription:
        "Коли значення питання, що використовуються в логічному виразі, змінюються і вираз оцінюється як істинний, вказане значення присвоюється вибраному питанню.",
      trigger_copyvalueDescription:
        "Коли значення питання, що використовуються в логічному виразі, змінюються і вираз оцінюється як істинний, значення вибраного питання копіюється в інше питання.",
      trigger_skipDescription:
        "Коли логічний вираз оцінюється як істинний, опитування фокусується/переміщається на вибране питання.",
      trigger_runExpressionDescription:
        "Коли логічний вираз оцінюється як істинний, опитування оцінює інший вираз. Опціонально, результат останнього виразу може бути присвоєний як значення вибраному питанню.",
      completedHtmlOnConditionDescription:
        'Якщо логічний вираз оцінюється як істинний, на сторінці "Дякуємо" відображається вказаний контент.',
      itemExpressionText: "Коли вираз: '{0}' повертає істинний результат", //{0} - вираз
      itemEmptyExpressionText: "Нове правило",
      page_visibilityText: "зробити сторінку {0} видимою", //{0} назва сторінки
      panel_visibilityText: "зробити панель {0} видимою", //{0} назва панелі
      panel_enableText: "зробити панель {0} активною", //{0} назва панелі
      question_visibilityText: "зробити питання {0} видимим", //{0} назва питання
      question_enableText: "зробити питання {0} активним", //{0} назва питання
      question_requireText: "зробити питання {0} обов'язковим", //{0} назва питання
      question_resetValueText: "скинути значення для питання: {0}", //{0} назва питання
      question_setValueText: "присвоїти значення: {1} питанню: {0}", //{0} назва питання і {1} setValueExpression
      column_visibilityText: "зробити колонку {0} питання {1} видимою", //{0} назва колонки, {1} назва питання
      column_enableText: "зробити колонку {0} питання {1} активною", //{0} назва колонки, {1} назва питання
      column_requireText: "зробити колонку {0} питання {1} обов'язковою", //{0} назва колонки, {1} назва питання
      column_resetValueText: "скинути значення клітинки для колонки: {0}", //{0} назва колонки
      column_setValueText: "присвоїти значення клітинки: {1} колонці: {0}", //{0} назва колонки і {1} setValueExpression
      setValueExpressionPlaceholder:
        "Вираз, результат якого буде присвоєно цільовому питанню.",
      trigger_completeText: "опитування стає завершеним",
      trigger_setvalueText: "встановити в питання: {0} значення {1}", //{0} назва питання, {1} setValue
      trigger_setvalueEmptyText: "очистити значення питання: {0}", //{0} назва питання
      trigger_copyvalueText: "скопіювати в питання: {0} значення з питання {1}", //{0} і {1} назви питань
      trigger_skipText: "опитування пропускає до питання {0}", //{0} назва питання
      trigger_runExpressionText1: "запустити вираз: '{0}'", //{0} вираз
      trigger_runExpressionText2: " і присвоїти його результат питанню: {0}", //{0} назва питання
      completedHtmlOnConditionText:
        'показати користувацький текст для сторінки "Дякуємо".',
      showAllQuestions: "Всі питання",
      showAllActionTypes: "Всі типи дій",
      conditions: "Умов(и)",
      actions: "Дія(и)",
      expressionEditorTitle: "Визначити умови",
      actionsEditorTitle: "Визначити дії",
      deleteAction: "Видалити дію",
      addNewAction: "Додати дію",
      selectedActionCaption: "Вибрати дію...",
      expressionInvalid:
        "Логічний вираз пустий або недійсний. Будь ласка, виправте його.",
      noActionError: "Будь ласка, додайте хоча б одну дію.",
      actionInvalid: "Будь ласка, виправте помилки у вашій дії.",
      uncompletedRule_title: "Логічні правила неповні",
      uncompletedRule_text:
        "Ви не завершили деякі з логічних правил. Якщо ви зараз покинете вкладку, зміни будуть втрачені. Ви все ще хочете покинути вкладку, не завершивши зміни?",
      uncompletedRule_apply: "Так",
      uncompletedRule_cancel: "Ні, я хочу завершити правила",
    },
  },
  // Property Editors
  pe: {
    panel: {
      name: "Назва панелі",
      title: "Заголовок панелі",
      description: "Опис панелі",
      visibleIf: "Зробити панель видимою, якщо",
      requiredIf: "Зробити панель обов'язковою, якщо",
      questionsOrder: "Порядок питань у панелі",
      page: "Перемістити панель на сторінку",
      startWithNewLine: "Відображати панель на новому рядку",
      state: "Стан згортання панелі",
      width: "Ширина панелі в рядку",
      minWidth: "Мінімальна ширина панелі",
      maxWidth: "Максимальна ширина панелі",
      showNumber: "Номер панелей",
    },
    paneldynamic: {
      name: "Назва панелі",
      title: "Заголовок панелі",
      description: "Опис панелі",
      visibleIf: "Зробити панель видимою, якщо",
      requiredIf: "Зробити панель обов'язковою, якщо",
      page: "Перемістити панель на сторінку",
      startWithNewLine: "Відображати панель на новому рядку",
      state: "Стан згортання панелі",
      width: "Ширина панелі в рядку",
      minWidth: "Мінімальна ширина панелі",
      maxWidth: "Максимальна ширина панелі",
      confirmDelete: "Підтвердити видалення панелі",
      templateDescription: "Шаблон опису панелі",
      templateTitle: "Шаблон заголовка панелі",
      noEntriesText: "Текст пустої панелі",
      templateTabTitle: "Шаблон заголовка вкладки",
      tabTitlePlaceholder: "Заповнювач заголовка вкладки",
      templateVisibleIf: "Зробити індивідуальну панель видимою, якщо",
      hideNumber: "Приховати номер панелі",
      titleLocation: "Вирівнювання заголовка панелі",
      descriptionLocation: "Вирівнювання опису панелі",
      templateTitleLocation: "Вирівнювання заголовка питання",
      templateErrorLocation: "Вирівнювання повідомлення про помилку",
      newPanelPosition: "Місцезнаходження нової панелі",
      showRangeInProgress: "Показати індикатор прогресу",
      showProgressBar: "Показати індикатор прогресу",
      progressBarLocation: "Вирівнювання індикатора прогресу",
      keyName: "Запобігти дублюванню відповідей у наступному питанні",
    },
    question: {
      name: "Назва питання",
      title: "Заголовок питання",
      description: "Опис питання",
      visibleIf: "Зробити питання видимим, якщо",
      requiredIf: "Зробити питання обов'язковим, якщо",
      page: "Перемістити питання на сторінку",
      state: "Стан згортання поля питання",
      hideNumber: "Приховати номер питання",
      titleLocation: "Вирівнювання заголовка питання",
      descriptionLocation: "Вирівнювання опису питання",
      errorLocation: "Вирівнювання повідомлення про помилку",
      indent: "Збільшити внутрішній відступ",
      width: "Ширина питання в рядку",
      minWidth: "Мінімальна ширина питання",
      maxWidth: "Максимальна ширина питання",
      textUpdateMode: "Оновити значення поля вводу",
    },
    signaturepad: {
      signatureWidth: "Ширина області підпису",
      signatureHeight: "Висота області підпису",
      signatureAutoScaleEnabled: "Автоматичне масштабування області підпису",
      showPlaceholder: "Показати заповнювач у області підпису",
      placeholder: "Текст заповнювача",
      placeholderReadOnly:
        "Текст заповнювача в режимі тільки для читання або перегляду",
      allowClear: "Показати кнопку Очистити в області підпису",
      penMinWidth: "Мінімальна ширина лінії",
      penMaxWidth: "Максимальна ширина лінії",
      penColor: "Колір лінії",
    },
    comment: {
      rows: "Висота поля вводу (в рядках)",
    },
    showQuestionNumbers: "Нумерація питань",
    questionStartIndex: "Тип індексації питань",
    expression: {
      name: "Назва виразу",
      title: "Заголовок виразу",
      description: "Опис виразу",
      expression: "Вираз",
    },
    trigger: {
      expression: "Вираз",
    },
    calculatedvalue: {
      expression: "Вираз",
    },
    // survey templates
    survey: {
      title: "Назва опитування",
      description: "Опис опитування",
    },
    page: {
      name: "Назва сторінки",
      title: "Заголовок сторінки",
      description: "Опис сторінки",
      visibleIf: "Показати сторінку, якщо",
      requiredIf: "Зробити сторінку обов'язковою, якщо",
      maxTimeToFinish: "Обмеження часу на завершення сторінки",
      questionsOrder: "Порядок питань на сторінці",
    },
    matrixdropdowncolumn: {
      name: "Назва стовпця",
      title: "Заголовок стовпця",
      isUnique: "Запобігти дублюванню відповідей",
      width: "Ширина стовпця",
      minWidth: "Мінімальна ширина стовпця",
      rows: "Висота поля вводу (в рядках)",
      visibleIf: "Показати стовпець, якщо",
      requiredIf: "Зробити стовпець обов'язковим, якщо",
      showInMultipleColumns: "Кожна опція в окремому стовпці",
    },
    multipletextitem: {
      name: "Назва",
      title: "Заголовок",
    },
    masksettings: {
      saveMaskedValue: "Зберегти зашифроване значення в результатах опитування",
    },
    patternmask: {
      pattern: "Шаблон значення",
    },
    datetimemask: {
      min: "Мінімальне значення",
      max: "Максимальне значення",
    },
    numericmask: {
      allowNegativeValues: "Дозволити від'ємні значення",
      thousandsSeparator: "Роздільник тисяч",
      decimalSeparator: "Роздільник десяткових",
      precision: "Точність значення",
      min: "Мінімальне значення",
      max: "Максимальне значення",
    },
    currencymask: {
      prefix: "Префікс валюти",
      suffix: "Суфікс валюти",
    },
    imageHeight: "Висота області відображення",
    imageWidth: "Ширина області відображення",
    valueName: "Ідентифікатор з'єднання",
    rateDescriptionLocation: "Вирівнювання мітки",
    size: "Ширина поля вводу (в символах)",
    cellErrorLocation: "Вирівнювання повідомлення про помилку в клітинці",
    enabled: "Активований",
    disabled: "Деактивований",
    inherit: "Спадкувати",
    apply: "Застосувати",
    ok: "ОК",
    save: "Зберегти",
    clear: "Очистити",
    saveTooltip: "Зберегти",
    cancel: "Скасувати",
    set: "Встановити",
    reset: "Скинути",
    change: "Змінити",
    refresh: "Оновити",
    close: "Закрити",
    delete: "Видалити",
    add: "Додати",
    addNew: "Додати новий",
    addItem: "Натисніть, щоб додати елемент...",
    removeItem: "Натисніть, щоб видалити елемент...",
    dragItem: "Перетягніть елемент",
    addOther: "Інше",
    addSelectAll: "Вибрати все",
    addNone: "Жоден",
    removeAll: "Видалити все",
    edit: "Редагувати",
    back: "Повернутися без збереження",
    backTooltip: "Повернутися без збереження",
    saveAndBack: "Зберегти та повернутися",
    saveAndBackTooltip: "Зберегти та повернутися",
    doneEditing: "Завершено",
    editChoices: "Редагувати вибір",
    showChoices: "Показати вибір",
    move: "Перемістити",
    empty: "<порожньо>",
    emptyValue: "Значення порожнє",
    fastEntry: "Швидкий ввід",
    fastEntryNonUniqueError: "Значення '{0}' не є унікальним",
    fastEntryChoicesCountError:
      "Будь ласка, обмежте кількість елементів від {0} до {1}",
    fastEntryChoicesMinCountError:
      "Будь ласка, введіть принаймні {0} елементів",
    fastEntryPlaceholder:
      "Введіть список варіантів вибору та їхні ID у наступному форматі:\n\nid|варіант\n\nID варіанту вибору не видимий респондентам і може бути використаний у умовних правилах.",
    formEntry: "Введення форми",
    testService: "Перевірити службу",
    itemSelectorEmpty: "Будь ласка, виберіть елемент",
    conditionActionEmpty: "Будь ласка, виберіть дію",
    conditionSelectQuestion: "Виберіть питання...",
    conditionSelectPage: "Виберіть сторінку...",
    conditionSelectPanel: "Виберіть панель...",
    conditionValueQuestionTitle: "Будь ласка, введіть/виберіть значення",
    aceEditorHelp:
      "Натисніть ctrl+space для отримання підказки щодо завершення виразу",
    aceEditorRowTitle: "Поточний рядок",
    aceEditorPanelTitle: "Поточна панель",
    showMore: "Для отримання додаткових відомостей зверніться до документації",
    assistantTitle: "Доступні питання",
    cellsEmptyRowsColumns: "Має бути щонайменше один стовпець або рядок",
    showPreviewBeforeComplete: "Огляд перед надсиланням",
    overridingPropertyPrefix: "Увімкнено умовою",
    resetToDefaultCaption: "Скинути",
    propertyIsEmpty: "Будь ласка, введіть значення",
    propertyIsNoUnique: "Будь ласка, введіть унікальне значення",
    propertyNameIsNotUnique: "Будь ласка, введіть унікальне ім'я",
    propertyNameIsIncorrect:
      'Не використовуйте зарезервовані слова: "item", "choice", "panel", "row".',
    listIsEmpty: "У вас поки що немає елементів",
    "listIsEmpty@choices": "У вас поки що немає варіантів вибору",
    "listIsEmpty@columns": "У вас поки що немає стовпців",
    "listIsEmpty@rows": "У вас поки що немає рядків",
    "listIsEmpty@validators": "У вас поки що немає правил перевірки",
    "listIsEmpty@calculatedValues":
      "У вас поки що немає користувацьких змінних",
    "listIsEmpty@triggers": "У вас поки що немає тригерів",
    "listIsEmpty@navigateToUrlOnCondition": "У вас поки що немає посилань",
    "addNew@choices": "Додати новий варіант",
    "addNew@columns": "Додати новий стовпець",
    "addNew@rows": "Додати новий рядок",
    "addNew@validators": "Додати нове правило",
    "addNew@calculatedValues": "Додати нову змінну",
    "addNew@triggers": "Додати новий тригер",
    "addNew@navigateToUrlOnCondition": "Додати нове URL",
    expressionIsEmpty: "Вираз порожній",
    value: "Значення",
    text: "Текст",
    rowid: "ID рядка",
    imageLink: "URL зображення або відеофайлу",
    columnEdit: "Редагувати стовпець: {0}",
    itemEdit: "Редагувати елемент: {0}",
    url: "URL",
    path: "Шлях до даних",
    choicesbyurl: {
      url: "URL веб-служби",
      valueName: "Отримати значення для збереження з наступного властивості",
    },
    titleName: "Отримати значення для відображення з наступної властивості",
    imageLinkName: "Отримати URL файлів з наступної властивості",
    allowEmptyResponse: "Приймати порожню відповідь",
    titlePlaceholder: "Назва",
    surveyTitlePlaceholder: "Назва опитування",
    pageTitlePlaceholder: "Сторінка {num}",
    descriptionPlaceholder: "Опис",
    surveyDescriptionPlaceholder: "Опис",
    pageDescriptionPlaceholder: "Опис",
    textWrapEnabled: "Переносити вибори",
    showOtherItem: 'Увімкнути опцію "Інше"',
    otherText: 'Перейменувати опцію "Інше"',
    showNoneItem: 'Увімкнути опцію "Жоден"',
    showRefuseItem: 'Увімкнути опцію "Відмовляюся відповідати"',
    showDontKnowItem: 'Увімкнути опцію "Не знаю"',
    noneText: 'Перейменувати опцію "Жоден"',
    showSelectAllItem: 'Увімкнути опцію "Вибрати все"',
    selectAllText: 'Перейменувати опцію "Вибрати все"',
    choicesMin: "Мінімальне значення для авто-згенерованих елементів",
    choicesMax: "Максимальне значення для авто-згенерованих елементів",
    choicesStep: "Крокове значення для авто-згенерованих елементів",
    name: "Назва",
    title: "Назва",
    cellType: "Тип вводу в клітинці",
    colCount: "Кількість стовпців",
    choicesOrder: "Порядок виборів",
    visible: "Видимий",
    isRequired: "Обов'язкове",
    markRequired: "Позначити як обов'язкове",
    removeRequiredMark: "Прибрати позначку обов'язковості",
    isAllRowRequired: "Вимагати відповідь у кожному рядку",
    eachRowUnique: "Запобігти дублюванню відповідей у рядках",
    requiredErrorText: "Повідомлення про помилку для обов'язкових питань",
    startWithNewLine: "Відобразити питання на новому рядку",
    rows: "Рядки",
    cols: "Стовпці",
    placeholder: "Текст підказки в полі вводу",
    showPreview: "Показати область попереднього перегляду",
    storeDataAsText: "Зберігати вміст файлу в JSON результаті як текст",
    maxSize: "Максимальний розмір файлу (в байтах)",
    rowCount: "Кількість рядків",
    columnLayout: "Макет стовпців",
    addRowLocation: "Вирівнювання кнопки 'Додати рядок'",
    transposeData: "Перетворити рядки в стовпці",
    addRowText: "Текст кнопки 'Додати рядок'",
    removeRowText: "Текст кнопки 'Видалити рядок'",
    rateMin: "Мінімальне значення рейтингу",
    rateMax: "Максимальне значення рейтингу",
    rateStep: "Крокове значення",
    minRateDescription: "Опис мінімального значення",
    maxRateDescription: "Опис максимального значення",
    inputType: "Тип вводу",
    optionsCaption: "Підказка для опцій",
    defaultValue: "За замовчуванням",
    cellsDefaultRow: "Тексти за замовчуванням",
    surveyEditorTitle: "Редагувати налаштування опитування",
    qEditorTitle: "Редагувати: {0}",
    maxLength: "Максимальна довжина символів",
    buildExpression: "Створити",
    editExpression: "Редагувати",
    and: "і",
    or: "або",
    remove: "Видалити",
    addCondition: "Додати умову",
    emptyLogicPopupMessage: "Виберіть питання, щоб почати налаштування умов.",
    if: "Якщо",
    then: "тоді",
    setToName: "Цільове питання",
    fromName: "Питання, з якого копіювати відповідь",
    gotoName: "Питання, на яке пропустити",
    ruleIsNotSet: "Правило неправильне",
    includeIntoResult: "Додати до результатів опитування",
    showTitle: "Показати назву та опис",
    expandCollapseTitle: "Розгорнути/згорнути назву",
    locale: "Вибрати мову опитування",
    simulator: "Вибрати тип пристрою",
    landscapeOrientation: "Переключитися на ландшафтну орієнтацію",
    portraitOrientation: "Переключитися на портретну орієнтацію",
    mode: "Режим відображення опитування",
    clearInvisibleValues: "Очистити приховані значення питань",
    cookieName: "Обмежити однією відповіддю",
    sendResultOnPageNext:
      "Автоматично зберігати прогрес опитування при зміні сторінки",
    storeOthersAsComment:
      'Зберігати значення опції "Інше" як окрему властивість',
    showPageTitles: "Показати назви сторінок",
    showPageNumbers: "Показати номери сторінок",
    pagePrevText: "Текст кнопки 'Попередня сторінка'",
    pageNextText: "Текст кнопки 'Наступна сторінка'",
    completeText: "Текст кнопки 'Завершити опитування'",
    previewText: "Текст кнопки 'Огляд відповідей'",
    editText: "Текст кнопки 'Редагувати відповідь'",
    startSurveyText: "Текст кнопки 'Почати опитування'",
    showNavigationButtons: "Показати/сховати кнопки навігації",
    showPrevButton: 'Показати кнопку "Попередня сторінка"',
    firstPageIsStarted: "Перша сторінка є стартовою сторінкою",
    showCompletedPage: 'Показати сторінку "Дякуємо"',
    goNextPageAutomatic: "Автоматично переходити на наступну сторінку",
    allowCompleteSurveyAutomatic: "Завершити опитування автоматично",
    showProgressBar: "Вирівнювання прогрес-бару",
    questionTitleLocation: "Вирівнювання заголовка питання",
    questionTitleWidth: "Ширина заголовка питання",
    requiredText: "Обов'язковий(-і) символ(-и)",
    questionTitleTemplate:
      "Шаблон заголовка питання, за замовчуванням: '{no}. {require} {title}'",
    questionErrorLocation: "Вирівнювання повідомлення про помилку",
    focusFirstQuestionAutomatic: "Фокус на перше питання на новій сторінці",
    questionsOrder: "Порядок питань",
    maxTimeToFinish: "Часовий ліміт для завершення опитування",
    maxTimeToFinishPage: "Часовий ліміт для завершення однієї сторінки",
    showTimerPanel: "Вирівнювання таймера",
    showTimerPanelMode: "Режим таймера",
    renderMode: "Режим відображення панелі",
    allowAddPanel: "Увімкнути додавання панелей",
    allowRemovePanel: "Увімкнути видалення панелей",
    panelAddText: "Текст кнопки 'Додати панель'",
    panelRemoveText: "Текст кнопки 'Видалити панель'",
    isSinglePage: "Показати всі елементи на одній сторінці",
    html: "HTML розмітка",
    setValue: "Відповідь",
    dataFormat: "Формат зберігання",
    allowAddRows: "Увімкнути додавання рядків",
    allowRemoveRows: "Увімкнути видалення рядків",
    allowRowsDragAndDrop: "Увімкнути перестановку рядків",
    responsiveImageSizeHelp:
      "Не застосовується, якщо ви вказали точну ширину або висоту області відображення.",
    minImageWidth: "Мінімальна ширина області відображення",
    maxImageWidth: "Максимальна ширина області відображення",
    minImageHeight: "Мінімальна висота області відображення",
    maxImageHeight: "Максимальна висота області відображення",
    minValue: "Мінімальне значення",
    maxValue: "Максимальне значення",
    minLength: "Мінімальна довжина (в символах)",
    allowDigits: "Дозволити цифри",
    minCount: "Мінімальна кількість",
    maxCount: "Максимальна кількість",
    regex: "Регулярний вираз",
    surveyvalidator: {
      text: "Повідомлення про помилку",
      expression: "Вираз для перевірки",
    },
    totalText: "Заголовок рядка загальної суми",
    totalType: "Метод агрегації",
    totalExpression: "Вираз загальної суми",
    totalDisplayStyle: "Формат відображення загальної суми",
    totalCurrency: "Валюта",
    totalFormat: "Форматований рядок",
    logo: "Логотип опитування",
    questionsOnPageMode: "Макет опитування",
    maxTextLength: "Обмежити довжину відповіді",
    maxOthersLength: "Обмежити довжину коментаря",
    commentAreaRows: "Висота області коментаря (у рядках)",
    autoGrowComment: "Автоматично розширювати текстові області",
    allowResizeComment:
      "Дозволити користувачам змінювати розмір текстових областей",
    textUpdateMode: "Оновлення значень полів вводу",
    maskType: "Тип маски вводу",
    focusOnFirstError: "Фокус на першій недійсній відповіді",
    checkErrorsMode: "Запустити перевірку",
    validateVisitedEmptyFields: "Перевіряти порожні поля при втраті фокусу",
    navigateToUrl: "Перенаправлення на зовнішнє посилання після надсилання",
    navigateToUrlOnCondition: "Динамічне зовнішнє посилання",
    completedBeforeHtml:
      "Розмітка для показу, якщо користувач вже заповнив це опитування",
    completedHtml: '"Дякуємо" розмітка сторінки',
    completedHtmlOnCondition: 'Динамічна розмітка сторінки "Дякуємо"',
    loadingHtml: "Розмітка для показу під час завантаження моделі опитування",
    commentText: "Текст області коментаря",
    autocomplete: "Тип автозаповнення",
    labelTrue: 'Мітка для "Правда"',
    labelFalse: 'Мітка для "Неправда"',
    allowClear: "Показати кнопку 'Очистити'",
    searchMode: "Режим пошуку",
    displayStyle: "Формат відображення",
    format: "Форматований рядок",
    maximumFractionDigits: "Максимальна кількість дробових цифр",
    minimumFractionDigits: "Мінімальна кількість дробових цифр",
    useGrouping: "Показувати роздільники груп",
    allowMultiple: "Дозволити кілька завантажень файлів",
    allowImagesPreview: "Попередній перегляд завантажених зображень",
    acceptedTypes: "Прийняті типи файлів",
    waitForUpload: "Чекати на завершення завантаження",
    needConfirmRemoveFile: "Підтвердити видалення файлу",
    detailPanelMode: "Вирівнювання розширення рядка",
    minRowCount: "Мінімальна кількість рядків",
    maxRowCount: "Максимальна кількість рядків",
    confirmDelete: "Підтвердити видалення рядка",
    confirmDeleteText: "Повідомлення підтвердження",
    panelCount: "Початкова кількість панелей",
    minPanelCount: "Мінімальна кількість панелей",
    maxPanelCount: "Максимальна кількість панелей",
    panelsState: "Стан згортання внутрішніх панелей",
    panelPrevText: "Текст кнопки 'Попередня панель'",
    panelNextText: "Текст кнопки 'Наступна панель'",
    panelRemoveButtonLocation: "Вирівнювання кнопки 'Видалити панель'",
    hideIfRowsEmpty: "Сховати питання, якщо немає рядків",
    hideColumnsIfEmpty: "Сховати стовпці, якщо немає рядків",
    rateValues: "Користувацькі значення рейтингу",
    rateCount: "Кількість рейтингів",
    autoGenerate: "Конфігурація рейтингу",
    hideIfChoicesEmpty: "Сховати питання, якщо немає варіантів",
    minWidth: "Мінімальна ширина",
    maxWidth: "Максимальна ширина",
    width: "Ширина",
    showHeader: "Показати заголовки стовпців",
    horizontalScroll: "Показати горизонтальну смугу прокрутки",
    columnMinWidth: "Мінімальна ширина стовпця",
    rowTitleWidth: "Ширина заголовка рядка",
    valueTrue: 'Значення для зберігання, коли вибрано "Правда"',
    valueFalse: 'Значення для зберігання, коли вибрано "Неправда"',
    minErrorText: '"Значення нижче мінімуму" повідомлення про помилку',
    maxErrorText: '"Значення перевищує максимум" повідомлення про помилку',
    otherErrorText: '"Порожній коментар" повідомлення про помилку',
    keyDuplicationError: "Повідомлення про помилку для дублікатів відповідей",
    minSelectedChoices: "Мінімальна кількість вибраних варіантів",
    maxSelectedChoices: "Максимальна кількість вибраних варіантів",
    showClearButton: "Показати кнопку 'Очистити'",
    logoWidth: "Ширина логотипа",
    logoHeight: "Висота логотипа",
    readOnly: "Тільки для читання",
    enableIf: "Вимкнути режим лише для читання, якщо",
    emptyRowsText: '"Немає рядків" повідомлення',
    separateSpecialChoices: "Окремі спеціальні варіанти",
    choicesFromQuestion: "Копіювати варіанти з наступного питання",
    choicesFromQuestionMode: "Які варіанти вибрати для копіювання",
    progressBarShowPageTitles: "Показати заголовки сторінок у прогрес-барі",
    progressBarShowPageNumbers: "Показати номери сторінок у прогрес-барі",
    showCommentArea: "Додати поле для коментаря",
    commentPlaceholder: "Текст-підказка для поля коментаря",
    displayRateDescriptionsAsExtremeItems:
      "Показати мітки як екстремальні значення",
    rowsOrder: "Порядок рядків",
    columnsLayout: "Макет стовпців",
    columnColCount: "Кількість вкладених стовпців",
    correctAnswer: "Правильна відповідь",
    defaultPanelValue: "Значення за замовчуванням",
    cells: "Тексти клітинок",
    fileInputPlaceholder: "Виберіть файл або вставте посилання на файл...",
    keyName: "Запобігти дублюванню відповідей у наступному стовпці",
    itemvalue: {
      visibleIf: "Зробити варіант видимим, якщо",
      enableIf: "Зробити варіант вибиральним, якщо",
    },
    "itemvalue@rows": {
      visibleIf: "Зробити рядок видимим, якщо",
      enableIf: "Зробити рядок редагованим, якщо",
    },
    imageitemvalue: {
      text: "Alt текст",
    },
    logoPosition: "Вирівнювання логотипа",
    addLogo: "Додати логотип...",
    changeLogo: "Змінити логотип...",
    logoPositions: {
      none: "Видалити логотип",
      left: "Зліва",
      right: "Праворуч",
      top: "Зверху",
      bottom: "Знизу",
    },
    // Creator tabs
    tabs: {
      panel: {
        layout: "Макет панелі",
      },
      general: "Загальні",
      fileOptions: "Параметри",
      html: "HTML Редактор",
      columns: "Стовпці",
      rows: "Рядки",
      choices: "Варіанти",
      items: "Елементи",
      visibleIf: "Видимий якщо",
      enableIf: "Редагований якщо",
      requiredIf: "Обов'язковий якщо",
      rateValues: "Оцінки",
      choicesByUrl: "Варіанти з веб-сервісу",
      matrixChoices: "Значення за замовчуванням",
      multipleTextItems: "Текстові поля",
      numbering: "Нумерація",
      validators: "Валідація",
      navigation: "Навігація",
      question: "Налаштування питання",
      pages: "Сторінки",
      timer: "Режим тестування",
      calculatedValues: "Обчислені значення",
      triggers: "Тригери",
      templateTitle: "Шаблон заголовка",
      totals: "Всього",
      logic: "Умови",
      mask: "Налаштування маски вводу",
      layout: {
        panel: "Макет панелі",
        question: "Макет",
        base: "Макет",
      },
      data: "Дані",
      validation: "Валідація",
      cells: "Тексти окремих клітинок",
      showOnCompleted: "Сторінка 'Дякуємо'",
      logo: "Логотип у заголовку опитування",
      slider: "Слайдер",
      expression: "Вираз",
      questionSettings: "Налаштування питання",
      header: "Заголовок",
      background: "Фон",
      appearance: "Зовнішній вигляд",
      others: "Інше",
    },
    editProperty: "Редагувати властивість '{0}'",
    items: "Елементи",
    choicesVisibleIf: "Зробити варіанти видимими якщо",
    choicesEnableIf: "Зробити варіанти доступними якщо",
    columnsEnableIf: "Зробити стовпці видимими якщо",
    rowsEnableIf: "Зробити рядки видимими якщо",
    innerIndent: "Збільшити внутрішній відступ",
    defaultValueFromLastRow:
      "Використовувати відповіді з останнього рядка як значення за замовчуванням",
    defaultValueFromLastPanel:
      "Використовувати відповіді з останньої панелі як значення за замовчуванням",
    enterNewValue: "Будь ласка, введіть значення.",
    noquestions: "У опитуванні немає питань.",
    createtrigger: "Будь ласка, створіть тригер",
    titleKeyboardAdornerTip: "Натисніть кнопку Enter для редагування",
    keyboardAdornerTip:
      "Натисніть кнопку Enter для редагування елемента, натисніть кнопку Delete для видалення елемента, натисніть Alt плюс стрілка вгору або стрілка вниз для переміщення елемента",
    triggerOn: "На ",
    triggerMakePagesVisible: "Зробити сторінки видимими",
    triggerMakeQuestionsVisible: "Зробити елементи видимими",
    triggerCompleteText: "Завершити опитування, якщо успішно.",
    triggerNotSet: "Тригер не встановлено",
    triggerRunIf: "Запустити якщо",
    triggerSetToName: "Змінити значення на: ",
    triggerFromName: "Копіювати значення з: ",
    triggerRunExpression: "Виконати цей вираз",
    triggerSetValue: "на: ",
    triggerGotoName: "Перейти до питання",
    triggerIsVariable: "Не включати змінну в результати опитування.",
    triggerRunExpressionEmpty: "Будь ласка, введіть дійсний вираз",
    emptyExpressionPlaceHolder: "Введіть вираз тут...",
    noFile: "Файл не вибрано",
    clearIfInvisible: "Очищати значення прихованих питань",
    valuePropertyName: "Зберігати значення у наступній властивості",
    searchEnabled: "Увімкнути пошук за введенням",
    hideSelectedItems: "Сховати вибрані елементи",
    closeOnSelect: "Сховати випадаючий список після вибору",
    verticalAlign: "Вертикальне вирівнювання в клітинках",
    alternateRows: "Чергування кольорів рядків",
    columnsVisibleIf: "Зробити стовпці видимими якщо",
    rowsVisibleIf: "Зробити рядки видимими якщо",
    otherPlaceholder: "Текст-підказка для поля коментаря",
    filePlaceholder: "Текст-підказка для локального файлу",
    photoPlaceholder: "Текст-підказка для камери",
    fileOrPhotoPlaceholder: "Текст-підказка для локального файлу або камери",
    rateType: "Іконка оцінки",
    url_placeholder: "Наприклад: https://api.example.com/books",
    path_placeholder: "Наприклад: categories.fiction",
    questionStartIndex_placeholder: "Наприклад: a)",
    width_placeholder: "Наприклад: 6in",
    minWidth_placeholder: "Наприклад: 600px",
    maxWidth_placeholder: "Наприклад: 50%",
    imageHeight_placeholder: "auto",
    imageWidth_placeholder: "auto",
    theme: {
      themeName: "Тема",
      isPanelless: "Зовнішній вигляд питання",
      editorPanel: "Фон та радіус закруглення кутів",
      questionPanel: "Фон та радіус закруглення кутів",
      primaryColor: "Колір акценту",
      panelBackgroundTransparency: "Прозорість панелі та боксу питання",
      questionBackgroundTransparency: "Прозорість елементів введення",
      fontSize: "Розмір шрифту опитування",
      scale: "Масштаб опитування",
      cornerRadius: "Радіус закруглення",
      pageTitle: "Шрифт заголовка сторінки",
      pageDescription: "Шрифт опису сторінки",
      questionTitle: "Шрифт заголовка питання",
      questionDescription: "Шрифт опису питання",
      editorFont: "Шрифт",
      backgroundOpacity: "Прозорість", // Автоматично згенерований рядок

      "--sjs-font-family": "Сімейство шрифтів опитування",
      "--sjs-general-backcolor-dim": "Колір фону",
      "--sjs-primary-backcolor": "Фонові кольори акценту",
      "--sjs-primary-forecolor": "Колір тексту акценту",
      "--sjs-special-red": "Кольори повідомлень про помилки",
      "--sjs-shadow-small": "Ефекти тіні",
      "--sjs-shadow-inner": "Ефекти тіні",
      "--sjs-border-default": "Кольори",
    },
    "header@header": {
      headerView: "Перегляд",
      logoPosition: "Вирівнювання логотипу",
      surveyTitle: "Шрифт заголовка опитування",
      surveyDescription: "Шрифт опису опитування",
      headerTitle: "Шрифт заголовка опитування",
      headerDescription: "Шрифт опису опитування",
      inheritWidthFrom: "Ширина області контенту",
      textAreaWidth: "Ширина тексту",
      backgroundColorSwitch: "Колір фону",
      backgroundImage: "Фонове зображення",
      backgroundImageOpacity: "Прозорість",
      overlapEnabled: "Накладення",
      logoPositionX: "Вирівнювання логотипу",
      titlePositionX: "Вирівнювання заголовка опитування",
      descriptionPositionX: "Вирівнювання опису опитування",
    },
  },
  // Property values
  pv: {
    true: "true",
    false: "false",
    file: "Локальний файл",
    camera: "Камера",
    "file-camera": "Локальний файл або Камера",
    inherit: "Успадковувати",
    show: "Видимий",
    hide: "Прихований",
    default: "Успадковувати",
    initial: "Початковий",
    random: "Випадковий",
    collapsed: "Згорнутий",
    expanded: "Розгорнутий",
    none: "Без змін",
    asc: "За зростанням",
    desc: "За спаданням",
    indeterminate: "Невизначений",
    selected: "Вибраний",
    unselected: "Не вибраний",
    decimal: "десятковий",
    currency: "валюта",
    percent: "відсоток",
    firstExpanded: "Перший панель розгорнута",
    off: "Сховати номери питань",
    list: "Список",
    carousel: "Карусель",
    tab: "Вкладки",
    progressTop: "Навігатор панелі + Індикатор прогресу зверху",
    progressBottom: "Навігатор панелі + Індикатор прогресу знизу",
    progressTopBottom: "Навігатор панелі + Індикатор прогресу зверху і знизу",
    horizontal: "Горизонтальний",
    vertical: "Вертикальний",
    top: "Зверху",
    bottom: "Знизу",
    topBottom: "Зверху та знизу",
    both: "Обидва",
    left: "Зліва",
    right: "Справа",
    center: "Центр",
    leftRight: "Зліва і справа",
    middle: "Середина",
    color: "колір",
    date: "дата",
    datetime: "дата і час",
    "datetime-local": "локальна дата і час",
    email: "електронна пошта",
    month: "місяць",
    number: "число",
    password: "пароль",
    range: "діапазон",
    tel: "телефон",
    text: "текст",
    time: "час",
    url: "URL",
    week: "тиждень",
    hidden: "Прихований",
    edit: "Редагований",
    display: "Тільки для читання",
    contain: "Умістити",
    cover: "Заповнити",
    fill: "Розтягнути",
    next: "Далі",
    last: "Останній",
    onComplete: "Після завершення опитування",
    onHidden: "Коли питання приховане",
    onHiddenContainer: "Коли питання або його панель/сторінка приховані",
    clearInvisibleValues: {
      none: "Ніколи",
    },
    clearIfInvisible: {
      none: "Ніколи",
    },
    inputType: {
      color: "Колір",
      date: "Дата",
      "datetime-local": "Дата і Час",
      email: "Електронна пошта",
      month: "Місяць",
      number: "Число",
      password: "Пароль",
      range: "Діапазон",
      tel: "Номер телефону",
      text: "Текст",
      time: "Час",
      url: "URL",
      week: "Тиждень",
    },
    maskType: {
      none: "Немає",
      pattern: "Шаблон",
      numeric: "Числовий",
      datetime: "Дата і Час",
      currency: "Валюта",
    },
    inputTextAlignment: {
      auto: "Авто",
      left: "Зліва",
      right: "Справа",
    },
    all: "Усі",
    page: "Сторінка",
    survey: "Опитування",
    onNextPage: "При переході на наступну сторінку",
    onValueChanged: "Після зміни відповіді",
    onValueChanging: "Перед зміною відповіді",
    questionsOnPageMode: {
      standard: "Оригінальна структура",
      singlePage: "Показати всі питання на одній сторінці",
      questionPerPage: "Показати одне питання на сторінку",
    },
    noPreview: "Без попереднього перегляду",
    showAllQuestions: "Показати всі питання",
    showAnsweredQuestions: "Показати лише відповіді на питання",
    pages: "Завершені сторінки",
    questions: "Завершені питання",
    requiredQuestions: "Завершені обов'язкові питання",
    correctQuestions: "Дійсні відповіді",
    buttons: "Завершені сторінки (інтерфейс кнопок)",
    underInput: "Під полем вводу",
    underTitle: "Під заголовком питання",
    onBlur: "При втраті фокусу",
    onTyping: "Під час введення",
    underRow: "Під рядком",
    underRowSingle: "Під рядком, показати лише одне розширення рядка",
    auto: "Авто",
    showNavigationButtons: {
      none: "Приховано",
    },
    showTimerPanel: {
      none: "Приховано",
    },
    showTimerPanelMode: {
      all: "Обидва",
    },
    addRowLocation: {
      default: "На основі макета матриці",
    },
    panelsState: {
      default: "Заблоковано",
      collapsed: "Згорнути все",
      expanded: "Розгорнути все",
      firstExpanded: "Перший розгорнутий",
    },
    widthMode: {
      static: "Статичний",
      responsive: "Адаптивний",
    },
    contentMode: {
      image: "Зображення",
      video: "Відео",
      youtube: "YouTube",
    },
    displayMode: {
      buttons: "Кнопки",
      dropdown: "Випадаючий список",
    },
    rateColorMode: {
      default: "За замовчуванням",
      scale: "Шкала",
    },
    scaleColorMode: {
      monochrome: "Монохромний",
      colored: "Кольоровий",
    },
    autoGenerate: {
      true: "Авто-генерація",
      false: "Ручний",
    },
    rateType: {
      labels: "Мітки",
      stars: "Зірки",
      smileys: "Смайли",
    },
    state: {
      default: "Заблоковано",
    },
    showQuestionNumbers: {
      default: "Автоматична нумерація",
      on: "Автоматична нумерація",
      onPage: "Скидання на кожній сторінці",
      onpanel: "Скидання на кожній панелі",
      onPanel: "Скидання на кожній панелі",
      onSurvey: "Продовжити в усьому опитуванні",
      off: "Без нумерації",
    },
    descriptionLocation: {
      underTitle: "Під заголовком питання",
      underInput: "Під полем вводу",
    },
    selectToRankAreasLayout: {
      horizontal: "Поруч із виборами",
      vertical: "Над виборами",
    },
    displayStyle: {
      decimal: "Десятковий",
      currency: "Валюта",
      percent: "Відсоток",
      date: "Дата",
    },
    totalDisplayStyle: {
      decimal: "Десятковий",
      currency: "Валюта",
      percent: "Відсоток",
      date: "Дата",
    },
    rowsOrder: {
      initial: "Оригінал",
    },
    questionsOrder: {
      initial: "Оригінал",
    },
    showProgressBar: {
      off: "Приховано",
      topbottom: "Зверху та знизу",
      aboveheader: "Над заголовком",
      belowheader: "Під заголовком",
    },
    progressBarLocation: {
      top: "Зверху",
      bottom: "Знизу",
      topBottom: "Зверху та знизу",
    },
    sum: "Сума",
    count: "Кількість",
    min: "Мінімум",
    max: "Максимум",
    avg: "Середнє",
    searchMode: {
      contains: "Містить",
      startsWith: "Починається з",
    },
    backgroundImageFit: {
      auto: "Авто",
      cover: "Заповнити",
      contain: "Вмістити",
      fill: "Розтягнути",
      tile: "Плитка",
    },
    backgroundImageAttachment: {
      fixed: "Фіксований",
      scroll: "Прокручування",
    },
    headerView: {
      basic: "Базовий",
      advanced: "Розширений",
    },
    inheritWidthFrom: {
      survey: "Як в опитуванні",
      container: "Як у контейнері",
    },
    backgroundColorSwitch: {
      none: "Немає",
      accentColor: "Колір акценту",
      custom: "Користувацький",
    },
    colorPalette: {
      light: "Світла",
      dark: "Темна",
    },
    isPanelless: {
      false: "За замовчуванням",
      true: "Без панелей",
    },
    progressBarInheritWidthFrom: {
      survey: "Як в опитуванні",
      container: "Як у контейнері",
    },
  },
  // Operators
  op: {
    empty: "Порожньо",
    notempty: "Не порожньо",
    equal: "Дорівнює",
    notequal: "Не дорівнює",
    contains: "Містить",
    notcontains: "Не містить",
    anyof: "Будь-яке з",
    allof: "Усі з",
    greater: "Більше ніж",
    less: "Менше ніж",
    greaterorequal: "Більше або дорівнює",
    lessorequal: "Менше або дорівнює",
    and: "і",
    or: "або",
  },
  // Embed window
  ew: {
    angular: "Використовувати версію Angular",
    jquery: "Використовувати версію jQuery",
    knockout: "Використовувати версію Knockout",
    react: "Використовувати версію React",
    vue: "Використовувати версію Vue",
    bootstrap: "Для фреймворку Bootstrap",
    modern: "Сучасна тема",
    default: "Тема за замовчуванням",
    orange: "Помаранчева тема",
    darkblue: "Темно-синя тема",
    darkrose: "Темно-рожева тема",
    stone: "Кам'яна тема",
    winter: "Зимова тема",
    winterstone: "Зима-Камінь тема",
    showOnPage: "Показати опитування на сторінці",
    showInWindow: "Показати опитування у вікні",
    loadFromServer: "Завантажити JSON опитування з сервера",
    titleScript: "Скрипти та стилі",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript",
  },
  // Попередній перегляд (Опитування)
  ts: {
    selectPage: "Виберіть сторінку для тестування",
    showInvisibleElements: "Показати невидимі елементи",
    hideInvisibleElements: "Приховати невидимі елементи",
  },
  validators: {
    answercountvalidator: "Кількість відповідей",
    emailvalidator: "Email",
    expressionvalidator: "Вираз",
    numericvalidator: "Число",
    regexvalidator: "Regex",
    textvalidator: "Текст",
  },
  triggers: {
    completetrigger: "Завершити опитування",
    setvaluetrigger: "Задати відповідь",
    copyvaluetrigger: "Копіювати відповідь",
    skiptrigger: "Пропустити до питання",
    runexpressiontrigger: "Запустити вираз",
    visibletrigger: "змінити видимість (застаріло)",
  },
  peplaceholder: {
    patternmask: {
      pattern: "Напр.: +1(999)-999-99-99",
    },
    datetimemask: {
      pattern: "Напр.: мм/дд/рррр ГГ:ХХ:СС",
    },
    currencymask: {
      prefix: "Напр.: $",
      suffix: "Напр.: USD",
    },
    panelbase: {
      questionTitleWidth: "Напр.: 200px",
    },
    panellayoutcolumn: {
      effectiveWidth: "Напр.: 30%",
      questionTitleWidth: "Напр.: 200px",
    },
  },
  pehelp: {
    panel: {
      name: "Ідентифікатор панелі, який не видно респондентам.",
      description: "Введіть підзаголовок панелі.",
      visibleIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову для відображення панелі.",
      enableIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка дозволить редагування панелі.",
      requiredIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка запобігає відправці опитування, якщо принаймні одне з питань панелі не має відповіді.",
      questionTitleLocation:
        'Застосовується до всіх питань у цій панелі. Якщо встановлено "Приховано", також приховуються описи питань. Для перевизначення цієї настройки, встановіть правила вирівнювання заголовків для окремих питань. Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування ("Зверху" за замовчуванням).',
      questionTitleWidth:
        "Встановлює постійну ширину заголовків питань, якщо вони вирівняні зліва від своїх блоків питань. Приймає значення CSS (px, %, in, pt тощо).",
      questionErrorLocation:
        'Встановлює розташування повідомлення про помилку відносно всіх питань у панелі. Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування.',
      questionsOrder:
        'Залишає початковий порядок питань або випадковим чином змінює його. Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування.',
      page: "Переміщує панель в кінець обраної сторінки.",
      innerIndent:
        "Додає відступ між вмістом панелі та лівою межею блоку панелі.",
      startWithNewLine:
        "Зніміть галочку, щоб відобразити панель в одному ряду з попереднім питанням або панеллю. Це налаштування не застосовується, якщо панель є першим елементом у вашій формі.",
      state:
        'Виберіть із варіантів: "Розгорнута" - панель відображається повністю і може бути згорнута; "Згорнута" - панель відображає лише заголовок та опис і може бути розгорнута; "Заблокована" - панель відображається повністю і не може бути згорнута.',
      width:
        "Встановлює ширину панелі у співвідношенні до інших елементів опитування в одному ряду. Приймає значення CSS (px, %, in, pt тощо).",
    },
    paneldynamic: {
      name: "Ідентифікатор панелі, який не видно респондентам.",
      description: "Введіть підзаголовок панелі.",
      visibleIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову для відображення панелі.",
      enableIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка дозволить редагування панелі.",
      requiredIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка запобігає відправці опитування, якщо принаймні одне з питань панелі не має відповіді.",
      templateTitleLocation:
        'Застосовується до всіх питань у цій панелі. Для перевизначення цієї настройки, встановіть правила вирівнювання заголовків для окремих питань. Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування ("Зверху" за замовчуванням).',
      templateErrorLocation:
        'Встановлює розташування повідомлення про помилку відносно питання з неправильним введенням. Виберіть один із варіантів: "Зверху" - текст помилки розміщується зверху блоку питання; "Знизу" - текст помилки розміщується знизу блоку питання. Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування ("Зверху" за замовчуванням).',
      errorLocation:
        'Встановлює розташування повідомлення про помилку відносно всіх питань у панелі. Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування.',
      page: "Переміщує панель в кінець обраної сторінки.",
      innerIndent:
        "Додає відступ між вмістом панелі та лівою межею блоку панелі.",
      startWithNewLine:
        "Зніміть галочку, щоб відобразити панель в одному ряду з попереднім питанням або панеллю. Це налаштування не застосовується, якщо панель є першим елементом у вашій формі.",
      state:
        'Виберіть із варіантів: "Розгорнута" - панель відображається повністю і може бути згорнута; "Згорнута" - панель відображає лише заголовок та опис і може бути розгорнута; "Заблокована" - панель відображається повністю і не може бути згорнута.',
      width:
        "Встановлює ширину панелі у співвідношенні до інших елементів опитування в одному ряду. Приймає значення CSS (px, %, in, pt тощо).",
      templateTitle:
        "Введіть шаблон для динамічних заголовків панелей. Використовуйте {panelIndex} для загальної позиції панелі та {visiblePanelIndex} для її порядку серед видимих панелей. Вставте ці змінні в шаблон для додавання автоматичної нумерації.",
      templateTabTitle:
        "Введіть шаблон для заголовків вкладок. Використовуйте {panelIndex} для загальної позиції панелі та {visiblePanelIndex} для її порядку серед видимих панелей. Вставте ці змінні в шаблон для додавання автоматичної нумерації.",
      tabTitlePlaceholder:
        "Текст для заголовків вкладок, який застосовується, коли шаблон заголовка вкладки не дає значущого значення.",
      templateVisibleIf:
        "Це налаштування дозволяє контролювати видимість окремих панелей у динамічній панелі. Використовуйте змінну `{panel}` для посилання на поточну панель у вашому виразі.",
      titleLocation:
        'Це налаштування автоматично успадковується всіма питаннями у цій панелі. Для перевизначення цієї настройки, встановіть правила вирівнювання заголовків для окремих питань. Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування ("Зверху" за замовчуванням).',
      descriptionLocation:
        'Варіант "Успадковувати" застосовує налаштування рівня сторінки (якщо встановлено) або рівня опитування ("Під заголовком панелі" за замовчуванням).',
      newPanelPosition:
        'Визначає позицію нової панелі. За замовчуванням нові панелі додаються в кінці. Виберіть "Наступна", щоб вставити нову панель після поточної.',
      defaultValueFromLastPanel:
        "Дублює відповіді з останньої панелі та присвоює їх наступній доданій динамічній панелі.",
      keyName:
        "Вкажіть назву питання, щоб вимагати від користувача надати унікальну відповідь на це питання в кожній панелі.",
    },
    defaultValueExpression:
      "Це налаштування дозволяє призначити значення відповіді за замовчуванням на основі виразу. Вираз може включати базові обчислення - `{q1_id} + {q2_id}`, логічні вирази, такі як `{age} > 60`, та функції: `iif()`, `today()`, `age()`, `min()`, `max()`, `avg()` тощо. Значення, визначене цим виразом, служить початковим значенням за замовчуванням, яке може бути перевизначене користувачем вручну.",
    resetValueIf:
      'Використовуйте іконку чарівної палички, щоб встановити умову, яка визначає, коли введення користувача скидається до значення, встановленого на основі "Виразу значення за замовчуванням", "Виразу встановленого значення" або "Значення відповіді за замовчуванням" (якщо встановлено).',
    setValueIf:
      'Використовуйте іконку чарівної палички, щоб встановити умову, яка визначає, коли слід запустити "Вираз встановленого значення" та динамічно призначити отримане значення як відповідь.',
    setValueExpression:
      'Вкажіть вираз, який визначає значення, яке слід встановити, коли виконуються умови правила "Встановити значення, якщо". Вираз може включати базові обчислення - `{q1_id} + {q2_id}`, логічні вирази, такі як `{age} > 60`, та функції: `iif()`, `today()`, `age()`, `min()`, `max()`, `avg()` тощо. Значення, визначене цим виразом, може бути перевизначене користувачем вручну.',
    question: {
      name: "Ідентифікатор питання, який не видно респондентам.",
      description: "Введіть підзаголовок питання.",
      visibleIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову видимості питання.",
      enableIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка дозволяє редагування питання.",
      requiredIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка запобігає просуванню або відправці опитування, якщо на питання не надано відповідь.",
      startWithNewLine:
        "Зніміть галочку, щоб відобразити питання в одному ряду з попереднім питанням або панеллю. Це налаштування не застосовується, якщо питання є першим елементом у вашій формі.",
      page: "Переміщує питання в кінець обраної сторінки.",
      state:
        'Виберіть із варіантів: "Розгорнуто" - блок питання відображається повністю і може бути згорнутий; "Згорнуто" - блок питання відображає лише заголовок та опис і може бути розгорнутий; "Заблоковано" - блок питання відображається повністю і не може бути згорнутий.',
      titleLocation:
        'Перевизначає правила вирівнювання заголовків, визначені на рівні панелі, сторінки або опитування. Якщо встановлено "Приховано", також приховуються описи питань. Варіант "Успадковувати" застосовує будь-які налаштування вищого рівня (якщо встановлено) або налаштування рівня опитування ("Зверху" за замовчуванням).',
      descriptionLocation:
        'Варіант "Успадковувати" застосовує налаштування рівня опитування ("Під заголовком питання" за замовчуванням).',
      errorLocation:
        'Встановлює розташування повідомлення про помилку відносно питання з неправильним введенням. Виберіть один із варіантів: "Зверху" - текст помилки розміщується зверху блоку питання; "Знизу" - текст помилки розміщується знизу блоку питання. Варіант "Успадковувати" застосовує налаштування рівня опитування ("Зверху" за замовчуванням).',
      indent: "Додає відступ між вмістом питання та лівою межею блоку питання.",
      width:
        "Встановлює ширину питання у співвідношенні до інших елементів опитування в одному ряду. Приймає значення CSS (px, %, in, pt тощо).",
      surveyvalidator: {
        expression:
          "Використовуйте іконку чарівної палички, щоб встановити правило валідації для питання.",
      },
      textUpdateMode:
        'Виберіть із варіантів: "Після втрати фокусу" - значення оновлюється, коли поле вводу втрачає фокус; "Під час введення" - значення оновлюється в реальному часі, під час введення. Варіант "Успадковувати" застосовує налаштування рівня опитування ("Після втрати фокусу" за замовчуванням).',
      url: "Ви можете використовувати будь-який веб-сервіс як джерело даних для питань з кількома варіантами відповіді. Щоб заповнити значення варіантів, введіть URL-адресу сервісу, що надає дані.",
      searchMode:
        "Операція порівняння, що використовується для фільтрації списку вибору.",
      textWrapEnabled:
        "Довгі тексти у варіантах вибору автоматично будуть розривати рядки, щоб вписатися в меню випадаючого списку. Зніміть галочку, якщо ви хочете, щоб тексти обрізалися.",
    },
    signaturepad: {
      signatureWidth:
        "Встановлює ширину відображуваної області підпису та результуючого зображення.",
      signatureHeight:
        "Встановлює висоту відображуваної області підпису та результуючого зображення.",
      signatureAutoScaleEnabled:
        "Виберіть, якщо ви хочете, щоб область підпису заповнила весь доступний простір у блоці питання, зберігаючи співвідношення сторін 3:2 за замовчуванням. Коли встановлені власні значення ширини та висоти, налаштування зберігає співвідношення сторін цих розмірів.",
    },
    file: {
      imageHeight: "Налаштовує висоту зображення в результатах опитування.",
      imageWidth: "Налаштовує ширину зображення в результатах опитування.",
      allowImagesPreview:
        "Відображає мініатюри для завантажених файлів, коли це можливо. Зніміть галочку, якщо ви хочете показувати іконки файлів замість цього.",
    },
    image: {
      contentMode:
        'Варіант "Авто" автоматично визначає підходящий режим відображення - Зображення, Відео або YouTube - на основі наданого URL-адресу.',
    },
    imagepicker: {
      imageHeight: "Перевизначає мінімальні та максимальні значення висоти.",
      imageWidth: "Перевизначає мінімальні та максимальні значення ширини.",
      choices:
        '"Значення" служить ідентифікатором елемента, який використовується в умовних правилах; "Текст" відображається респондентам.',
      contentMode:
        'Виберіть між "Зображення" та "Відео", щоб встановити режим вмісту для медіа-селектора. Якщо вибрано "Зображення", переконайтеся, що всі надані варіанти є зображеннями у форматах: JPEG, GIF, PNG, APNG, SVG, BMP, ICO. Аналогічно, якщо вибрано "Відео", переконайтеся, що всі варіанти є прямими посиланнями на відеофайли у форматах: MP4, MOV, WMV, FLV, AVI, MKV. Зверніть увагу, що посилання на YouTube не підтримуються для відео-варіантів.',
    },
    text: {
      size: "Це налаштування лише змінює розмір поля вводу і не впливає на ширину блоку питання. Щоб обмежити прийнятну довжину введення, перейдіть до <b>Валідація → Максимальна кількість символів</b>.",
    },
    comment: {
      rows: "Встановлює кількість відображуваних рядків у полі вводу. Якщо введення займає більше рядків, з'явиться смуга прокрутки.",
    },
    // survey templates
    survey: {
      mode: 'Виберіть із варіантів: "Редагований" - дозволяє респондентам заповнювати опитування; "Тільки для читання" - вимикає можливість редагування форми.',
    },
    matrixdropdowncolumn: {
      name: "Ідентифікатор стовпця, який не видно респондентам.",
      isUnique:
        "Коли увімкнено для стовпця, респондент повинен надати унікальну відповідь на кожне питання в межах цього стовпця.",
      rows: "Встановлює кількість відображуваних рядків у полі введення. Якщо введення займає більше рядків, з'явиться смуга прокрутки.",
      visibleIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову видимості стовпця.",
      enableIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка дозволяє редагування стовпця.",
      requiredIf:
        "Використовуйте іконку чарівної палички, щоб встановити умову, яка запобігає відправці опитування, якщо хоча б на одне вкладене питання не надано відповідь.",
      showInMultipleColumns:
        "Коли обрано, створюється окремий стовпець для кожного варіанту відповіді.",
    },
    widthMode:
      'Виберіть із варіантів: "Статичний" - встановлює фіксовану ширину; "Адаптивний" - робить опитування на всю ширину екрану; "Авто" - застосовує один з двох варіантів залежно від типів питань, що використовуються.',
    cookieName:
      "Призначте унікальне значення cookie для вашого опитування. Cookie буде встановлено в браузері респондента після завершення опитування, щоб запобігти повторному заповненню опитування.",
    logo: "Вставте посилання на зображення (без обмежень за розміром) або натисніть на іконку папки, щоб вибрати файл із комп'ютера (до 64 КБ).",
    logoWidth:
      "Встановлює ширину логотипу в одиницях CSS (px, %, in, pt тощо).",
    logoHeight:
      "Встановлює висоту логотипу в одиницях CSS (px, %, in, pt тощо).",
    logoFit:
      'Виберіть із варіантів: "Без змін" - зображення зберігає свій оригінальний розмір; "Умістити" - зображення змінюється, щоб вписатися, зберігаючи пропорції; "Заповнити" - зображення заповнює весь блок, зберігаючи пропорції; "Розтягнути" - зображення розтягується, щоб заповнити блок, не зберігаючи пропорцій.',
    goNextPageAutomatic:
      "Виберіть, якщо ви хочете, щоб опитування автоматично переходило на наступну сторінку після того, як респондент відповість на всі питання на поточній сторінці.",
    allowCompleteSurveyAutomatic:
      "Виберіть, якщо ви хочете, щоб опитування автоматично завершувалося після того, як респондент відповість на всі питання.",
    showNavigationButtons:
      "Встановлює видимість та розташування кнопок навігації на сторінці.",
    showProgressBar:
      'Встановлює видимість та розташування індикатора прогресу. Значення "Авто" відображає індикатор прогресу над або під заголовком опитування.',
    showPreviewBeforeComplete:
      "Увімкніть сторінку попереднього перегляду зі всіма або тільки з відповідями.",
    questionTitleLocation:
      "Застосовується до всіх питань у опитуванні. Це налаштування може бути перевизначено правилами вирівнювання заголовків на нижчих рівнях: панель, сторінка або питання. Налаштування нижчого рівня перевизначатимуть ті, що встановлені на вищому рівні.",
    requiredText:
      "Символ або послідовність символів, що вказує на обов'язковість відповіді.",
    questionStartIndex:
      "Введіть число або літеру, з якої ви хочете почати нумерацію.",
    questionErrorLocation:
      'Встановлює розташування повідомлення про помилку відносно питання з неправильним введенням. Виберіть один із варіантів: "Зверху" - текст помилки розміщується зверху блоку питання; "Знизу" - текст помилки розміщується знизу блоку питання.',
    focusFirstQuestionAutomatic:
      "Виберіть, якщо ви хочете, щоб перше поле вводу на кожній сторінці було готове для введення тексту.",
    questionsOrder:
      "Зберігає початковий порядок питань або рандомізує їх. Ефект цього налаштування видно лише у вкладці Попереднього перегляду.",
    maxTextLength: "Тільки для питань із введенням тексту.",
    maxOthersLength: "Тільки для коментарів до питань.",
    commentAreaRows:
      "Встановлює кількість відображуваних рядків у текстових полях для коментарів до питань. Якщо введення займає більше рядків, з'являється смуга прокрутки.",
    autoGrowComment:
      "Виберіть, якщо ви хочете, щоб коментарі до питань і питання з довгим текстом автоматично збільшувалися у висоту залежно від довжини введеного тексту.",
    allowResizeComment:
      "Тільки для коментарів до питань і питань з довгим текстом.",
    calculatedValues:
      "Користувацькі змінні служать проміжними або допоміжними змінними, що використовуються у розрахунках у формі. Вони приймають введення респондентів як вихідні значення. Кожна користувацька змінна має унікальне ім'я та вираз, на якому вона базується.",
    includeIntoResult:
      "Виберіть, якщо ви хочете, щоб розраховане значення виразу було збережено разом із результатами опитування.",
    triggers:
      'Тригер - це подія або умова, заснована на виразі. Як тільки вираз оцінюється як "істинний", тригер запускає дію. Така дія може мати цільове питання, на яке вона впливає.',
    clearInvisibleValues:
      "Виберіть, чи потрібно очищати значення для питань, прихованих умовною логікою, і коли це робити.",
    textUpdateMode:
      'Виберіть із варіантів: "Після втрати фокусу" - значення оновлюється, коли поле вводу втрачає фокус; "Під час введення" - значення оновлюється в реальному часі, під час введення.',
    columns:
      "Ліве значення служить ідентифікатором стовпця, який використовується в умовних правилах, праве значення відображається респондентам.",
    rows: "Ліве значення служить ідентифікатором рядка, який використовується в умовних правилах, праве значення відображається респондентам.",
    columnMinWidth: "Приймає значення CSS (px, %, in, pt тощо).",
    rowTitleWidth: "Приймає значення CSS (px, %, in, pt тощо).",
    totalText:
      'Видно лише якщо хоча б один стовпець відображає загальні значення, встановлені за допомогою "Методу агрегації" або "Виразу загального значення".',
    cellErrorLocation:
      'Встановлює розташування повідомлення про помилку відносно комірки з некоректним введенням. Опція "Успадкувати" застосовує налаштування з властивості "Вирівнювання повідомлення про помилку".',
    keyDuplicationError:
      'Коли увімкнено властивість "Запобігання дублюванню відповідей", респондент, який намагається відправити дубльований запис, отримає наступне повідомлення про помилку.',
    totalExpression:
      "Дозволяє обчислювати загальні значення на основі виразу. Вираз може включати базові обчислення (`{q1_id} + {q2_id}`), логічні вирази (`{age} > 60`) та функції (`iif()`, `today()`, `age()`, `min()`, `max()`, `avg()` тощо).",
    confirmDelete: "Запускає запит на підтвердження видалення рядка.",
    defaultValueFromLastRow:
      "Дублює відповіді з останнього рядка і присвоює їх наступному доданому динамічному рядку.",
    keyName:
      "Посилається на ідентифікатор стовпця, щоб вимагати від користувача надання унікальної відповіді на кожне питання в зазначеному стовпці.",
    description: "Введіть підзаголовок.",
    locale:
      "Виберіть мову для створення опитування. Щоб додати переклад, перемкніть на нову мову та перекладіть оригінальний текст тут або у вкладці Перекладів.",
    detailPanelMode:
      'Встановлює розташування розділу з деталями відносно рядка. Виберіть із варіантів: "Без" - розширення не додано; "Під рядком" - розширення рядка розташовано під кожним рядком матриці; "Під рядком, відображати лише одне розширення рядка" - розширення відображається лише під одним рядком, інші розширення рядків згорнуті.',
    imageFit:
      'Виберіть із варіантів: "Без змін" - зображення зберігає свій оригінальний розмір; "Умістити" - зображення змінюється, щоб вписатися, зберігаючи пропорції; "Накрити" - зображення заповнює весь блок, зберігаючи пропорції; "Заповнити" - зображення розтягується, щоб заповнити блок, не зберігаючи пропорцій.',
    autoGrow:
      'Опція "Успадкувати" застосовує налаштування на рівні опитування (за замовчуванням "Вимкнено").',
    allowResize:
      'Опція "Успадкувати" застосовує налаштування на рівні опитування (за замовчуванням "Увімкнено").',
    maxTimeToFinish:
      'Інтервал часу в секундах, після якого опитування автоматично переходить на сторінку "Дякуємо".',
    maxTimeToFinishPage:
      'Інтервал часу в секундах, після якого опитування автоматично переходить на наступну сторінку. Приховує кнопку навігації "Попередня".',
    validateVisitedEmptyFields:
      "Увімкніть цю опцію, щоб запустити валідацію, коли користувач фокусується на порожньому полі введення, а потім залишає його без внесення змін.",
    page: {
      maxTimeToFinish:
        'Інтервал часу в секундах, після якого опитування автоматично переходить на наступну сторінку. Приховує кнопку навігації "Попередня".',
      visibleIf:
        "Використовуйте іконку чарівної палички, щоб встановити умовне правило, яке визначає видимість сторінки.",
      enableIf:
        "Використовуйте іконку чарівної палички, щоб встановити умовне правило, яке дозволяє редагування сторінки.",
      requiredIf:
        "Використовуйте іконку чарівної палички, щоб встановити умовне правило, яке запобігає відправці опитування, якщо хоча б на одне вкладене питання не надано відповідь.",
      questionTitleLocation:
        'Застосовується до всіх питань на цій сторінці. Якщо встановлено значення "Приховано", також приховуються описи питань. Якщо ви хочете перевизначити це налаштування, задайте правила вирівнювання заголовків для окремих питань або панелей. Опція "Успадкувати" застосовує налаштування на рівні опитування (за замовчуванням "Зверху").',
      questionTitleWidth:
        "Встановлює постійну ширину заголовків питань, коли вони вирівняні ліворуч від їхніх блоків. Приймає значення CSS (px, %, in, pt тощо).",
      questionErrorLocation:
        'Встановлює розташування повідомлення про помилку відносно питання з некоректним введенням. Виберіть один із варіантів: "Зверху" - текст помилки розміщується зверху блоку питання; "Знизу" - текст помилки розміщується знизу блоку питання. Опція "Успадкувати" застосовує налаштування на рівні опитування (за замовчуванням "Зверху").',
      questionsOrder:
        'Зберігає початковий порядок питань або рандомізує їх. Опція "Успадкувати" застосовує налаштування на рівні опитування (за замовчуванням "Оригінал"). Ефект цього налаштування видно лише у вкладці Попереднього перегляду.',
      navigationButtonsVisibility:
        'Встановлює видимість кнопок навігації на сторінці. Опція "Успадкувати" застосовує налаштування на рівні опитування, яке за замовчуванням є "Видимим".',
    },
    showTimerPanel: "Встановлює видимість і розташування таймера на сторінці.",
    panelsState:
      'Виберіть із варіантів: "Заблоковано" - користувачі не можуть розгортати або згортати панелі; "Згорнути всі" - всі панелі починають у згорнутому стані; "Розгорнути всі" - всі панелі починають у розгорнутому стані; "Перший розгорнутий" - спочатку розгорнуто лише першу панель. Застосовується, якщо "Режим відображення панелей" встановлено на "Список" і властивість "Шаблон заголовка панелі" задана.',
    imageLinkName:
      "Введіть загальну назву властивості в масиві об'єктів, що містить URL-адреси зображень або відеофайлів, які ви хочете відобразити в списку вибору.",
    choices:
      "Ліве значення служить як ідентифікатор елемента, що використовується в умовних правилах, праве значення відображається респондентам.",
    title: "Введіть зручний для користувача заголовок для відображення.",
    waitForUpload:
      "Забезпечує, що користувачі не завершать опитування, доки файли не будуть завантажені.",
    minWidth: "Приймає значення CSS (px, %, in, pt тощо).",
    maxWidth: "Приймає значення CSS (px, %, in, pt тощо).",
    width: "Приймає значення CSS (px, %, in, pt тощо).",
    valueName:
      "Ідентифікатор з'єднання є користувацьким ключем, який ви можете призначити кільком питанням, щоб об'єднати їх і синхронізувати їхні значення. Ці значення будуть об'єднані в єдиний масив або об'єкт і збережені в результатах опитування з використанням ключа як назви властивості.",
    useDisplayValuesInDynamicTexts:
      "У типах питань з одиничним і множинним вибором кожен варіант відповіді має ідентифікатор та відображуване значення. Коли обрано, ця опція показує відображуване значення замість ідентифікатора у питаннях HTML і динамічних заголовках та описах елементів опитування.",
    clearIfInvisible:
      'Виберіть, чи потрібно очищати значення питань, прихованих умовною логікою, і коли це робити. Опція "Успадкувати" застосовує налаштування на рівні опитування ("Після завершення опитування" за замовчуванням).',
    choicesFromQuestionMode:
      'Виберіть із варіантів: "Усі" - копіює всі варіанти вибору з вибраного питання; "Обрані" - динамічно копіює лише обрані варіанти вибору; "Необрані" - динамічно копіює лише необрані варіанти вибору. Опції "Жоден" та "Інше" копіюються за замовчуванням, якщо вони увімкнені в джерельному питанні.',
    showOtherItem:
      "При виборі користувачі можуть включити додатковий ввід у окремому коментарі.",
    separateSpecialChoices:
      'Відображає кожен спеціальний варіант вибору ("Жоден", "Інше", "Вибрати всі") на новому рядку, навіть коли використовується макет з кількома стовпцями.',
    path: "Вкажіть місце розташування в сервісному наборі даних, де знаходиться цільовий масив об'єктів. Залиште порожнім, якщо URL вже вказує на масив.",
    choicesbyurl: {
      valueName:
        "Введіть єдину назву властивості в масиві об'єктів, значення якої буде збережене як відповідь у результатах опитування.",
    },
    titleName:
      "Введіть єдину назву властивості в масиві об'єктів, що містить значення, які ви хочете відобразити в списку вибору.",
    allowEmptyResponse:
      "Виберіть, щоб дозволити сервісу повернути порожню відповідь або масив.",
    choicesVisibleIf:
      "Використовуйте іконку чарівної палички, щоб встановити умовне правило, яке визначає видимість усіх варіантів вибору.",
    rateValues:
      "Ліве значення служить як ідентифікатор елемента, що використовується в умовних правилах, праве значення відображається респондентам.",
    rating: {
      displayMode:
        '"Авто" вибирає між режимами "Кнопки" та "Випадний список" залежно від доступної ширини. Коли ширини недостатньо для відображення кнопок, питання відображає випадаючий список.',
    },
    valuePropertyName:
      "Дозволяє з'єднувати питання, що дають результати в різних форматах. Коли такі питання зв'язуються за допомогою ідентифікатора з'єднання, ця спільна властивість зберігає значення вибраних питань.",
    searchEnabled:
      "Виберіть, якщо ви хочете оновити вміст випадаючого меню, щоб відповідати запиту пошуку, який користувач вводить у поле вводу.",
    valueTrue:
      "Значення, яке буде збережене в результатах опитування, коли респонденти дадуть позитивну відповідь.",
    valueFalse:
      "Значення, яке буде збережене в результатах опитування, коли респонденти дадуть негативну відповідь.",
    showPreview:
      "Не рекомендується відключати цю опцію, оскільки вона перевизначає зображення попереднього перегляду і ускладнює користувачеві розуміння, чи були файли завантажені.",
    needConfirmRemoveFile: "Запускає запит на підтвердження видалення файлу.",
    selectToRankEnabled:
      "Увімкніть, щоб ранжувати лише вибрані варіанти. Користувачі перетягуватимуть вибрані елементи з списку вибору, щоб впорядкувати їх у зоні ранжування.",
    dataList:
      "Введіть список варіантів, які будуть запропоновані респонденту під час вводу.",
    itemSize:
      "Це налаштування лише змінює розмір полів вводу і не впливає на ширину блоку питання.",
    itemTitleWidth:
      "Встановлює постійну ширину всіх ярликів елементів у пікселях.",
    inputTextAlignment:
      'Виберіть, як вирівнювати значення вводу в полі. Налаштування за замовчуванням "Авто" вирівнює значення вводу праворуч, якщо застосована маска валюти або чисел, і ліворуч, якщо ні.',
    altText:
      "Служить заміною, коли зображення не може бути відображене на пристрої користувача та для цілей доступності.",
    expression: {
      name: "Ідентифікатор виразу, який не видно респондентам.",
      description: "Введіть підзаголовок виразу.",
      expression:
        "Вираз може містити базові обчислення (`{q1_id} + {q2_id}`), умови (`{age} > 60`) та функції ('iif()`, `today()`, `age()`, `min()`, `max()`, `avg()`, тощо).",
    },
    storeOthersAsComment:
      'Виберіть, щоб зберігати значення опції "Інше" як окрему властивість у результатах опитування.',
    format: "Використовуйте {0} як заповнювач для фактичного значення.",
    acceptedTypes:
      "Зверніться до опису атрибута [accept](https://www.w3schools.com/tags/att_input_accept.asp) для отримання додаткової інформації.",
    columnColCount:
      'Застосовується тільки якщо "Тип вводу в клітинці" встановлено на Група кнопок або Прапорці.',
    autocomplete:
      "Виберіть тип даних, які браузер користувача може отримати. Ці дані надходять або з попередніх значень, введених користувачем, або з попередньо налаштованих значень, якщо такі були збережені користувачем для автозаповнення.",
    filePlaceholder:
      'Застосовується, коли "Тип джерела файлів" є "Місцевий файл" або коли камера недоступна.',
    photoPlaceholder: 'Застосовується, коли "Тип джерела файлів" є "Камера".',
    fileOrPhotoPlaceholder:
      'Застосовується, коли "Тип джерела файлів" є "Місцевий файл або камера".',
    colCount:
      "Розташовує варіанти вибору у макеті з кількома стовпцями. Коли встановлено 0, варіанти відображаються в одному рядку.",
    masksettings: {
      saveMaskedValue:
        "Виберіть, якщо ви хочете зберегти значення питання з застосованою маскою в результатах опитування.",
    },
    patternmask: {
      pattern:
        "Шаблон може містити рядкові літерали і такі заповнювачі: `9` - для цифри; `a` - для літери великої чи маленької регістра; `#` - для цифри або літери великої чи маленької регістра. Використовуйте зворотний слеш `\\` для екранування символу.",
    },
    datetimemask: {
      pattern:
        "Шаблон може містити роздільники і такі заповнювачі:<br>`m` - номер місяця.<br>`mm` - номер місяця, з ведучим нулем для однозначних значень.<br>`d` - день місяця.<br>`dd` - день місяця, з ведучим нулем для однозначних значень.<br>`yy` - Останні дві цифри року.<br>`yyyy` - Чотирицифровий рік.<br>`H` - Години у 24-годинному форматі.<br>`HH` - Години у 24-годинному форматі, з ведучим нулем для однозначних значень.<br>`h` - Години у 12-годинному форматі.<br>`hh` - Години у 12-годинному форматі, з ведучим нулем для однозначних значень.<br>`MM` - Хвилини.<br>`ss` - Секунди.<br>`TT` - Період 12-годинного годинника у верхньому регістрі (AM/PM).<br>`tt` - Період 12-годинного годинника у нижньому регістрі (am/pm).",
    },
    numericmask: {
      decimalSeparator:
        "A symbol used to separate the fractional part from the integer part of a displayed number.",
      thousandsSeparator:
        "A symbol used to separate the digits of a large number into groups of three.",
      precision:
        "Limits how many digits to retain after the decimal point for a displayed number.",
    },
    currencymask: {
      prefix: "One or several symbols to be displayed before the value.",
      suffix: "One or several symbols to be displayed after the value.",
    },
    theme: {
      isPanelless: "This setting applies only to questions outside of a panel.",
      primaryColor:
        "Sets a supplementary color that highlights key survey elements.",
      panelBackgroundTransparency:
        "Adjusts the transparency of panels and question boxes relative to the survey background.",
      questionBackgroundTransparency:
        "Adjusts the transparency of input elements relative to the survey background.",
      cornerRadius:
        "Sets the corner radius for all rectangular elements. Enable the Advanced Mode if you want to set individual corner radius values for input elements or panels and question boxes.",
      "--sjs-general-backcolor-dim":
        "Sets the main background color of the survey.",
    },
    header: {
      inheritWidthFrom:
        'The "Same as container" option auto-adjusts the header content area width to fit into the HTML element the survey is placed in.',
      textAreaWidth:
        "The width of the header area that contains the survey title and description, measured in pixels.",
      overlapEnabled:
        "Makes the bottom of the header overlaid with the top of the survey.",
    },
    panellayoutcolumn: {
      effectiveWidth: "Accepts values in percentage (%).",
      questionTitleWidth: "Accepts values in pixels (px).",
    },
    progressBarInheritWidthFrom:
      'The "Same as container" option auto-adjusts the progress bar area width to fit into the HTML element the survey is placed in.',
  },
  // Properties
  p: {
    title: {
      name: "Назва",
      title: "Залиште порожнім, якщо збігається з 'Назва'.",
    },
    multiSelect: "Дозволити множинний вибір",
    showLabel: "Показувати підписи до зображень та відео",
    swapOrder: "Поміняти місцями порядок 'Так' і 'Ні'",
    value: "Значення",
    tabAlign: "Вирівнювання вкладки",
    sourceType: "Тип джерела файлу",
    fitToContainer: "Вписати в контейнер",
    setValueExpression: "Встановити вираз значення",
    description: "Опис",
    logoFit: "Вписати логотип",
    pages: "Сторінки",
    questions: "Питання",
    triggers: "Тригери",
    calculatedValues: "Користувацькі змінні",
    surveyId: "ID опитування",
    surveyPostId: "ID поста опитування",
    surveyShowDataSaving: "Показувати збереження даних опитування",
    questionDescriptionLocation: "Вирівнювання опису питання",
    progressBarType: "Тип індикатора прогресу",
    showTOC: "Показати зміст (TOC)",
    tocLocation: "Вирівнювання TOC",
    questionTitlePattern: "Шаблон заголовка питання",
    widthMode: "Режим ширини опитування",
    showBrandInfo: "Показати інформацію про бренд",
    useDisplayValuesInDynamicTexts:
      "Використовувати значення для відображення в динамічних текстах",
    visibleIf: "Видимий, якщо",
    defaultValueExpression: "Вираз значення за замовчуванням",
    requiredIf: "Обов’язковий, якщо",
    resetValueIf: "Скинути значення, якщо",
    setValueIf: "Встановити значення, якщо",
    validators: "Правила валідації",
    bindings: "Прив'язки",
    renderAs: "Відображати як",
    attachOriginalItems: "Прикріпити оригінальні елементи",
    choices: "Варіанти",
    choicesByUrl: "Варіанти за URL",
    currency: "Валюта",
    cellHint: "Підказка для клітинки",
    totalMaximumFractionDigits: "Максимальна кількість знаків після коми",
    totalMinimumFractionDigits: "Мінімальна кількість знаків після коми",
    columns: "Колонки",
    detailElements: "Детальні елементи",
    allowAdaptiveActions: "Дозволити адаптивні дії",
    defaultRowValue: "Значення рядка за замовчуванням",
    detailPanelShowOnAdding: "Показати панель деталей при додаванні",
    choicesLazyLoadEnabled: "Увімкнено ледаче завантаження варіантів",
    choicesLazyLoadPageSize: "Розмір сторінки ледачого завантаження варіантів",
    inputFieldComponent: "Компонент поля вводу",
    itemComponent: "Компонент елемента",
    min: "Мінімум",
    max: "Максимум",
    minValueExpression: "Вираз мінімального значення",
    maxValueExpression: "Вираз максимального значення",
    step: "Крок",
    dataList: "Елементи для автопідказки",
    itemSize: "Ширина поля вводу (в символах)",
    itemTitleWidth: "Ширина підпису елемента (в px)",
    inputTextAlignment: "Вирівнювання значення вводу",
    elements: "Елементи",
    content: "Вміст",
    navigationButtonsVisibility: "Показати/приховати кнопки навігації",
    navigationTitle: "Заголовок навігації",
    navigationDescription: "Опис навігації",
    longTap: "Довге натискання",
    autoGrow: "Динамічне розширення поля вводу",
    allowResize: "Увімкнути маркер зміни розміру",
    acceptCarriageReturn: "Приймати перенесення рядка",
    displayMode: "Режим відображення",
    rateType: "Тип оцінювання",
    label: "Мітка",
    contentMode: "Режим вмісту",
    imageFit: "Вписати зображення та мініатюру",
    altText: "Альтернативний текст",
    height: "Висота",
    penColor: "Колір ручки",
    backgroundColor: "Колір фону",
    templateElements: "Елементи шаблону",
    operator: "Оператор",
    isVariable: "Є змінною",
    runExpression: "Виконати вираз",
    showCaption: "Показати підпис",
    iconName: "Назва іконки",
    iconSize: "Розмір іконки",
    precision: "Точність",
    matrixDragHandleArea: "Область перетягування матриці",
    backgroundImage: "Фонове зображення",
    backgroundImageFit: "Вписати фонове зображення",
    backgroundImageAttachment: "Прикріплення фонового зображення",
    backgroundOpacity: "Прозорість фону",
    selectToRankEnabled: "Дозволити вибіркове ранжування",
    selectToRankAreasLayout: "Вирівнювання зони ранжування",
    selectToRankEmptyRankedAreaText:
      "Текст для відображення, якщо всі варіанти обрані",
    selectToRankEmptyUnrankedAreaText:
      "Текст-заповнювач для області ранжування",
    allowCameraAccess: "Дозволити доступ до камери",
    scaleColorMode: "Режим кольору рейтингової іконки",
    rateColorMode: "Колірна схема смайликів",
    copyDisplayValue: "Копіювати значення для відображення",
    effectiveColSpan: "Кількість об’єднаних колонок",
    progressBarInheritWidthFrom: "Ширина області індикатора прогресу",
  },
  theme: {
    advancedMode: "Розширений режим",
    pageTitle: "Сторінка",
    questionTitle: "Поле питання",
    editorPanel: "Елемент введення",
    lines: "Лінії",

    primaryDefaultColor: "Основний",
    primaryDarkColor: "При наведенні",
    primaryLightColor: "Вибраний",
    backgroundDimColor: "Колір фону",

    cornerRadius: "Радіус кута",
    backcolor: "Основний фон",
    hovercolor: "Фон при наведенні",
    borderDecoration: "Оздоблення межі",

    fontColor: "Колір шрифту",
    backgroundColor: "Колір фону",

    primaryForecolor: "Основний колір",
    primaryForecolorLight: "Колір для вимкненого",
    font: "Шрифт",
    borderDefault: "Темніший",
    borderLight: "Світліший",

    fontFamily: "Сімейство шрифтів",
    fontWeightRegular: "Звичайний",
    fontWeightHeavy: "Тяжкий",
    fontWeightSemiBold: "Напівжирний",
    fontWeightBold: "Жирний",
    color: "Колір",
    placeholderColor: "Колір підказки",
    size: "Розмір",

    opacity: "Прозорість",
    boxShadowX: "X",
    boxShadowY: "Y",
    boxShadowAddRule: "Додати ефект тіні",
    boxShadowBlur: "Розмиття",
    boxShadowSpread: "Розподіл",
    boxShadowDrop: "Спадання",
    boxShadowInner: "Внутрішня",

    names: {
      default: "За замовчуванням",
      sharp: "Різкий",
      borderless: "Без меж",
      flat: "Плоский",
      plain: "Простий",
      doubleborder: "Подвійна межа",
      layered: "Шаруватий",
      solid: "Суцільний",
      threedimensional: "3D",
      contrast: "Контрастний",
    },
    colors: {
      teal: "Бірюзовий",
      blue: "Синій",
      purple: "Фіолетовий",
      orchid: "Орхідея",
      tulip: "Тюльпан",
      brown: "Коричневий",
      green: "Зелений",
    },
  },
};

(editorLocalization.locales as any)["customLocales"] = customLocaleStrings;
