import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: 8px;
    border-top: 1px solid ${theme.palette.background.light};
  `}
`;

export const PaginationItem = styled.button`
  ${({ theme }) => css`
    margin-top: 1px;
    border: none;
    display: inline-block;
    background: none;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 8px 0px;
    color: ${theme.palette.text.default};
    box-shadow: 0 -1px 0px transparent;
    cursor: pointer;
    display: none;

    &.active {
      pointer-events: none;
      color: ${theme.palette.border.secondary};
      box-shadow: 0 -2px 0px ${theme.palette.border.default};
    }

    &.visible {
      display: inline-block;
    }

    &.empty {
      cursor: unset;
      pointer-events: none;
    }

    @media (min-width: 768px) {
      padding: 16px 16px 0px;
    }
  `}
`;
