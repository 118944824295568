import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { lighten } from "polished";

export const ChecklistModalOuterWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const ChecklistModalWrapper = styled.div`
  ${({
    theme: {
      palette: { border, background, error, info, success, text },
    },
  }) => css`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 24px 16px;
    border-radius: 8px;
    background-color: ${background.secondary};
    max-width: 1240px;

    --font-family: "Roboto";
    --sjs-font-family: "Roboto";
    --sjs-font-surveytitle-color: ${border.default};
    --sjs-primary-backcolor: ${border.default};
    --sjs-secondary-backcolor: ${border.default};
    --primary: ${border.default};
    --secondary: ${border.default};
    --sjs-primary-backcolor-light: ${lighten(0.36, border.default)};
    --secondary-light: ${lighten(0.18, border.default)};
    --sjs-secondary-backcolor-semi-light: ${lighten(0.28, border.default)};
    --ctr-survey-page-background-color-hovered: ${lighten(
      0.28,
      border.default
    )};
    --ctr-button-contextual-button-background-color-focused: ${lighten(
      0.28,
      border.default
    )};
    --ctr-survey-header-logo-placeholder-background-color-hovered: ${lighten(
      0.28,
      border.default
    )};
    --sjs-questionpanel-hovercolor: ${lighten(0.28, border.default)};
    --sjs-font-size: 12px;

    @media (min-width: 768px) {
      --sjs-font-size: 14px;
    }

    .svc-tab-designer--with-page-navigator .svc-tab-designer_content {
      margin: 0 24px 0 8px;
    }

    .svc-page-navigator__selector .svc-page-navigator__navigator-icon {
      height: 100% !important;
      width: 100% !important;
    }

    .svc-btn,
    .svc-text {
      font-size: 12px;
      line-height: 18px;

      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .svc-btn {
      padding: 9px 13px;
      height: auto;
    }

    .sd-rating__item-star--selected.sd-rating__item-star--readonly svg {
      fill: ${border.default};
    }

    .svc-page__content {
      --sjs-secondary-backcolor-semi-light: ${lighten(0.24, border.default)};
    }

    .sd-boolean__thumb,
    .sd-boolean__label {
      padding: 4px 13px;
    }

    .svc-creator--mobile .svc-question__content {
      padding-left: 12px;
      padding-right: 12px;
    }

    .sd-element__num {
      padding-inline-end: 0;
      margin-inline-start: 0;
      width: auto;

      & + span {
        width: 4px;
      }
    }

    .sd-btn--action {
      &:hover {
        background-color: ${border.secondary};
      }
    }

    .svc-question__content {
      padding-left: 12px;
      padding-right: 12px;
    }

    .svc-creator--mobile .svc-tab-designer .sd-container-modern {
      min-width: auto;
    }

    .svc-side-bar__container {
      min-width: auto;
    }
    .sd-body__navigation .sd-btn {
      padding: 9px 13px;
    }

    .sd-body.sd-body--responsive .sd-body__navigation {
      padding-top: 24px;
    }

    .sd-root-modern--mobile .sd-body.sd-body--responsive,
    .sd-root-modern--mobile .sd-body.sd-body--static {
      padding: 12px 0;
    }

    .spg-search-editor_container {
      width: 100%;
    }

    .sd-input,
    .spg-input {
      min-height: 32px;
      height: 100%;
      background-color: ${background.default};
      border: 1px solid ${background.disable};
      border-radius: 6px;
      padding: 6px 9px;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
      color: ${text.primary};

      @media (min-width: 768px) {
        min-height: 40px;
        padding: 9px 13px;
        line-height: 20px;
        font-size: 14px;
      }

      &[type="datetime-local"] {
        min-height: auto;
      }
    }

    .sd-dropdown {
      padding-right: 48px;
    }

    .sv-button-group__item {
      padding: 6px 9px;
      line-height: 18px;

      @media (min-width: 768px) {
        line-height: 20px;
        padding: 9px 13px;
      }
    }

    .sv-action-bar-item__title::after {
      filter: invert(64%) sepia(98%) saturate(3655%) hue-rotate(208deg)
        brightness(95%) contrast(101%);
    }

    .sd-element__num {
      padding-inline-end: 2px;
      margin-inline-start: 0px;
      width: max-content;
    }

    .sd-element--with-frame > .sd-element__erbox {
      margin: 0;
      width: 100%;
      background-color: transparent;
    }

    .sd-title.sd-element__title {
      max-width: 95%;
      margin-left: 0px;
    }

    .sd-header__text .sd-title {
      font-size: 20px;
      line-height: 120%;
    }

    .sd-page .sd-page__title {
      font-size: 18px;
      line-height: 120%;
    }

    .sv-list__item-body.sd-list__item-body:hover {
      background-color: ${background.disable};
    }

    .sd-dropdown__value {
      line-height: inherit;
      min-height: auto;
      font-size: 12px;
    }

    .sd-btn,
    .svc-btn {
      color: ${border.secondary};
      background-color: ${background.default};
      border: 1px solid ${border.secondary};

      &:hover {
        background-color: ${text.disable};
      }
    }

    .sd-body__navigation .sd-btn {
      padding: 9px 17px;
    }

    @media (min-width: 768px) {
      .sd-dropdown__value {
        font-size: 14px;
      }

      .sd-page .sd-page__title {
        font-size: 20px;
      }

      .sd-title.sd-container-modern__title {
        padding: 12px 0 24px 12px;
      }

      .sd-header__text .sd-title {
        font-size: 24px;
      }

      .sd-body.sd-body--static {
        padding: 16px 0;
      }
    }

    .svc-question__content .sd-question__content {
      padding: 0;
      margin: 0;
    }

    .svc-logo-image-placeholder {
      margin: 0;
    }
  `}
`;

export const CloseChecklistWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const ChecklistModalHeading = styled.h3`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.text.primary};
    margin-bottom: 12px;
  `}
`;
