import * as SurveyReact from "survey-creator-react";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-creator-core/survey-creator-core.i18n";
import "survey-core/survey.i18n";
import { ChecklistEntity } from "../../../interfaces/checklists";
import {
  useAddChecklistMutation,
  useGetChecklistModelByIdQuery,
  useUpdateChecklistMutation,
} from "../../../api/checklists.api";
import {
  useRef,
  useEffect,
  MutableRefObject,
  useMemo,
  useState,
  useCallback,
} from "react";
import {
  handleOnEditChecklist,
  handleOnSaveChecklist,
} from "./checklist-creator-component.helper";
import { skipToken } from "@reduxjs/toolkit/query";
import Loaders from "../../loaders/loaders";
import ChecklistModalContainer from "../checklist-modal-container/checklist-modal-container";
import "../ua-checklist-translation";
import { KypsButton } from "../../kyps-button/kyps-button";
import { SaveChecklistMobileButton } from "./checklist-creator-component.styles";
import { isEmpty } from "lodash";
import { t } from "i18next";

type ChecklistCreatorComponentProps = {
  entityName: string;
  targetId: string;
  entity: ChecklistEntity;
  closeModal: () => void;
} & (EditChecklistType | CreateChecklistType);

type EditChecklistType = {
  isEditMode: true;
  modelId: string;
  checklistName: string;
};

type CreateChecklistType = {
  isEditMode?: false;
};

interface IChecklistData {
  model: string;
  title: string;
}

const ChecklistCreatorComponent = (props: ChecklistCreatorComponentProps) => {
  const { entityName, entity, closeModal, targetId } = props;
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [addChecklist] = useAddChecklistMutation();
  const [editChecklist] = useUpdateChecklistMutation();
  const { data: apiChecklistModelData, isLoading } =
    useGetChecklistModelByIdQuery(
      props.isEditMode && entity ? props.modelId : skipToken
    );

  const [editData, setEditData] = useState<IChecklistData | null>(null);

  const creator = useMemo(
    () =>
      new SurveyReact.SurveyCreator({
        showLogicTab: true,
        showJSONEditorTab: false,
      }),
    []
  );
  const data = useMemo(() => {
    if (apiChecklistModelData) return apiChecklistModelData.model;

    if (props.isEditMode && !isEmpty(editData?.model)) return editData?.model;

    return null;
  }, [apiChecklistModelData, editData?.model, props.isEditMode]);

  creator.onValueChangingCallback = () => creator.setModified();

  useEffect(() => {
    creator.locale = "customLocales";
    creator.survey.locale = "ua";

    if (props.isEditMode && data) {
      creator.text = data;
      creator.showSaveButton = true;
      return;
    }

    if (!props.isEditMode)
      creator.survey.title = `${entityName} ${t("checklists.newChecklist")}`;
  }, [creator, data, entityName, props.isEditMode]);

  if (props.isEditMode && data) {
    const { modelId } = props;

    creator.saveSurveyFunc = () => {
      if (modelId) {
        closeModal();
        handleOnEditChecklist({
          requestData: {
            modelId,
            body: editData ?? {
              model: creator.text,
              title: creator.survey.title,
            },
          },
          editChecklist,
        });
      }
    };
  }

  const onSaveChecklist = useCallback(() => {
    closeModal();

    if (props.isEditMode) {
      closeModal();

      return handleOnEditChecklist({
        requestData: {
          modelId: props.modelId,
          body: editData ?? {
            model: creator.text,
            title: creator.survey.title,
          },
        },
        editChecklist,
      });
    }

    handleOnSaveChecklist({
      addChecklist,
      requestData: {
        entity,
        targetId,
        body: {
          model: creator.text,
          title: creator.survey.title,
        },
      },
    });
  }, [
    addChecklist,
    closeModal,
    creator,
    editData,
    editChecklist,
    entity,
    props,
    targetId,
  ]);

  creator.saveSurveyFunc = onSaveChecklist;
  creator.onModified.add(() => {
    if (props.isEditMode)
      setEditData({
        model: JSON.stringify(creator.JSON as JSON),
        title: creator.survey.title,
      });
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node))
        props.closeModal();
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, props]);

  const content = useMemo(() => {
    if (isLoading && props.isEditMode) return <Loaders variant="table" />;

    return <SurveyReact.SurveyCreatorComponent creator={creator} />;
  }, [creator, isLoading, props.isEditMode]);

  const isMobileButtonDisabled = useMemo(() => {
    if (creator.state === "modified") return false;

    if (!props.isEditMode) return false;

    return true;
  }, [creator.state, props.isEditMode]);

  return (
    <ChecklistModalContainer
      componentRef={ref}
      closeModal={props.closeModal}
      children={
        <>
          <SaveChecklistMobileButton>
            <KypsButton
              placeholder={t(
                props.isEditMode
                  ? "buttons.edit.actionWithKey"
                  : "buttons.add.actionWithKey",
                {
                  key: t("tables.checklist"),
                }
              )}
              onClick={creator.saveSurveyFunc}
              isDisabled={isMobileButtonDisabled}
            />
          </SaveChecklistMobileButton>
          {content}
        </>
      }
      heading={
        props.isEditMode
          ? t("checklists.actions.edit", { name: props.checklistName })
          : t("checklists.actions.create")
      }
    />
  );
};

export default ChecklistCreatorComponent;
