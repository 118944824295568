import styled from "@emotion/styled";

export const LocationChecklistsTableHeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  button {
    margin: 0 0 0 auto;
  }
`;
