import { IChecklistsModelResponseDTO } from "../../interfaces/checklists";
import { ICompany } from "../../interfaces/companies";
import {
  IEditableSensorTableData,
  ILocationDetailsTableData,
} from "../../interfaces/editable-cells";
import { IManagerDTO } from "../../interfaces/managers";
import { ISensorHistoryPayload } from "../../interfaces/sensors";
import {
  IAttachTableData,
  ISensorsStatsData,
  ITreeTableData,
} from "../../interfaces/tables";

type TableNames =
  | "company"
  | "locations"
  | "location"
  | "sensorStats"
  | "managers"
  | "treeTable"
  | "attachUser"
  | "locationDetails"
  | "sensorDetails"
  | "sensorHistory"
  | "checklists";

type ColumnNamesMap = {
  company: ICompany;
  locations: ILocationsTableWidths;
  location: ILocationTableWidths;
  sensorStats: ISensorsStatsData;
  managers: IManagerDTO;
  treeTable: ITreeTableData;
  attachUser: IAttachTableData;
  locationDetails: ILocationDetailsTableData;
  sensorDetails: IEditableSensorTableData;
  sensorHistory: ISensorHistoryPayload;
  checklists: IChecklistsModelResponseDTO;
};

type GetColumnWidth = <T extends TableNames>(
  tableName: T,
  columnName: keyof ColumnNamesMap[T] | "actions"
) => number | undefined;

export const getColumnWidth: GetColumnWidth = (tableName, columnName) => {
  if (tableName === "company" && columnName in companyTableWidths)
    return companyTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<ICompany>
    ];

  if (tableName === "locations" && columnName in locationsTableWidths)
    return locationsTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<ILocationsTableWidths>
    ];

  if (tableName === "location" && columnName in locationTableWidths)
    return locationTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<ILocationTableWidths>
    ];

  if (tableName === "sensorStats" && columnName in sensorsStatsTableWidths)
    return sensorsStatsTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<ISensorsStatsData>
    ];

  if (tableName === "managers" && columnName in managersTableWidths)
    return managersTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<IManagerDTO>
    ];

  if (tableName === "treeTable" && columnName in managersTableWidths)
    return treeTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<ITreeTableData>
    ];

  if (tableName === "attachUser" && columnName in attachUserTableWidths)
    return attachUserTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<IAttachTableData>
    ];

  if (
    tableName === "locationDetails" &&
    columnName in locationDetailsTableWidths
  )
    return locationDetailsTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<ILocationDetailsTableData>
    ];

  if (tableName === "sensorDetails" && columnName in sensorDetailsTableWidths)
    return sensorDetailsTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<IEditableSensorTableData>
    ];

  if (tableName === "sensorHistory" && columnName in sensorHistoryTableWidths)
    return sensorHistoryTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<ISensorHistoryPayload>
    ];

  if (tableName === "checklists" && columnName in сhecklistsTableWidths)
    return сhecklistsTableWidths[
      columnName as keyof ExtendedTableTypeWithAction<IChecklistsModelResponseDTO>
    ];
};

type ExtendedTableTypeWithAction<T> = {
  [K in keyof T]: number | undefined;
} & { actions: number | undefined };

const isMobile = window.innerWidth < 768;

const сhecklistsTableWidths: ExtendedTableTypeWithAction<IChecklistsModelResponseDTO> =
  {
    title: isMobile ? 200 : 240,
    modelCreationDate: isMobile ? 140 : 160,
    updatedAt: isMobile ? 140 : 160,
    actions: isMobile ? 124 : 116,
    versionCreationDate: undefined,
    model: undefined,
    modelId: undefined,
    modelVersion: undefined,
  };

const companyTableWidths: ExtendedTableTypeWithAction<ICompany> = {
  comment: isMobile ? 180 : 240,
  createdAt: isMobile ? 146 : 200,
  fullName: isMobile ? 200 : 340,
  shortName: isMobile ? 160 : 240,
  actions: 96,
  id: undefined,
  checklistsAvailable: undefined,
};

const locationsTableWidths: ExtendedTableTypeWithAction<ILocationsTableWidths> =
  {
    locationId: 0,
    locationName: isMobile ? 180 : 200,
    locationAddress: isMobile ? 180 : 200,
    managers: isMobile ? 106 : 112,
    online: undefined,
    offline: isMobile ? 86 : 100,
    total: isMobile ? 86 : 104,
    inRange: undefined,
    attention: 126,
    outRange: isMobile ? 106 : 116,
    lastUpdate: isMobile ? 146 : 156,
    actions: 96,
  };

interface ILocationsTableWidths {
  locationId: string;
  locationName: string;
  locationAddress: string;
  managers: number;
  online: number;
  offline: number;
  total: number;
  inRange: number;
  attention: number;
  outRange: number;
  lastUpdate: string;
}

interface ILocationTableWidths {
  name: number;
  openChart: number;
  safeZone: number;
  temperature: number;
  battery: number;
  signal: number;
  actions: number;
  lastUpdate: number;
}

const locationTableWidths: ILocationTableWidths = {
  name: isMobile ? 192 : 300,
  openChart: isMobile ? 54 : 78,
  safeZone: isMobile ? 112 : 168,
  temperature: isMobile ? 102 : 122,
  battery: isMobile ? 76 : 116,
  signal: isMobile ? 86 : 124,
  actions: isMobile ? 44 : 68,
  lastUpdate: isMobile ? 144 : 162,
};

const sensorsStatsTableWidths: ExtendedTableTypeWithAction<ISensorsStatsData> =
  {
    name: isMobile ? 182 : 212,
    lastTemperature: 81,
    lastHumidity: 60,
    battery: 81,
    signal: 81,
    range: isMobile ? 96 : 102,
    lastUpdateDate: 160,
    actions: undefined,
    id: undefined,
  };

const managersTableWidths: ExtendedTableTypeWithAction<IManagerDTO> = {
  name: isMobile ? 160 : 214,
  phoneNumber: isMobile ? 148 : 172,
  telegramId: isMobile ? 106 : 144,
  email: isMobile ? 200 : 240,
  createdAt: isMobile ? 146 : 152,
  updatedAt: isMobile ? 146 : 152,
  actions: 96,
  companies: undefined,
  id: undefined,
  status: undefined,
};

const treeTableWidths: ExtendedTableTypeWithAction<ITreeTableData> = {
  name: isMobile ? 380 : 400,
  actions: isMobile ? 52 : 40,
  id: undefined,
};

const attachUserTableWidths: ExtendedTableTypeWithAction<IAttachTableData> = {
  user: isMobile ? 156 : 180,
  email: isMobile ? 156 : 180,
  role: isMobile ? 152 : 170,
  emailNotification: isMobile ? 60 : 72,
  telegramNotification: isMobile ? 60 : 72,
  actions: isMobile ? 96 : 118,
  locationId: undefined,
  userId: undefined,
};

const locationDetailsTableWidths: ExtendedTableTypeWithAction<ILocationDetailsTableData> =
  {
    value: isMobile ? 240 : 440,
    id: isMobile ? 86 : 126,
    name: isMobile ? 100 : 200,
    actions: undefined,
  };

const sensorDetailsTableWidths: ExtendedTableTypeWithAction<IEditableSensorTableData> =
  {
    value: isMobile ? 200 : 440,
    id: isMobile ? 86 : 126,
    name: isMobile ? 140 : 200,
    actions: undefined,
  };

const sensorHistoryTableWidths: ExtendedTableTypeWithAction<ISensorHistoryPayload> =
  {
    actions: undefined,
    currentDbm: 96,
    gatewayNumber: 112,
    sensorHum: 112,
    sensorNumber: undefined,
    sensorTemp: 112,
    sensorTime: 160,
    voltage: 86,
  };
