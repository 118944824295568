import { FunctionComponent, RefObject, SVGProps } from "react";
import { ReactComponent as PencilSvg } from "../../assets/Pencil.svg";
import { ReactComponent as BinSvg } from "../../assets/Bin.svg";
import { ReactComponent as CaretSvg } from "../../assets/Caret.svg";
import { ReactComponent as DotsSvg } from "../../assets/Dots-vertical.svg";
import { ReactComponent as ToLineSvg } from "../../assets/to-line.svg";
import { ReactComponent as SignalSvg } from "../../assets/Signal.svg";
import { ReactComponent as BatterySvg } from "../../assets/Battery.svg";
import { ReactComponent as WarningSvg } from "../../assets/Warning.svg";
import { ReactComponent as DetachSvg } from "../../assets/Detach.svg";
import { ReactComponent as ChevronSvg } from "../../assets/Chevron.svg";
import { ReactComponent as ChevronRightSvg } from "../../assets/Chevron-right.svg";
import { ReactComponent as SwitchHorizontalSvg } from "../../assets/Switch-horizontal.svg";
import { ReactComponent as PlusSvg } from "../../assets/plus-dark.svg";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import { ReactComponent as ListSvg } from "../../assets/list.svg";
import { ReactComponent as GridSvg } from "../../assets/grid.svg";
import { ReactComponent as ChartSvg } from "../../assets/chart.svg";
import { ReactComponent as TimeSvg } from "../../assets/time.svg";
import { ReactComponent as ClipboardSvg } from "../../assets/Clipboard.svg";
import { ReactComponent as ChecklistSvg } from "../../assets/checklist.svg";
import { ReactComponent as HistorySvg } from "../../assets/history.svg";
import { ReactComponent as ResultSvg } from "../../assets/result.svg";
import { MouseEvent } from "react";
import { SvgWrapper } from "./icon-button.styles";
import { IconButtonNames } from "../../utils/icon-button-names";
import clsx from "clsx";

interface IIconButton {
  name: IconButtonNames;
  hoverColor?: "info" | "warning" | "darken" | "none";
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  isExpanded?: boolean;
  confirmWindow?: JSX.Element;
  className?: string;
  asIcon?: boolean;
  isLarge?: boolean;
  color?: "light" | "error" | "";
  dataName?: string;
  hasZeroMargin?: boolean;
  ref?: RefObject<HTMLDivElement>;
}

const icons: Record<
  IIconButton["name"],
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  bin: BinSvg,
  pencil: PencilSvg,
  caret: CaretSvg,
  vDots: DotsSvg,
  toLine: ToLineSvg,
  battery: BatterySvg,
  signal: SignalSvg,
  warning: WarningSvg,
  detach: DetachSvg,
  chevron: ChevronSvg,
  chevronRight: ChevronRightSvg,
  switchHorizontal: SwitchHorizontalSvg,
  plus: PlusSvg,
  close: CloseSvg,
  list: ListSvg,
  grid: GridSvg,
  chart: ChartSvg,
  time: TimeSvg,
  clipboard: ClipboardSvg,
  checklist: ChecklistSvg,
  history: HistorySvg,
  result: ResultSvg,
};

const IconButton = ({
  name,
  hoverColor,
  onClick,
  isExpanded,
  confirmWindow,
  className,
  asIcon,
  isLarge,
  color,
  dataName,
  hasZeroMargin,
  ref,
}: IIconButton): JSX.Element => {
  const SelectedSvg = icons[name];

  return (
    <SvgWrapper
      ref={ref}
      onClick={onClick}
      className={clsx(
        className,
        color,
        isExpanded && "expanded",
        asIcon && "as-icon",
        isLarge && "large",
        hasZeroMargin && "zero-margin"
      )}
      data-name={dataName}
      title={dataName}
    >
      <SelectedSvg className={hoverColor} />
      {confirmWindow}
    </SvgWrapper>
  );
};

export default IconButton;
