import { t } from "i18next";
import { AddChecklistFunction } from "../../../interfaces/add-functions";
import {
  IAddChecklistRequest,
  IUpdateChecklistRequest,
} from "../../../interfaces/checklists";
import { UpdateChecklistFunction } from "../../../interfaces/update-functions";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnSaveChecklist {
  requestData: IAddChecklistRequest;
  addChecklist: AddChecklistFunction;
}

interface IHandleOnEditChecklist {
  requestData: IUpdateChecklistRequest;
  editChecklist: UpdateChecklistFunction;
}

export const handleOnSaveChecklist = ({
  addChecklist,
  requestData,
}: IHandleOnSaveChecklist) =>
  addChecklist(requestData)
    .unwrap()
    .then(() =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.created"),
          entity: t("entities.checklist"),
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const handleOnEditChecklist = ({
  editChecklist,
  requestData,
}: IHandleOnEditChecklist) =>
  editChecklist(requestData)
    .unwrap()
    .then(() =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.updated"),
          entity: t("entities.checklist"),
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
