import React, { Fragment, ReactNode } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import InputBlock, { InputField } from "./kyps-form-input";
import { FormHeader, FormWrapper } from "./kyps-form.styles";
import { Subheading } from "../../styles/helper.styles";
import { useTranslation } from "react-i18next";
import { fillFormDefaultValues } from "./kyps-form.helper";

interface IKypsForm {
  fields: InputField<any>[];
  onSubmit: SubmitHandler<any>;
  heading?: string;
  description?: string | ReactNode;
  uncontrolledFields?: JSX.Element | null;
  uncontrolledFieldsPosition?: "top" | "bottom";
}

const KypsForm = React.forwardRef<HTMLFormElement, IKypsForm>(
  (
    {
      fields,
      onSubmit,
      heading,
      description,
      uncontrolledFields,
      uncontrolledFieldsPosition = "top",
    },
    ref
  ) => {
    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({ defaultValues: fillFormDefaultValues(fields) });

    const { t } = useTranslation();

    return (
      <FormWrapper>
        <FormHeader>
          <Subheading>{heading ?? t("tabs.generalInformation")}</Subheading>
          <div>{description ?? "Small description here"}</div>
        </FormHeader>
        {uncontrolledFieldsPosition === "top" && uncontrolledFields}
        <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
          {fields.map(
            ({
              label,
              name,
              rules,
              type,
              value,
              iconName,
              placeholder,
              isHidden,
            }) => {
              if (isHidden) return <Fragment key={String(name)}></Fragment>;

              return (
                <InputBlock
                  key={String(name)}
                  label={label}
                  name={String(name)}
                  control={control}
                  rules={rules}
                  errors={errors}
                  type={type}
                  value={value}
                  iconName={iconName}
                  placeholder={placeholder}
                />
              );
            }
          )}
        </form>
        {uncontrolledFieldsPosition === "bottom" && uncontrolledFields}
      </FormWrapper>
    );
  }
);

export default KypsForm;
