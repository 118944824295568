import { t } from "i18next";
import { AddCompletionFunction } from "../../../interfaces/add-functions";
import { IAddCompletionBodyRequest } from "../../../interfaces/checklists";
import { createNotification } from "../../toaster/toaster.helper";
import { Model } from "survey-core";

interface IHandleOnAddCompletion {
  locationId: string;
  modelId: string;
  requestBody: IAddCompletionBodyRequest;
  addCompletion: AddCompletionFunction;
}

interface IChecklistResult {
  correctAnswers: number;
  incorrectAnswers: number;
}

export const handleOnAddCompletion = ({
  locationId,
  modelId,
  requestBody,
  addCompletion,
}: IHandleOnAddCompletion) =>
  addCompletion({
    locationId,
    modelId,
    body: requestBody,
  })
    .unwrap()
    .then(() =>
      createNotification({
        message: t("checklists.completions.saveCompletionMessage"),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const getChecklistResult = (checklist: Model): IChecklistResult =>
  checklist.getAllQuestions().reduce(
    (acc: IChecklistResult, question) => {
      const hasCorrectAnswer = !!question.correctAnswer;
      const isAnswerCorrect = question?.isAnswerCorrect();

      if (question.isRequired) {
        if ((isAnswerCorrect && hasCorrectAnswer) || !hasCorrectAnswer)
          acc.correctAnswers++;

        if (hasCorrectAnswer && !isAnswerCorrect) acc.incorrectAnswers++;
      }

      return acc;
    },
    { correctAnswers: 0, incorrectAnswers: 0 }
  );
