import PageHeader from "../../components/page-header/page-header";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { UserRoles } from "../../interfaces/roles";
import { getUserRoles } from "../../auth/get-user-roles.helper";
import CompanyChecklistsTable from "../../components/kyps-tables/company-checklists-table/company-checklists-table";
import PageContentContainer, {
  IPageContent,
} from "../../components/page-content/page-content-container";
import LocationChecklistsTable from "../../components/kyps-tables/location-checklists-table/location-checklists-table";
import { useTranslation } from "react-i18next";

enum ChecklistsTabsEnum {
  COMPANY = "Company Checklists",
  LOCATION = "Location Checklists",
}

const Checklists = () => {
  const { t, i18n } = useTranslation();
  const { selectedCompany } = useAppSelector((state) => state.company);
  const [searchParams, setSearchParams] = useSearchParams();
  const { changeRole } = useAppDispatch();
  const checklistsHeaderRef = useRef<HTMLDivElement>(null);

  const companyId = useMemo(() => searchParams.get("company"), [searchParams]);

  useEffect(() => {
    if (!companyId && selectedCompany)
      setSearchParams(`company=${selectedCompany.value}`);
  }, [companyId, selectedCompany, setSearchParams]);

  useEffect(() => {
    const handleRole = (role: UserRoles) => changeRole(role);

    if (selectedCompany)
      getUserRoles({
        url: selectedCompany.value,
        handleOnChangeRole: handleRole,
      });
  }, [changeRole, selectedCompany]);

  const [isActiveTab, setIsActiveTab] = useState(ChecklistsTabsEnum.COMPANY);
  const handleTabClick = (tabName: ChecklistsTabsEnum) =>
    setIsActiveTab(tabName);

  const pageContent: IPageContent<ChecklistsTabsEnum>[] = useMemo(
    () => [
      {
        tabName: t("tabs.companyChecklists"),
        tabId: ChecklistsTabsEnum.COMPANY,
        content: (
          <CompanyChecklistsTable
            companyId={companyId as string}
            companyName={selectedCompany?.label ?? ""}
          />
        ),
      },
      {
        tabName: t("tabs.locationChecklists"),
        tabId: ChecklistsTabsEnum.LOCATION,
        content: (
          <LocationChecklistsTable
            tableHeaderRef={checklistsHeaderRef}
            companyId={companyId}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, selectedCompany?.label, i18n.language]
  );

  return (
    <>
      <PageHeader
        pageName={t("pages.checklists.name")}
        headerRef={checklistsHeaderRef}
      />
      <PageContentContainer
        activeTab={isActiveTab}
        pageContent={pageContent}
        tabHandler={handleTabClick}
      />
    </>
  );
};

export default Checklists;
