import { ReactNode, useEffect, useMemo } from "react";
import { ModalOverlay } from "./kyps-modal.styles";
import clsx from "clsx";
import { createPortal } from "react-dom";

interface IKypsModal {
  isModalOpen: boolean;
  modalContent?: ReactNode;
  className?: string;
}

const KypsModal = ({
  isModalOpen,
  modalContent,
  className,
}: IKypsModal): JSX.Element => {
  useEffect(() => {
    if (isModalOpen) document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "";
    };
  }, [isModalOpen]);

  const modal = useMemo(
    () => (
      <ModalOverlay className={clsx(isModalOpen && "visible", className)}>
        {modalContent}
      </ModalOverlay>
    ),
    [className, isModalOpen, modalContent]
  );

  return <>{isModalOpen && createPortal(modal, document.body)}</>;
};

export default KypsModal;
