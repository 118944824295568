import { format, isToday, isYesterday } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { TFunction } from "i18next";

const formatDateWithT = (
  date: Date,
  t: TFunction<"translations", undefined>
) => {
  if (isToday(date)) return format(date, `'${t("time.todayAt")}' HH:mm`);

  if (isYesterday(date))
    return format(date, `'${t("time.yesterdayAt")}' HH:mm`);

  return format(date, `dd.MM.yyyy '${t("time.at")}' HH:mm`);
};

const formatDate = (date: Date) => {
  if (isToday(date)) return format(date, "'Today at' HH:mm");

  if (isYesterday(date)) return format(date, "'Yesterday at' HH:mm");

  return format(date, "dd.MM 'at' HH:mm");
};

export const checkDate = (
  date: string,
  t?: TFunction<"translations", undefined>
) => {
  if (!date) return date;

  const localTime = utcToZonedTime(new Date(date), "Europe/Kiev");

  if (t) return formatDateWithT(localTime, t);

  return formatDate(localTime);
};

export const toLocalTime = (date: string) => {
  const localTime = utcToZonedTime(new Date(date), "Europe/Kiev");

  return format(localTime, "dd.MM.yyyy HH:mm:ss");
};

export const getFormattedDate = () => {
  const currentDate = new Date();

  const year = currentDate.getUTCFullYear();
  const month = getTwoDigitString(currentDate.getUTCMonth() + 1);
  const day = getTwoDigitString(currentDate.getUTCDate());
  const hours = getTwoDigitString(currentDate.getUTCHours());
  const minutes = getTwoDigitString(currentDate.getUTCMinutes());
  const seconds = getTwoDigitString(currentDate.getUTCSeconds());
  const milliseconds = currentDate
    .getUTCMilliseconds()
    .toString()
    .padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

const getTwoDigitString = (value: number) => value.toString().padStart(2, "0");
