export interface IPalette {
  default: string;
  primary: string;
  secondary: string;
  dark?: string;
  light?: string;
  disable?: string;
}

export interface BaseTheme {
  palette: {
    text: IPalette;
    background: IPalette;
    border: IPalette;
    info: IPalette;
    error: IPalette;
    success: IPalette;
  };
  zIndex: {
    bottomNav: number;
    burger: number;
    datepicker: number;
    deleteConfirm: number;
    dropdownMenu: number;
    flag: number;
    header: number;
    modal: number;
    mobileMenu: number;
    pinnedTable: number;
    secondSidebar: number;
    select: number;
  };
}

export const theme: BaseTheme = {
  palette: {
    background: {
      default: "#fff",
      primary: "#f9fafb",
      secondary: "#f7f7f8",
      light: "#e5e7eb",
      dark: "#9ca3af",
      disable: "#d1d5db",
    },
    text: {
      default: "#6b7280",
      primary: "#111827",
      secondary: "#4b5563",
      light: "#b9c1cb",
      dark: "#374151",
      disable: "#f3f4f6",
    },
    success: {
      default: "#10b981",
      primary: "#059669",
      secondary: "#6ee7b7",
      light: "#d1fae5",
      dark: "#065f46",
    },
    border: {
      default: "#6366f1",
      primary: "#1d4ed8",
      secondary: "#4f46e5",
      light: "#7bd2f9",
      dark: "#1f2937",
      disable: "#2563EB",
    },
    error: {
      default: "#dc2626",
      primary: "#ef4444",
      secondary: "#b91c1c",
      light: "#fee2e2",
      dark: "#991b1b",
      disable: "#fca5a5",
    },
    info: {
      default: "#f59e0b",
      primary: "#fcd34d",
      secondary: "#d97706",
      light: "#fef3c7",
      dark: "#92400e",
    },
  },
  zIndex: {
    burger: 11000,
    flag: 11000,
    modal: 1200,
    header: 1150,
    bottomNav: 100,
    select: 100,
    deleteConfirm: 90,
    dropdownMenu: 80,
    datepicker: 10,
    mobileMenu: 10,
    secondSidebar: 10,
    pinnedTable: 1,
  },
};
