import { ILocationWithChecklistModelsProjection } from "../../../interfaces/checklists";
import { ILocationChecklistsTableData } from "./location-checklists-table.utils";

export const transformToLocationChecklistsTableData = (
  apiLocationsChecklists: ILocationWithChecklistModelsProjection[]
): ILocationChecklistsTableData[] =>
  apiLocationsChecklists.map(({ checklistModels, name, id }) => ({
    title: name,
    modelId: id,
    modelVersion: 0,
    modelCreationDate: "",
    updatedAt: "",
    versionCreationDate: "",
    subRows: checklistModels,
  }));
