import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { kypsApi } from "../api/kyps.api";
import companyReducer from "../redux/company/reducers";
import userReducer from "../redux/user/reducers";
import userLocationsReducer from "../redux/userLocations/reducers";

export const store = configureStore({
  reducer: {
    [kypsApi.reducerPath]: kypsApi.reducer,
    company: companyReducer,
    user: userReducer,
    userLocations: userLocationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(kypsApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
