import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { createChecklistCompletionsTableColumns } from "./checklist-completions-table.utils";
import { hasEmptyMessage } from "../location-table/location-table.helper";
import { t } from "i18next";
import { useGetChecklistCompletionsQuery } from "../../../api/checklists-completion.api";
import { useMemo, useState } from "react";
import Loaders from "../../loaders/loaders";
import useModal from "../../../hooks/useModal";
import KypsModal from "../../kyps-modal/kyps-modal";
import { ISortCompletionsByKeys } from "../../../interfaces/checklists";
import { IPaginationQueryObj } from "../../../interfaces/tables";

interface IChecklistCompletionsTable {
  modelId: string;
  isCompanyChecklist?: boolean;
}

const baseSortObj: {
  property: keyof ISortCompletionsByKeys;
  direction: "ASC" | "DESC";
} = { property: "respondent", direction: "ASC" };

const ChecklistCompletionsTable = ({
  modelId,
  isCompanyChecklist,
}: IChecklistCompletionsTable) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: baseSortObj.property, desc: baseSortObj.direction === "DESC" },
  ]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryObj: IPaginationQueryObj<ISortCompletionsByKeys> = useMemo(
    () => ({
      page: pageIndex,
      size: pageSize,
      sort: [
        sorting.length
          ? {
              direction: sorting[0].desc ? "DESC" : "ASC",
              property: sorting[0].id as keyof ISortCompletionsByKeys,
            }
          : baseSortObj,
      ],
    }),
    [pageIndex, pageSize, sorting]
  );

  const {
    closeAndResetModal,
    currentModal,
    isModalOpen,
    openModal: openSecondModal,
  } = useModal();

  const { data: apiChecklistCompletions, isLoading } =
    useGetChecklistCompletionsQuery({
      modelId,
      ...queryObj,
    });

  const checklistCompletionsData = useMemo(
    () => apiChecklistCompletions?.content ?? [],
    [apiChecklistCompletions]
  );

  const table = useReactTable({
    data: checklistCompletionsData,
    columns: createChecklistCompletionsTableColumns({
      closeModal: closeAndResetModal,
      openModal: openSecondModal,
      isCompanyChecklist,
    }),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: { pageIndex, pageSize },
      sorting,
      columnPinning: { left: ["respondent"] },
    },
    sortDescFirst: false,
    manualPagination: true,
    pageCount: apiChecklistCompletions?.totalPages,
    autoResetPageIndex: false,
    enableSortingRemoval: false,
  });

  if (isLoading) return <Loaders variant="table" />;

  return (
    <>
      <KypsTable
        table={table}
        tableName={t("checklists.completions.tableName")}
        serverSideSorting
        emptyMessage={hasEmptyMessage({
          data: checklistCompletionsData,
          message: t("emptyMessage.text", {
            entity: t("emptyMessage.entities.completions"),
          }),
          isSmall: true,
        })}
      />
      {isModalOpen && (
        <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      )}
    </>
  );
};

export default ChecklistCompletionsTable;
