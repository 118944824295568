import {
  useMemo,
  useRef,
  MouseEvent as ReactMouseEvent,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import { IMenuItems } from "../../interfaces/dropdown-menu";
import clsx from "clsx";
import { createPortal } from "react-dom";
import {
  DropdownMenuBox,
  DropdownMenuItem,
  DropdownMenuItemsHeader,
  DropdownMenuWrapper,
} from "./dropdown-menu.styles";
import { IPositionCoords } from "../../interfaces/delete-confirm";

interface IDropdownMenu {
  menuItems: IMenuItems[];
  position: IPositionCoords;
  isOpen: boolean;
  setIsDropdownMenuOpen: Dispatch<SetStateAction<boolean>>;
  header?: string;
}

const DropdownMenu = ({
  menuItems,
  position,
  isOpen,
  setIsDropdownMenuOpen,
  header,
}: IDropdownMenu) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as Node;

      if (dropdownRef.current && !dropdownRef.current.contains(target))
        setIsDropdownMenuOpen(false);
    };

    window.addEventListener("click", handleOutsideClick);

    return () => window.removeEventListener("click", handleOutsideClick);
  }, [setIsDropdownMenuOpen]);

  const dropdownContent = useMemo(() => {
    const handleOnItem = (
      onItem: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void,
      e: ReactMouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      setIsDropdownMenuOpen(false);
      onItem(e);
    };

    return (
      <DropdownMenuWrapper
        ref={dropdownRef}
        style={{
          left: position.x,
          top: position.y,
        }}
      >
        <DropdownMenuBox className={clsx(isOpen && "visible")}>
          {header && (
            <DropdownMenuItemsHeader>{header}</DropdownMenuItemsHeader>
          )}
          {menuItems.map(({ name, onItemClick, isDisabled }) => (
            <DropdownMenuItem
              onClick={(e) => handleOnItem(onItemClick, e)}
              key={name}
              className={clsx(isDisabled && "disabled")}
            >
              {name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuBox>
      </DropdownMenuWrapper>
    );
  }, [
    position.x,
    position.y,
    isOpen,
    header,
    menuItems,
    setIsDropdownMenuOpen,
  ]);

  return <>{isOpen && createPortal(dropdownContent, document.body)}</>;
};

export default DropdownMenu;
