import { createColumnHelper } from "@tanstack/react-table";
import {
  IChecklistsModelResponseDTO,
  ChecklistEntityEnum,
} from "../../../interfaces/checklists";
import IconButton from "../../icon-button/icon-button";
import { ReactNode } from "react";
import ChecklistComponent from "../../survey-components/checklist-component/checklist-component";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import { handleOnDeleteChecklist } from "./company-checklists-table.helper";
import { DeleteChecklistFunction } from "../../../interfaces/delete-function";
import { t } from "i18next";
import ChecklistCompletionsTable from "../checklist-completions-table/checklist-completions-table";
import { checkDate } from "../../../helpers/check-date.helper";
import { AddCompletionFunction } from "../../../interfaces/add-functions";
import ChecklistCreatorComponent from "../../survey-components/checklist-creator-component/checklist-creator-component";
import { IOpenDropdownMenu } from "../../../hooks/useDropdownMenu";
import { IMenuItems } from "../../../interfaces/dropdown-menu";
import { IUserLocations } from "../../../redux/userLocations/reducers";
import ChecklistModalContainer from "../../survey-components/checklist-modal-container/checklist-modal-container";
import { getColumnWidth } from "../kyps-table.utils";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";
import AuthWrapper from "../../../auth/auth-wrapper";
import { adminRights } from "../../../interfaces/auth-wrapper";

interface ICreateCompanyChecklistsTableColumns {
  closeModal: () => void;
  openModal: (modalContent: ReactNode) => void;
  companyId: string;
  deleteChecklistFunction: DeleteChecklistFunction;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  addCompletion: AddCompletionFunction;
  openDropdownMenu: (openDropdownMenuProps: IOpenDropdownMenu) => void;
  userLocations: IUserLocations[] | null;
}

interface ICreateCompanyChecklistsDropdownItems {
  model: string;
  userLocations: IUserLocations[] | null;
  addCompletion: AddCompletionFunction;
  closeModal: () => void;
  openModal: (modalContent: ReactNode) => void;
}

const { accessor, display } = createColumnHelper<IChecklistsModelResponseDTO>();

export const createCompanyChecklistsTableColumns = ({
  companyId,
  userLocations,
  addCompletion,
  closeModal,
  deleteChecklistFunction,
  openDeleteConfirm,
  openDropdownMenu,
  openModal,
}: ICreateCompanyChecklistsTableColumns) => [
  accessor("title", {
    header: t("tables.name"),
    size: getColumnWidth("checklists", "title"),
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("modelCreationDate", {
    header: t("tables.created"),
    size: getColumnWidth("checklists", "modelCreationDate"),
    cell: ({ getValue }) => <TooltipCell value={checkDate(getValue(), t)} />,
  }),
  accessor("updatedAt", {
    header: t("tables.lastUpdate"),
    size: getColumnWidth("checklists", "updatedAt"),
    cell: ({
      row: {
        original: { modelVersion, updatedAt },
      },
    }) => modelVersion > 1 && <TooltipCell value={checkDate(updatedAt, t)} />,
  }),
  display({
    header: "",
    id: "actions",
    size: getColumnWidth("checklists", "actions"),
    cell: ({
      row: {
        original: { modelId, title },
      },
    }) => (
      <>
        <IconButton
          name="checklist"
          hoverColor="info"
          onClick={(e) => {
            const menuItems = createCompanyChecklistsDropdownItems({
              openModal,
              userLocations,
              model: modelId,
              addCompletion,
              closeModal,
            });
            e.stopPropagation();
            openDropdownMenu({
              isDropdownMenuOpen: true,
              menuItems,
              position: e.currentTarget.getBoundingClientRect(),
            });
          }}
          dataName={t("checklists.actions.take")}
        />
        <IconButton
          name="history"
          hoverColor="info"
          onClick={() =>
            openModal(
              <ChecklistModalContainer closeModal={closeModal}>
                <ChecklistCompletionsTable
                  modelId={modelId}
                  isCompanyChecklist={true}
                />
              </ChecklistModalContainer>
            )
          }
          dataName={t("checklists.actions.reviewResult")}
        />
        <AuthWrapper allowedRoles={adminRights}>
          <IconButton
            name="pencil"
            hoverColor="info"
            onClick={() =>
              openModal(
                <ChecklistCreatorComponent
                  closeModal={closeModal}
                  entityName={companyId}
                  entity={ChecklistEntityEnum.COMPANY}
                  targetId={modelId}
                  modelId={modelId}
                  checklistName={title}
                  isEditMode
                />
              )
            }
            dataName={t("buttons.edit.actionWithKey", {
              key: t("tables.checklist"),
            })}
          />
          <IconButton
            name="bin"
            hoverColor="warning"
            onClick={(e) => {
              openDeleteConfirm({
                deleteConfirmName: title,
                openerParamsProp: e.currentTarget.getBoundingClientRect(),
                deleteFunction: () =>
                  handleOnDeleteChecklist({
                    deleteChecklistFunction,
                    modelId,
                    title,
                  }),
              });
            }}
            dataName={t("buttons.delete.actionWithKey", {
              key: t("tables.checklist"),
            })}
            hasZeroMargin
          />
        </AuthWrapper>
      </>
    ),
  }),
];

export const createCompanyChecklistsDropdownItems = ({
  openModal,
  userLocations,
  model,
  closeModal,
  addCompletion,
}: ICreateCompanyChecklistsDropdownItems): IMenuItems[] =>
  !userLocations
    ? []
    : userLocations.map(({ name, id }) => ({
        name,
        onItemClick: () => {
          openModal(
            <ChecklistComponent
              model={model}
              closeChecklist={closeModal}
              addCompletionFunction={addCompletion}
              locationId={id}
            />
          );
        },
      }));
