import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILocation } from "../../interfaces/locations";
import { LocationUserRoles } from "../../interfaces/roles";

export interface IUserLocations extends ILocation {
  userRole: LocationUserRoles;
}

interface IUserLocationsState {
  userLocations: IUserLocations[] | null;
}

const initialState: IUserLocationsState = {
  userLocations: null,
};

const userLocationsSlice = createSlice({
  name: "userLocations",
  initialState,
  reducers: {
    setUserLocations: (
      state,
      { payload }: PayloadAction<IUserLocations[]>
    ) => ({
      ...state,
      userLocations: payload,
    }),
  },
});

export const { setUserLocations } = userLocationsSlice.actions;
export default userLocationsSlice.reducer;
