import { useLocation } from "react-router-dom";
import "./App.css";
import LandingContent from "./containers/landing-content/landing-content";
import AppContent from "./containers/app-content/app-content";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const GATrackerId = "G-P652EKGETG";

const App = () => {
  useEffect(() => {
    if (!ReactGA.isInitialized) ReactGA.initialize(GATrackerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReactGA.isInitialized]);

  const { pathname } = useLocation();
  const isLandingPage = pathname === "/";

  if (isLandingPage) return <LandingContent />;

  return <AppContent />;
};

export default App;
