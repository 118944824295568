import {
  IAddChecklistRequest,
  IChecklistsModelResponseDTO,
  ILocationWithChecklistModelsProjection,
  IPaginatedCompanyChecklistsQuery,
  IPaginatedLocationChecklistsQuery,
  IUpdateChecklistRequest,
} from "../interfaces/checklists";
import { IPaginatedResponse } from "../interfaces/companies";
import { getSortParams } from "./api-helpers";
import { kypsApi } from "./kyps.api";

export const checklistsControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getCompanyChecklistModels: query<
      IPaginatedResponse<IChecklistsModelResponseDTO>,
      IPaginatedCompanyChecklistsQuery
    >({
      query: ({
        companyId,
        page,
        size,
        sort = [{ direction: "ASC", property: "createdAt" }],
      }) => ({
        url: `/companies/${companyId}/checklist-models?page=${page}&size=${size}&sort=${getSortParams(
          sort
        )}`,
        method: "GET",
      }),
      providesTags: ["CompanyChecklists"],
    }),

    getLocationChecklistModels: query<
      IPaginatedResponse<IChecklistsModelResponseDTO>,
      IPaginatedLocationChecklistsQuery
    >({
      query: ({
        locationId,
        page,
        size,
        sort = [{ direction: "ASC", property: "createdAt" }],
      }) => ({
        url: `/locations/${locationId}/checklist-models?page=${page}&size=${size}&sort=${getSortParams(
          sort
        )}`,
        method: "GET",
      }),
      providesTags: ["LocationChecklists"],
    }),

    getChecklistModelById: query<IChecklistsModelResponseDTO, string>({
      query: (modelId) => ({
        url: `/checklist-models/${modelId}`,
        method: "GET",
      }),
      providesTags: ["CompanyChecklists", "LocationChecklists"],
    }),

    addChecklist: mutation<string, IAddChecklistRequest>({
      query: ({ entity, targetId, body }) => ({
        url: `${entity}/${targetId}/checklist-models`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["CompanyChecklists", "LocationChecklists"],
    }),

    updateChecklist: mutation<string, IUpdateChecklistRequest>({
      query: ({ body, modelId }) => ({
        url: `/checklist-models/${modelId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["CompanyChecklists", "LocationChecklists"],
    }),

    deleteChecklist: mutation<string, string>({
      query: (modelId) => ({
        url: `/checklist-models/${modelId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CompanyChecklists", "LocationChecklists"],
    }),

    getAllLocationsChecklistModelsWithoutContent: query<
      ILocationWithChecklistModelsProjection[],
      string
    >({
      query: (companyId) => ({
        url: `/companies/${companyId}/locations/all/checklist-models-without-content`,
        method: "GET",
      }),
      providesTags: ["LocationChecklists"],
    }),
  }),
});

export const {
  useAddChecklistMutation,
  useUpdateChecklistMutation,
  useDeleteChecklistMutation,
  useGetChecklistModelByIdQuery,
  useGetCompanyChecklistModelsQuery,
  useGetLocationChecklistModelsQuery,
  useGetAllLocationsChecklistModelsWithoutContentQuery,
} = checklistsControllerEndpoints;
