import { RouteObject } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Management from "./pages/management";
import Reports from "./pages/reports/reports";
import CompanyDashboard from "./components/dashboard-components/company-dashboard/company-dashboard";
import LocationDashboard from "./components/dashboard-components/location-dashboard/location-dashboard";
import Home from "./pages/home";
import NotFound from "./pages/not-found";
import { PagesPathsEnum } from "./utils/pages-paths";
import Checklists from "./pages/checklists/checklists";

const checklistsRoute: RouteObject = {
  path: PagesPathsEnum.CHECKLISTS,
  element: <Checklists />,
};

export const createRoutes = (
  areChecklistsAvailable: boolean
): RouteObject[] => {
  const baseRoutes: RouteObject[] = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: `${PagesPathsEnum.DASHBOARD}/`,
      element: <Dashboard />,
      children: [
        { index: true, element: <CompanyDashboard /> },
        { path: ":locationId", element: <LocationDashboard /> },
      ],
    },
    {
      path: PagesPathsEnum.MANAGEMENT,
      element: <Management />,
    },
    {
      path: PagesPathsEnum.REPORTS,
      element: <Reports />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return areChecklistsAvailable
    ? baseRoutes.concat(checklistsRoute)
    : baseRoutes;
};
