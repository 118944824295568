import { IPaginationQuery } from "./companies";
import { ILocation } from "./locations";

export interface IChecklistsModelWithoutContent {
  title: string;
  modelId: string;
  createdAt: string;
  updatedAt: string;
  modelVersion: number;
}

export interface IChecklistsModelResponseDTO {
  title: string;
  model: string;
  modelId: string;
  updatedAt: string;
  modelVersion: number;
  versionCreationDate: string;
  modelCreationDate: string;
}

export interface IChecklistCompletionResponseDTO {
  content: string;
  correctAnswers: number;
  incorrectAnswers: number;
  questionCount: number;
  id: string;
  checklistModel: IChecklistModelWithoutContentProjection;
  respondent: string;
  date: string;
  location: ILocation;
}

export enum ChecklistEntityEnum {
  LOCATION = "locations",
  COMPANY = "companies",
}

export type ChecklistEntity =
  | ChecklistEntityEnum.COMPANY
  | ChecklistEntityEnum.LOCATION;

export interface IUpdateChecklistRequest {
  modelId: string;
  body: {
    model: string;
    title: string;
  };
}

export interface IAddChecklistRequest {
  targetId: string;
  entity: ChecklistEntity;
  body: {
    model: string;
    title: string;
  };
}

export interface IAddCompletionBodyRequest {
  content: string;
  correctAnswers: number;
  incorrectAnswers: number;
  questionCount: number;
}

export interface IAddChecklistCompletionRequest {
  modelId: string;
  locationId: string;
  body: IAddCompletionBodyRequest;
}

export interface ILocationChecklistModelsResponseDTO {
  name: string;
  address: string;
  gatewayNumbers: string[];
  comments: string;
  id: string;
  checklistModels: IChecklistsModelResponseDTO[];
}

export interface ILocationWithChecklistModelsProjection {
  checklistModels: IChecklistModelWithoutContentProjection[];
  name: string;
  id: string;
  address: string;
}

export interface IChecklistModelWithoutContentProjection {
  updatedAt: string;
  title: string;
  modelId: string;
  modelVersion: number;
  versionCreationDate: string;
  modelCreationDate: string;
}

export interface ISortCompletionsByKeys
  extends Omit<IChecklistCompletionResponseDTO, "id" | "checklistModel"> {}

export interface ISortChecklistsByKeys
  extends Omit<IChecklistModelWithoutContentProjection, "modelId"> {}

export interface IPaginatedCompletionsQuery
  extends IPaginationQuery<ISortCompletionsByKeys> {
  modelId: string;
}

export interface IPaginatedCompanyChecklistsQuery
  extends IPaginationQuery<ISortChecklistsByKeys> {
  companyId: string;
}

export interface IPaginatedLocationChecklistsQuery
  extends IPaginationQuery<ISortChecklistsByKeys> {
  locationId: string;
}
