import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const DropdownMenuWrapper = styled.div`
  ${({ theme: { zIndex } }) => css`
    position: absolute;
    z-index: ${zIndex.dropdownMenu};
  `}
`;

export const DropdownOpenerWrapper = styled.div`
  ${({ theme }) => css`
    padding: 2px;
    border: 2px solid transparent;
    border-radius: 8px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    * {
      margin: 0 auto;
    }

    &.open {
      border-color: ${theme.palette.border.default};

      &.light {
        border-color: ${theme.palette.background.disable};
      }
    }

    &.light {
      max-height: 36px;
    }
  `}
`;

export const DropdownMenuBox = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: calc(100% + 1px);
    right: 0;
    min-width: 180px;
    width: max-content;
    overflow: hidden;
    background-color: ${theme.palette.background.default};
    border-radius: 6px;
    visibility: hidden;
    pointer-events: none;

    &.visible {
      max-height: 200px;
      overflow: auto;
      pointer-events: all;
      visibility: visible;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    }
  `}
`;

export const DropdownMenuItem = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.text.dark};
    padding: 6px 9px;
    font-size: 12px;
    cursor: pointer;

    &.disabled {
      color: ${theme.palette.background.disable};
      background-color: ${theme.palette.background.secondary};
      pointer-events: none;
    }

    &:hover {
      background-color: ${theme.palette.background.light};
    }

    @media (min-width: 768px) {
      padding: 8px 16px;
      font-size: 14px;
    }
  `}
`;

export const DropdownMenuItemsHeader = styled.h4`
  ${({ theme }) => css`
    color: ${theme.palette.text.dark};
    background-color: ${theme.palette.background.secondary};
    padding: 6px 9px;
    font-size: 12px;

    @media (min-width: 768px) {
      padding: 8px 16px;
      font-size: 14px;
    }
  `}
`;
