import { ReactNode } from "react";
import IconButton from "../../icon-button/icon-button";
import { createColumnHelper } from "@tanstack/react-table";
import { FlexDiv } from "../../../styles/helper.styles";
import { t } from "i18next";
import KypsTooltip from "../../kyps-tooltip/kyps-tooltip";
import clsx from "clsx";
import { CellMaxContent, ExpandedCell, FixedCell } from "../kyps-table.styles";
import ChecklistCompletionsTable from "../checklist-completions-table/checklist-completions-table";
import {
  IChecklistModelWithoutContentProjection,
  ChecklistEntityEnum,
} from "../../../interfaces/checklists";
import ChecklistComponent from "../../survey-components/checklist-component/checklist-component";
import { checkDate } from "../../../helpers/check-date.helper";
import { AddCompletionFunction } from "../../../interfaces/add-functions";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import { handleOnDeleteChecklist } from "../company-checklists-table/company-checklists-table.helper";
import { DeleteChecklistFunction } from "../../../interfaces/delete-function";
import ChecklistCreatorComponent from "../../survey-components/checklist-creator-component/checklist-creator-component";
import ChecklistModalContainer from "../../survey-components/checklist-modal-container/checklist-modal-container";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";
import { getColumnWidth } from "../kyps-table.utils";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import { getIsAllowedForManager } from "../locations-table/locations-table.helper";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";

export interface ILocationChecklistsTableData
  extends IChecklistModelWithoutContentProjection {
  subRows?: IChecklistModelWithoutContentProjection[];
}

interface ICreateLocationChecklistsTableColumns {
  closeModal: () => void;
  openModal: (modalContent: ReactNode) => void;
  addCompletion: AddCompletionFunction;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  deleteChecklistFunction: DeleteChecklistFunction;
  userRole: UserRoles;
}

const { accessor, display } =
  createColumnHelper<ILocationChecklistsTableData>();
export const createLocationChecklistsTableColumns = ({
  closeModal,
  openModal,
  addCompletion,
  openDeleteConfirm,
  deleteChecklistFunction,
  userRole,
}: ICreateLocationChecklistsTableColumns) => [
  accessor("title", {
    header: ({
      table: { getIsSomeRowsExpanded, getToggleAllRowsExpandedHandler },
    }) => (
      <FlexDiv
        className="clickable"
        onClick={getToggleAllRowsExpandedHandler()}
      >
        <IconButton
          name="toLine"
          hoverColor="darken"
          dataName={
            getIsSomeRowsExpanded()
              ? t("buttons.collapse.action")
              : t("buttons.expand.action")
          }
        />
        {t("tables.name")}
      </FlexDiv>
    ),
    size: getColumnWidth("checklists", "title"),
    cell: ({
      row: { getCanExpand, getToggleExpandedHandler, getIsExpanded, depth },
      getValue,
    }) => {
      if (depth > 0)
        return (
          <ExpandedCell>
            <KypsTooltip tooltipValue={getValue()} />
          </ExpandedCell>
        );

      return (
        <KypsTooltip
          tooltipValue={getValue()}
          onClick={depth > 0 ? undefined : getToggleExpandedHandler()}
        >
          <FixedCell className={clsx((getCanExpand() || depth) && "clickable")}>
            {getCanExpand() && (
              <IconButton
                isExpanded={getIsExpanded()}
                name="caret"
                hoverColor="darken"
                hasZeroMargin
              />
            )}
            {getValue()}
          </FixedCell>
        </KypsTooltip>
      );
    },
  }),
  accessor("modelCreationDate", {
    header: t("tables.created"),
    size: getColumnWidth("checklists", "modelCreationDate"),
    cell: ({ getValue }) => (
      <CellMaxContent>{checkDate(getValue(), t)}</CellMaxContent>
    ),
  }),
  accessor("updatedAt", {
    header: t("tables.lastUpdate"),
    size: getColumnWidth("checklists", "updatedAt"),
    cell: ({
      row: {
        original: { modelVersion, updatedAt },
      },
    }) => modelVersion > 1 && <TooltipCell value={checkDate(updatedAt, t)} />,
  }),
  display({
    header: "",
    id: "actions",
    size: getColumnWidth("checklists", "actions"),
    cell: ({
      row: {
        original: { modelId, title },
        depth,
        getParentRow,
      },
    }) =>
      depth ? (
        <>
          <IconButton
            name="checklist"
            hoverColor="info"
            onClick={() => {
              openModal(
                <ChecklistComponent
                  closeChecklist={closeModal}
                  model={modelId}
                  addCompletionFunction={addCompletion}
                  locationId={getParentRow()?.original.modelId}
                />
              );
            }}
            dataName={t("checklists.actions.take")}
          />
          <IconButton
            name="history"
            hoverColor="info"
            onClick={() => {
              openModal(
                <ChecklistModalContainer closeModal={closeModal}>
                  <ChecklistCompletionsTable modelId={modelId} />
                </ChecklistModalContainer>
              );
            }}
            dataName={t("checklists.actions.reviewResult")}
          />
          <AuthWrapper
            allowedRoles={managersRights}
            additionalCheck={
              getIsAllowedForManager(
                getParentRow()?.original.modelId as string,
                UserRolesEnum.MANAGER
              ) || userRole === UserRolesEnum.ADMIN
            }
          >
            <>
              <IconButton
                name="pencil"
                hoverColor="info"
                onClick={() => {
                  openModal(
                    <ChecklistCreatorComponent
                      closeModal={closeModal}
                      entityName={getParentRow()?.original.title as string}
                      entity={ChecklistEntityEnum.LOCATION}
                      targetId={getParentRow()?.original.modelId as string}
                      modelId={modelId}
                      checklistName={getParentRow()?.original.title as string}
                      isEditMode
                    />
                  );
                }}
                dataName={t("buttons.edit.actionWithKey", {
                  key: t("tables.checklist"),
                })}
              />
              <IconButton
                name="bin"
                hoverColor="warning"
                onClick={(e) => {
                  openDeleteConfirm({
                    deleteConfirmName: title,
                    openerParamsProp: e.currentTarget.getBoundingClientRect(),
                    deleteFunction: () =>
                      handleOnDeleteChecklist({
                        deleteChecklistFunction,
                        modelId,
                        title,
                      }),
                  });
                }}
                dataName={t("buttons.delete.actionWithKey", {
                  key: t("tables.checklist"),
                })}
              />
            </>
          </AuthWrapper>
        </>
      ) : null,
  }),
];
