import { useRoutes } from "react-router-dom";
import { createRoutes } from "../../routes";
import { Container, Wrapper } from "../../styles/helper.styles";
import { useAppSelector } from "../../hooks/useAppSelector";

const Holder = () => {
  const { areChecklistsAvailable, loading } = useAppSelector(
    ({ company }) => company
  );

  const content = useRoutes(createRoutes(areChecklistsAvailable));

  return <Wrapper>{loading ? null : <Container>{content}</Container>}</Wrapper>;
};

export default Holder;
