import { Dispatch, ReactNode, SetStateAction } from "react";
import IconButton from "../../icon-button/icon-button";
import { FlexDiv } from "../../../styles/helper.styles";
import {
  CustomLabel,
  TemperatureData,
  CellMaxContent,
  FixedCell,
  ExpandedCell,
} from "../kyps-table.styles";
import clsx from "clsx";
import { checkDate } from "../../../helpers/check-date.helper";
import { createColumnHelper } from "@tanstack/react-table";
import { ILocationTableData } from "../../../interfaces/tables";
import { createDropdownMenuItems } from "./location-table.helper";
import { ISelectOption } from "../../kyps-select/kyps-select";
import {
  checkBatteryLevel,
  checkSignalLevel,
  sortingFunction,
} from "../kyps-table.helper";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import SensorDetailsSidebar from "../../kyps-sidebar/sensor-details-sidebar/sensor-details-sidebar";
import KypsTooltip from "../../kyps-tooltip/kyps-tooltip";
import { KypsChart } from "../../kyps-chart/kyps-chart";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import { TFunction } from "i18next";
import { getColumnWidth } from "../kyps-table.utils";
import SensorNameComponent from "../../sensor-name-component/sensor-name-component";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import { IOpenDropdownMenu } from "../../../hooks/useDropdownMenu";
import { checkElementVisibility } from "../../../helpers/check-element-visibility";
import { DropdownOpenerWrapper } from "../../dropdown-menu/dropdown-menu.styles";

interface ICreateLocationTableColumns {
  selectedZone: ISelectOption;
  zonesOptions: ISelectOption[];
  minDate: Date | null;
  userRole: UserRoles;
  isMenuOpen: boolean;
  selectedId: string;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  deleteSensor: (id: string, name: string) => void;
  deleteIPoint: (id: string, name: string) => void;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  setSelectedId: Dispatch<SetStateAction<string>>;
  openDropdownMenu: (openDropdownMenuProps: IOpenDropdownMenu) => void;
  t: TFunction<"translations", undefined>;
}

const { accessor, display } = createColumnHelper<ILocationTableData>();

export const createLocationTableColumns = ({
  selectedZone,
  zonesOptions,
  minDate,
  userRole,
  isMenuOpen,
  selectedId,
  openModal,
  closeAndResetModal,
  deleteSensor,
  deleteIPoint,
  openDeleteConfirm,
  openDropdownMenu,
  setSelectedId,
  t,
}: ICreateLocationTableColumns) => [
  accessor("name", {
    header: ({
      table: { getIsSomeRowsExpanded, getToggleAllRowsExpandedHandler },
    }) => (
      <FlexDiv
        className="clickable"
        onClick={getToggleAllRowsExpandedHandler()}
      >
        <IconButton
          name="toLine"
          hoverColor="darken"
          dataName={
            getIsSomeRowsExpanded()
              ? t("buttons.collapse.action")
              : t("buttons.expand.action")
          }
        />
        {t("tables.name")}
      </FlexDiv>
    ),
    size: getColumnWidth("location", "name"),
    sortingFn: (rowA, rowB) =>
      sortingFunction(rowA.original.name.value, rowB.original.name.value),
    cell: ({
      row: {
        getCanExpand,
        getToggleExpandedHandler,
        getIsExpanded,
        depth,
        original: {
          id: sensorId,
          name: { value },
          showHumidity,
          sensorName,
        },
        getParentRow,
      },
      getValue,
    }) => {
      const parentRow = getParentRow();

      const openSensorDetails = () => {
        if (parentRow) {
          const {
            original: { id, name, zone },
          } = parentRow;

          openModal(
            <SensorDetailsSidebar
              closeModal={closeAndResetModal}
              defaultZone={zone ?? selectedZone}
              sensorId={sensorId}
              sensorNumber={value}
              defaultIPoint={{ label: name.value, value: id }}
              showHumidity={!!showHumidity}
              minDate={minDate}
              isDisabled={userRole === UserRolesEnum.USER}
            />
          );
        }
      };
      if (depth > 0)
        return (
          <ExpandedCell>
            <SensorNameComponent
              name={value}
              onClick={openSensorDetails}
              isCustom={getValue().isCustomConfig}
              subName={sensorName}
            />
          </ExpandedCell>
        );

      return (
        <KypsTooltip
          tooltipValue={getValue().value}
          onClick={depth > 0 ? openSensorDetails : getToggleExpandedHandler()}
        >
          <FixedCell className={clsx((getCanExpand() || depth) && "clickable")}>
            {getCanExpand() && (
              <IconButton
                isExpanded={getIsExpanded()}
                name="caret"
                hoverColor="darken"
                hasZeroMargin
              />
            )}
            {getValue().value}
            {getValue().isCustomConfig && <CustomLabel>Custom</CustomLabel>}
          </FixedCell>
        </KypsTooltip>
      );
    },
  }),
  display({
    header: "",
    size: getColumnWidth("location", "openChart"),
    cell: ({
      row: {
        original: {
          name: { value },
          safeZone,
        },
        getParentRow,
      },
    }) => {
      const parentRow = getParentRow();
      if (parentRow) {
        const iPointName = parentRow.original.name.value;
        const { highGreen, highYellow, lowGreen, lowYellow } = safeZone;

        return (
          <IconButton
            dataName={t("buttons.open.actionWithKey", {
              key: t("entities.chart"),
            })}
            name="chart"
            onClick={() =>
              openModal(
                <KypsChart
                  closeModal={closeAndResetModal}
                  range={[lowYellow, lowGreen, highGreen, highYellow]}
                  sensorNumber={value}
                  minDate={minDate}
                  iPointName={iPointName}
                  t={t}
                />
              )
            }
            hasZeroMargin
          />
        );
      }
    },
    id: "openChart",
  }),
  accessor("safeZone", {
    header: () => <CellMaxContent>{t("tables.safeZone")} (°C)</CellMaxContent>,
    size: getColumnWidth("location", "safeZone"),
    cell: ({
      row: {
        original: {
          name: { isCustomConfig },
        },
        depth,
      },
      getValue,
    }) =>
      (isCustomConfig || depth === 0) && (
        <CellMaxContent>
          {`${getValue().lowGreen}; ${getValue().highGreen}`}
        </CellMaxContent>
      ),
  }),
  accessor("temperature", {
    header: "",
    size: getColumnWidth("location", "temperature"),
    cell: ({ getValue, row: { depth } }) => (
      <>
        {!!depth && (
          <TemperatureData className={clsx(getValue()?.state)}>
            {getValue()?.value}
          </TemperatureData>
        )}
      </>
    ),
  }),
  accessor("battery", {
    header: "",
    size: getColumnWidth("location", "battery"),
    cell: ({ getValue, row: { depth } }) => (
      <CellMaxContent>
        {!!depth && checkBatteryLevel(getValue())}
      </CellMaxContent>
    ),
  }),
  accessor("signal", {
    header: "",
    size: getColumnWidth("location", "signal"),
    cell: ({ getValue, row: { depth } }) => (
      <CellMaxContent>{!!depth && checkSignalLevel(getValue())}</CellMaxContent>
    ),
  }),
  accessor("time", {
    header: t("tables.lastUpdate"),
    size: getColumnWidth("location", "lastUpdate"),
    cell: ({ getValue, row: { depth } }) => (
      <CellMaxContent>
        {!!depth && <>{checkDate(getValue() as string, t)}</>}
      </CellMaxContent>
    ),
  }),
  display({
    header: "",
    size: getColumnWidth("location", "actions"),
    cell: ({
      row: {
        depth,
        original: {
          id,
          name: { value },
        },
        getParentRow,
      },
    }) => {
      const IPointValues = getParentRow()?.original;
      const selectedIPoint = IPointValues && {
        label: IPointValues.name.value,
        value: IPointValues.id,
      };

      return (
        <AuthWrapper
          allowedRoles={managersRights}
          children={
            <DropdownOpenerWrapper
              className={clsx(
                isMenuOpen && selectedId === id && "open",
                "light"
              )}
            >
              <IconButton
                name="vDots"
                hoverColor="none"
                color={depth > 0 ? "light" : ""}
                hasZeroMargin
                onClick={(e) => {
                  e.stopPropagation();

                  const openerParams = e.currentTarget.getBoundingClientRect();
                  const menuItems = createDropdownMenuItems({
                    id,
                    openModal,
                    closeAndResetModal,
                    role: depth === 0 ? "iPoint" : "sensor",
                    selectedZone,
                    zonesOptions,
                    selectedIPoint,
                    t,
                    onDeleteButton: () =>
                      openDeleteConfirm({
                        deleteConfirmName: value,
                        deleteFunction: () => {
                          if (depth === 0) {
                            deleteIPoint(id, value);
                            return;
                          }

                          deleteSensor(id, value);
                        },
                        openerParamsProp: openerParams,
                      }),
                  });

                  setSelectedId(id);

                  openDropdownMenu({
                    isDropdownMenuOpen: true,
                    position: checkElementVisibility(
                      openerParams,
                      menuItems.length
                    ),
                    menuItems,
                  });
                }}
              />
            </DropdownOpenerWrapper>
          }
        />
      );
    },
    id: "actions",
  }),
];
