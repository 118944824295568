import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { useMemo, useRef, useState } from "react";
import {
  ISortChecklistsByKeys,
  ChecklistEntityEnum,
} from "../../../interfaces/checklists";
import { createCompanyChecklistsTableColumns } from "./company-checklists-table.utils";
import KypsModal from "../../kyps-modal/kyps-modal";
import useModal from "../../../hooks/useModal";
import { KypsButton } from "../../kyps-button/kyps-button";
import { Subheading } from "../../../styles/helper.styles";
import { t } from "i18next";
import ChecklistCreatorComponent from "../../survey-components/checklist-creator-component/checklist-creator-component";
import DeleteConfirm from "../../delete-confirm/delete-confirm";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import {
  useDeleteChecklistMutation,
  useGetCompanyChecklistModelsQuery,
} from "../../../api/checklists.api";
import { useAddChecklistCompletionMutation } from "../../../api/checklists-completion.api";
import useDropdownMenu from "../../../hooks/useDropdownMenu";
import DropdownMenu from "../../dropdown-menu/dropdown-menu";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { skipToken } from "@reduxjs/toolkit/query";
import { IPaginationQueryObj } from "../../../interfaces/tables";
import { CompanyChecklistsTableHeader } from "./company-checklists-table.styles";
import Loaders from "../../loaders/loaders";
import AuthWrapper from "../../../auth/auth-wrapper";
import { adminRights } from "../../../interfaces/auth-wrapper";
import { hasEmptyMessage } from "../location-table/location-table.helper";

interface ICompanyChecklistsTable {
  companyId: string;
  companyName: string;
}

const baseSortObj: {
  property: keyof ISortChecklistsByKeys;
  direction: "ASC" | "DESC";
} = {
  direction: "DESC",
  property: "modelCreationDate",
};

const CompanyChecklistsTable = ({
  companyId,
  companyName,
}: ICompanyChecklistsTable) => {
  const userLocations = useAppSelector(
    ({ userLocations: { userLocations } }) => userLocations
  );
  const tableRef = useRef<HTMLDivElement | null>(null);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([
    {
      desc: baseSortObj.direction === "DESC",
      id: baseSortObj.property,
    },
  ]);

  const queryObj: IPaginationQueryObj<ISortChecklistsByKeys> = useMemo(
    () => ({
      page: pageIndex,
      size: pageSize,
      sort: [
        sorting.length
          ? {
              property: sorting[0].id as keyof ISortChecklistsByKeys,
              direction: sorting[0].desc ? "DESC" : "ASC",
            }
          : baseSortObj,
      ],
    }),
    [pageIndex, pageSize, sorting]
  );

  const [deleteChecklistFunction] = useDeleteChecklistMutation();
  const [addCompletion] = useAddChecklistCompletionMutation();
  const {
    data: apiCompanyChecklists,
    isLoading,
    status,
  } = useGetCompanyChecklistModelsQuery(
    companyId ? { companyId, ...queryObj } : skipToken
  );

  const tableData = useMemo(
    () => apiCompanyChecklists?.content ?? [],
    [apiCompanyChecklists]
  );

  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();
  const {
    deleteConfirmName,
    isDeleteConfirmOpen,
    openerParams,
    closeDeleteConfirm,
    deleteFunction,
    openDeleteConfirm,
  } = useDeleteConfirm(tableRef);
  const {
    dropdownMenuPosition,
    isDropdownMenuOpen,
    menuItems,
    openDropdownMenu,
    setIsDropdownMenuOpen,
  } = useDropdownMenu(tableRef);

  const table = useReactTable({
    columns: createCompanyChecklistsTableColumns({
      openModal,
      closeModal: closeAndResetModal,
      companyId,
      openDeleteConfirm,
      deleteChecklistFunction,
      addCompletion,
      openDropdownMenu,
      userLocations,
    }),
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: { left: ["title"] },
      pagination: { pageIndex, pageSize },
      sorting,
    },
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    sortDescFirst: false,
    manualPagination: true,
    pageCount: apiCompanyChecklists?.totalPages,
    autoResetPageIndex: false,
  });

  const tableHeader = useMemo(
    () => [
      <CompanyChecklistsTableHeader key="companyChecklistsTableHeader">
        <Subheading>{t("tabs.companyChecklists")}</Subheading>
        <AuthWrapper allowedRoles={adminRights}>
          <KypsButton
            onClick={() =>
              openModal(
                <ChecklistCreatorComponent
                  entityName={companyName}
                  entity={ChecklistEntityEnum.COMPANY}
                  targetId={companyId}
                  closeModal={closeAndResetModal}
                />
              )
            }
            placeholder={t("buttons.add.actionWithKey", {
              key: t("checklists.newChecklist"),
            })}
            iconType="plus"
            hasIcon
          />
        </AuthWrapper>
      </CompanyChecklistsTableHeader>,
    ],
    [closeAndResetModal, companyId, companyName, openModal]
  );

  if (isLoading) return <Loaders variant="table" />;

  return (
    <>
      <KypsTable
        table={table}
        tableRef={tableRef}
        tableHeaderElements={tableHeader}
        emptyMessage={
          status &&
          hasEmptyMessage({
            data: tableData,
            message: t("emptyMessage.text", {
              entity: t("emptyMessage.entities.checklist"),
            }),
            isLoading,
            status,
          })
        }
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
        />
      )}
      {isDropdownMenuOpen && (
        <DropdownMenu
          menuItems={menuItems}
          position={dropdownMenuPosition}
          isOpen={isDropdownMenuOpen}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
          header={t("checklists.actions.takeAs")}
        />
      )}
    </>
  );
};

export default CompanyChecklistsTable;
