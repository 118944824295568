import { t } from "i18next";
import { DeleteChecklistFunction } from "../../../interfaces/delete-function";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnDeleteChecklist {
  deleteChecklistFunction: DeleteChecklistFunction;
  modelId: string;
  title: string;
}

export const handleOnDeleteChecklist = ({
  deleteChecklistFunction,
  modelId,
  title,
}: IHandleOnDeleteChecklist) =>
  deleteChecklistFunction(modelId)
    .unwrap()
    .then(() =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.deleted"),
          entity: `${t("entities.checklist")} ${title}`,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
