import { getUserRoles } from "../../auth/get-user-roles.helper";
import { ICompany } from "../../interfaces/companies";
import { UserRolesEnum } from "../../interfaces/roles";
import { ISelectOption } from "../kyps-select/kyps-select";

export const createCompaniesOptions = (data: ICompany[]): ISelectOption[] =>
  data.map(({ fullName, id }) => ({ label: fullName, value: id }));

interface IGetIsLocationAllowed {
  companyId: string;
  locationId: string;
  rolesArray: string[];
}

export enum LocationIdStatus {
  ALLOWED = "allowed",
  NOT_ALLOWED = "not allowed",
  LOADING = "loading",
  NO_URL = "no url",
}

export const getIsLocationAllowed = ({
  companyId,
  locationId,
  rolesArray,
}: IGetIsLocationAllowed) => {
  const userRole = getUserRoles({ url: companyId });

  if (
    userRole === UserRolesEnum.SUPER_ADMIN ||
    userRole === UserRolesEnum.ADMIN
  )
    return LocationIdStatus.ALLOWED;

  return rolesArray.find((userGroup) => userGroup.includes(locationId))
    ? LocationIdStatus.ALLOWED
    : LocationIdStatus.NOT_ALLOWED;
};
