import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useUpdateCompanyByIdMutation } from "../../../api/companies.api";
import { IEditCompany } from "../../../interfaces/companies";
import { createNotification } from "../../toaster/toaster.helper";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { t } from "i18next";

type EditCompanyResult = ReturnType<typeof useUpdateCompanyByIdMutation>;
type EditCompanyFunction = EditCompanyResult[0];

interface IHandleOnEditCompany {
  data: IEditCompany;
  editCompany: EditCompanyFunction;
  closeModal: () => void;
  updateChecklistsState: ActionCreatorWithPayload<boolean, string>;
  currentCompany: ISelectOption | null;
}

export const handleOnEditCompany = ({
  data,
  editCompany,
  closeModal,
  updateChecklistsState,
  currentCompany,
}: IHandleOnEditCompany) => {
  closeModal();

  editCompany(data)
    .unwrap()
    .then(({ fullName, checklistsAvailable }) => {
      if (currentCompany?.value === data.id)
        updateChecklistsState(checklistsAvailable);

      createNotification({
        message: t("notification.success", {
          entity: `${t("entities.company")} ${fullName}`,
          action: t("notification.actions.updated"),
        }),
        variant: "success",
      });
    })
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
