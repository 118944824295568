import { useRef, useMemo, useCallback, useState, useEffect } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import { createAddCompanyFields } from "../add-company-sidebar/add-company-sidebar.utils";
import {
  IAddCompany,
  IEditCompanyFormFields,
} from "../../../interfaces/companies";
import KypsForm from "../../kyps-form/kyps-form";
import {
  useGetCompanyByIdQuery,
  useUpdateCompanyByIdMutation,
} from "../../../api/companies.api";
import { handleOnEditCompany } from "./edit-company-sidebar.helper";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Loaders from "../../loaders/loaders";
import CompanyManagersTable from "../../kyps-tables/company-managers-table/company-managers-table";
import { UserRolesEnum } from "../../../interfaces/roles";
import { TFunction } from "i18next";
import CustomCheckbox from "../../kyps-custom-checkbox/custom-checkbox";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import AuthWrapper from "../../../auth/auth-wrapper";
import { adminRights } from "../../../interfaces/auth-wrapper";

interface IEditCompanySidebar {
  currentCompanyId: string;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const EditCompanySidebar = ({
  currentCompanyId,
  closeModal,
  t,
}: IEditCompanySidebar): JSX.Element => {
  const userRole = useAppSelector(({ user }) => user.user?.role);
  const currentCompany = useAppSelector(
    ({ company }) => company.selectedCompany
  );
  const { updateChecklistsState } = useAppDispatch();
  const [areChecklistsAvailable, setAreChecklistsAvailable] =
    useState<boolean>(false);

  const [editCompany] = useUpdateCompanyByIdMutation();
  const { data: apiCompanyData, isLoading } =
    useGetCompanyByIdQuery(currentCompanyId);

  useEffect(() => {
    if (apiCompanyData)
      setAreChecklistsAvailable(!!apiCompanyData.checklistsAvailable);
  }, [apiCompanyData]);

  const editFields = useMemo(() => {
    if (apiCompanyData)
      return createAddCompanyFields(t).map((field) => {
        const value =
          apiCompanyData[field.name as keyof IEditCompanyFormFields] ?? "";

        return {
          ...field,
          value,
          isHidden:
            userRole !== UserRolesEnum.SUPER_ADMIN && field.name === "comment",
        };
      });

    return [];
  }, [apiCompanyData, t, userRole]);

  const formRef = useRef<HTMLFormElement>(null);
  const handleEditCompanyFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmitEditCompany = useCallback(
    ({ fullName, shortName, comment }: IAddCompany) => {
      const companyData: IEditCompanyFormFields = {
        fullName,
        shortName,
        comment,
        id: currentCompanyId,
      };
      handleOnEditCompany({
        editCompany,
        closeModal,
        data: {
          ...companyData,
          checklistsAvailable: areChecklistsAvailable,
        },
        currentCompany,
        updateChecklistsState,
      });
    },
    [
      areChecklistsAvailable,
      closeModal,
      currentCompany,
      currentCompanyId,
      editCompany,
      updateChecklistsState,
    ]
  );

  const sidebarContent = useMemo(() => {
    if (isLoading) return [<Loaders key="Loader" variant="sidebar" />];

    return [
      <KypsForm
        fields={editFields}
        onSubmit={onSubmitEditCompany}
        description={
          <AuthWrapper allowedRoles={adminRights}>
            <CustomCheckbox
              checkboxHeading={t("checklists.actions.showChecklists")}
              isChecked={areChecklistsAvailable}
              onCheckboxClick={() =>
                setAreChecklistsAvailable(!areChecklistsAvailable)
              }
            />
          </AuthWrapper>
        }
        ref={formRef}
        key="EditCompanyForm"
      />,
      <CompanyManagersTable
        companyId={currentCompanyId}
        key="CompanyManagersTable"
        t={t}
      />,
    ];
  }, [
    isLoading,
    editFields,
    onSubmitEditCompany,
    areChecklistsAvailable,
    currentCompanyId,
    t,
  ]);

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: `${t("buttons.edit.actionWithKey", {
          key: apiCompanyData?.fullName ?? t("entities.company"),
        })}`,
        buttons: [
          <KypsButton
            key="Cancel"
            variant="light"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
          />,
          <KypsButton
            key="Edit company"
            variant="default"
            placeholder={t("buttons.edit.actionWithKey", {
              key: t("entities.company"),
            })}
            onClick={handleEditCompanyFormSubmit}
          />,
        ],
      }}
      content={sidebarContent}
    />
  );
};

export default EditCompanySidebar;
