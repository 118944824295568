import { css } from "@emotion/react";
import styled from "@emotion/styled";
import arrowUp from "../../assets/arrow-up.svg";
import arrowDown from "../../assets/arrow-down.svg";

export const tableCellPaddings = 48;

export const TableComponentWrapper = styled.div`
  ${({ theme }) => css`
    padding: 10px 8px;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;

    &.report {
      padding: 0 0 32px 0;
      border: none;
      background-color: transparent;
    }

    &.without-bg {
      border: none;
      background-color: transparent;
      padding: 0;
    }

    @media (min-width: 768px) {
      padding: 20px 16px;
    }
  `}
`;

export const TableWrapperWithHeader = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    overflow: hidden;
  `}
`;

export const TableWrapper = styled.div`
  overflow: auto hidden;
`;

export const TableName = styled.h3`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.text.primary};
    width: 100%;
    margin-bottom: 16px;
  `}
`;

export const StyledTable = styled.table`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.background.default};
    border-collapse: separate;
    border-radius: 4px;
    border-spacing: 0;
    table-layout: fixed;
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 14px;
    }

    &.auto-layout {
      table-layout: auto;
    }

    th:last-of-type {
      width: 96px; // variant for two icons, when there will be 3 vertical dots -...- 52px, when detach icon - 68px
    }

    &.has-actions {
      tr td:last-of-type > div {
        padding: 0;
        margin: 0 auto;
      }
    }

    &.report {
      background-color: ${theme.palette.background.secondary};
      color: ${theme.palette.border.dark};

      td,
      th {
        height: 1px;
        padding: 0;
        border: none;
        text-transform: none;
        color: ${theme.palette.border.dark};
        background-color: ${theme.palette.background.secondary};
      }

      * {
        font-size: 10px;
        line-height: 16px;
      }

      td:first-of-type > div,
      th:first-of-type > div {
        height: 100%;
        width: 100%;
        display: flex;

        &.row-span {
          height: 76px;

          .has-tooltip {
            display: flex;
            justify-content: flex-start;
          }
        }
      }

      td,
      th,
      div {
        padding: 0;
      }

      thead {
        th {
          height: 1px;
          border: none;
          text-transform: unset;
          background-color: ${theme.palette.background.secondary};
          padding: 2px 1px 1px 1px;

          & > div {
            background-color: ${theme.palette.background.default};
            border-radius: 2px;
          }
        }

        th div {
          height: 100%;
          width: 100%;
        }

        th:first-of-type {
          width: 100px;

          & > div {
            padding: 10px 0 10px 12px;
          }
        }

        th:not(:first-of-type) {
          width: 36px;
        }
      }

      tbody {
        background-color: ${theme.palette.background.primary};

        tr {
          height: 1px;

          td {
            border: none;
            height: inherit;
            padding: 1px;
            background-color: ${theme.palette.background.secondary};

            .tooltip {
              padding: 6px;
            }
          }
        }

        td > div {
          height: 100%;
          width: 100%;
          background-color: ${theme.palette.background.default};
          border-radius: 2px;
        }

        td:first-of-type > div {
          height: 100%;
          width: 100%;
          max-width: 144px;
          padding: 0 12px;
          margin-right: -2px;
          background-color: ${theme.palette.background.default};
          display: flex;
        }
      }
    }
  `}
`;

export const StyledThead = styled.thead`
  &.hidden {
    max-height: 0;
    visibility: collapse;
  }
`;

export const StyledTr = styled.tr``;

export const StyledTh = styled.th`
  ${({ theme: { palette, zIndex } }) => css`
    border-bottom: 1px solid ${palette.background.light};
    background-color: ${palette.background.primary};
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;

    &.pinned {
      position: sticky;
      z-index: ${zIndex.pinnedTable};
      left: 0;
      box-shadow: ${palette.background.dark} -4px 0px 7px -8px inset;
      opacity: 0.95;

      &.right {
        left: unset;
        right: 0;
        box-shadow: ${palette.background.dark} 4px 0px 7px -8px inset;
      }
    }
  `}
`;

export const StyledTd = styled.td`
  ${({ theme: { palette, zIndex } }) => css`
    border-bottom: 1px solid ${palette.background.light};
    background-color: ${palette.background.default};

    &.hidden {
      display: none;
    }

    &.pinned {
      position: sticky;
      z-index: ${zIndex.pinnedTable};
      left: 0px;
      box-shadow: ${palette.background.dark} -4px 0px 7px -8px inset;
      opacity: 0.95;

      &.right {
        left: unset;
        right: 0;
        box-shadow: ${palette.background.dark} 4px 0px 7px -8px inset;
      }
    }
  `}
`;

export const StyledTBody = styled.tbody`
  ${({ theme }) => css`
    tr:last-child td {
      border: none;
    }

    td.is-sub-row {
      background-color: ${theme.palette.background.primary};
    }
  `}
`;

export const TdContent = styled.div`
  padding: 0 6px;
  height: 52px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  max-width: 100%;

  @media (min-width: 768px) {
    padding: 0 24px;
  }

  &.large {
    min-height: 60px;
    height: initial;
    width: auto;

    > label {
      flex: 1;

      textarea {
        margin: 20px 0;
      }
    }

    > span {
      display: block;
      padding: 20px 0;
    }
  }

  &.small {
    height: auto;
    padding: 4px 12px;
  }

  &.zero-padding {
    padding: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ThContent = styled.div`
  padding: 0 6px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;

  @media (min-width: 768px) {
    padding: 0 24px;
  }

  &.zero-padding {
    padding: 0;
    width: 100%;
    height: 38px;
  }

  &.sortable,
  &.can-sort {
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      display: inline-block;
      height: 12px;
      width: 12px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0;
      position: absolute;
      right: -10px;

      @media (min-width: 768px) {
        right: 6px;
      }
    }
  }

  &.sortable {
    &.up::after {
      background-image: url(${arrowUp});
      opacity: 1;
    }

    &.down::after {
      background-image: url(${arrowDown});
      opacity: 1;
    }
  }

  &.can-sort {
    &:hover {
      &::after {
        background-image: url(${arrowUp});
        transition: all 0.3 ease;
        opacity: 1;
      }
    }
  }

  &.small {
    height: auto;
    padding: 4px 12px;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 8px;
  width: 100%;
  gap: 8px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const TemperatureData = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    padding: 2px 12px;
    font-weight: 500;
    border-radius: 4px;
    background-color: ${theme.palette.background.light};
    color: ${theme.palette.border.dark};

    &.green {
      background-color: ${theme.palette.success.light};
      color: ${theme.palette.success.dark};
    }

    &.orange {
      background-color: ${theme.palette.info.light};
      color: ${theme.palette.info.dark};
    }

    &.red {
      background-color: ${theme.palette.error.light};
      color: ${theme.palette.error.dark};
    }
  `}
`;

export const CellMaxContent = styled.div`
  min-width: max-content;
  display: flex;
  align-items: center;
`;

export const CustomLabel = styled.span`
  ${({ theme }) => css`
    margin-left: 8px;
    display: inline-block;
    padding: 2px 9px;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px;
    background-color: ${theme.palette.background.light};
    color: ${theme.palette.border.dark};
  `}
`;

export const FixedCell = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.link-cell {
      color: ${theme.palette.border.disable};
      font-weight: 700;
    }

    &.bold {
      font-weight: 500;
      color: ${theme.palette.text.primary};
    }

    &.dark {
      font-weight: 500;
      color: ${theme.palette.text.dark};
    }

    &.clickable {
      cursor: pointer;
    }
  `}
`;

export const ExpandedCell = styled.div`
  display: inline-block;
  max-width: 100%;
  padding-left: 22px;
`;
