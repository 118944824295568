import { createAction } from "@reduxjs/toolkit";
import { ISelectOption } from "../../components/kyps-select/kyps-select";

export const selectCompany = createAction<ISelectOption>(
  "company/selectCompany"
);
export const changeLoadingState = createAction<boolean>(
  "company/changeLoadingState"
);
export const updateChecklistsState = createAction<boolean>(
  "company/updateChecklistsState"
);
