import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Plus from "../../assets/plus.svg";
import Download from "../../assets/download.svg";
import Email from "../../assets/email.svg";

export const StyledButton = styled.button`
  ${({
    theme: {
      palette: { background, border, error, text },
    },
  }) => css`
    padding: 6px 9px;
    border-radius: 6px;
    cursor: pointer;
    font-family: inherit;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    min-width: max-content;
    border: 1px solid transparent;
    transition: all 0.3s ease;

    @media (min-width: 768px) {
      line-height: 20px;
      font-size: 14px;
      padding: 9px 13px;
    }

    @media (min-width: 992px) {
      padding: 9px 17px;
    }

    &.default {
      background-color: ${border.secondary};
      color: ${background.default};

      &:hover {
        background-color: ${border.primary};
      }
    }

    &.warning {
      background-color: ${error.default};
      color: ${background.default};

      &:hover {
        background-color: ${error.secondary};
      }
    }

    &.light {
      color: ${text.dark};
      background-color: ${background.default};
      border: 1px solid ${background.disable};

      &:hover {
        background-color: ${text.disable};
      }
    }

    &.active {
      color: ${border.secondary};
      background-color: ${background.default};
      border: 1px solid ${border.secondary};

      &:hover {
        background-color: ${text.disable};
      }
    }

    &.large {
      max-height: 40px;
      padding: 6px 32px;

      @media (min-width: 768px) {
        padding: 10px 56px;
      }
    }

    &.small {
      padding: 4px 9px;

      @media (min-width: 768px) {
        padding: 4px 14px;
      }
    }

    &.has-icon {
      padding-left: 28px;
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 6px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-position: center;
        background-size: 10px;
        background-repeat: no-repeat;
      }

      @media (min-width: 768px) {
        padding-left: 44px;

        &::before {
          left: 16px;
        }
      }
    }

    &.plus {
      &::before {
        background-image: url(${Plus});
      }
    }

    &.download {
      &::before {
        background-image: url(${Download});
        background-size: contain;
      }
    }

    &.email {
      &::before {
        height: 16px;
        background-image: url(${Email});
        background-size: contain;
      }
    }

    &.larger {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      padding: 9px 11px;

      @media (min-width: 768px) {
        padding: 9px 13px;
      }

      @media (min-width: 992px) {
        padding: 9px 17px;
      }
    }

    &.disabled {
      pointer-events: none;
      filter: opacity(0.4);
    }
  `}
`;
